import CustomerService, {
  CustomerServiceClass,
} from "@/app/services/api/CustomerService";
import { ICustomerCreate, ICustomerRange, ICustomerUpdate } from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { execute } from "@/redux/common/functions";
import { slice } from ".";

export interface ICustomerEffects
  extends IReduxBaseCrudEffect<
    ICustomerRange,
    ICustomerCreate,
    ICustomerUpdate,
    any
  > {}

class CustomerEffects
  extends BaseReduxCrudEffects<
    ICustomerRange,
    ICustomerCreate,
    ICustomerUpdate,
    CustomerServiceClass
  >
  implements ICustomerEffects
{
  change_request_state(
    payload: { applicant_uuid: string; response: "ACCEPTED" | "REFUSED" },
    callback?: (data: any) => void,
    onError?: (data: any) => void
  ) {
    return execute({
      slice: this.slice,
      inputProps: payload,
      serviceFn: this.ResourceService.change_request_state,
      actionToDispatch: this.slice.actions.change_request_state,
      callback,
      onError,
    });
  }
}

const customerEffects = new CustomerEffects(slice, CustomerService);

export default customerEffects;
