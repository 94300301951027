import { IProduct } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { IProductState, productInitialState } from ".";

export interface IProductReducer
  extends IBaseCrudReducer<IProductState, IProduct> {
  resetState: (state: IProductState) => void;
}

class ProductReducer
  extends BaseCrudReducer<IProductState, IProduct>
  implements IProductReducer
{
  resetState = (state: IProductState) =>
    resetStateKeys(state, productInitialState);
}

const reducers: IProductReducer = new ProductReducer();

export default reducers;
