const customer = {
  root: "user",
  root_plural: "users",
  salon: "Hairdressing salon",
  client_detail: "User details",
  salon_detail: "Lounge details",
  no_profile: "No profile",
  hair_objective_1: "Strength",
  hair_objective_2: "Length",
  hair_objective_3: "Feed",
  hair_objective_4: "Definition",
  hair_state_client_current_1: "Healthy",
  hair_state_client_current_2: "Brittle",
  hair_state_client_current_3: "Dryness of the scalp",
  hair_state_client_current_4: "Dandruff problem",
  hair_state_client_current_5: "Oily hair",
  hair_state_client_current_6: "Hair loss",
  hair_state_client_current_7: "Other",
  hair_last_client_current_1: "Less than 3 weeks",
  hair_last_client_current_2: "Less than 3 months",
  hair_last_client_current_3: "More than 3 months",
  really_want_to_change_user_status:
    "Are you sure you want to change the status of this user to {status}?",
  change_stockist_request: "Change query status",
  really_want_to_change_stockist_request:
    "Are you sure you want to check the status of this query?",
  select_a_state_bellow: "Select a query status to assign to this user below.",
  really_want_to_change_request_state:
    "Are you sure you want to change the query status of this user to {status}?",
  request_state_changed_successfully: "Query status successfully changed",
  really_want_to_refuse_this_request:
    "Are you sure you want to deny this request?",
  really_want_to_accept_this_request:
    "Are you sure you want to accept this request?",
  user_status_changed_successfuly: "User status successfully changed",
};
export default customer;
