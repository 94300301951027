import { Box } from "@mui/material";
import PropTypes from "prop-types";
//
import Image from "../../image";

// ----------------------------------------------------------------------

SingleFilePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default function SingleFilePreview({ file }) {
  if (!file) {
    return null;
  }

  const url = typeof file === "string" ? file : file.preview;
  const isVideo =
    typeof url === "string" &&
    (url.startsWith("data:video") || url.endsWith(".mp4"));
  const Cmp = isVideo
    ? ({ src, sx, ...props }) => (
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            left: 0,
            top: 0,
          }}
        >
          <video
            autoPlay
            {...{
              ...props,
              style: {
                ...sx,
                position: "absolute",
                zIndex: 0,
              },
            }}
          >
            <source src={url} />
          </video>

          <Box
            sx={{
              position: "absolute",
              zIndex: 100,
              left: 0,
              top: 0,
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
          />
        </Box>
      )
    : Image;
  return (
    <Cmp
      alt="file preview"
      src={url}
      sx={{
        top: 8,
        left: 8,
        zIndex: 8,
        borderRadius: 1,
        position: "absolute",
        width: "calc(100% - 16px)",
        height: "calc(100% - 16px)",
        userSelect: "none",
      }}
    />
  );
}
