import { IApplicationState } from "@/app/types";

const sliceNames: Record<keyof IApplicationState, string> = {
  product: "product",
  order: "order",
  globalData: "globalData",
  customer: "customer",
  articleCategory: "articleCategory",
  grade: "grade",
  paymentRequest: "paymentRequest",
  autoForm: "autoForm",
  commission: "commission",
  provisioning: "provisioning",
  bestAmbassadeur: "ambassadeur",
};

export default sliceNames;
