const product = {
  root: "produit",
  root_plural: "produits",
  name: "Nom",
  name_en: "Nom en anglais",
  salon: "Salon",
  category: "Catégorie",
  createdAt: "Créé le",
  price: "Prix",
  details: "Détails d'un produit",
  sub_category: "Sous-catégorie",
  really_want_to_duplicate_this_product:
    "Voulez-vous vraiment dupliquer ce produit ({productName}) ?",
  duplicate: "Dupliquer",
  product_duplicated_successfully: "Produit dupliqué avec succès",
};
export default product;
