import {
  ICommissionCreate,
  ICommissionRange,
  ICommissionUpdate,
} from "@/app/types";
import BaseCrudService from "./BaseCrudService";
import { CommissionUrls } from "./urls";

class CommissionService extends BaseCrudService<
  ICommissionCreate,
  ICommissionUpdate,
  ICommissionRange
> {}

export default new CommissionService(CommissionUrls);
