// sections
import Page from "@/core/components/page";
import Login from "@/presentation/sections/auth/Login";
// import Login from '@/presentation/sections/auth/LoginAuth0';

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <Page
      fullWidth
      title="login.root"
    >
      <Login />
    </Page>
  );
}
