import { IOrder } from "@/app/types";
import Iconify from "@/core/components/iconify";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";
import { orderPaymentTypeIcons } from "./constants";
import moment from "moment";
import getAppDate from "@/core/utils/getAppDate";
import getFullName from "@/core/utils/getFullName";

const orderColumns: (toCurrentcy: any) => ITableRowColumn<IOrder>[] = (
  toCurrentcy
) => [
  {
    header: { id: "user", label: "order.customer", align: "left" },
    type: "avatar",
    title: (row) =>
      getFullName(
        row?.user?.firstname,
        row?.user?.lastname,
        row?.user?.phonenumber
      ),
    avatarUrl: (row) => row?.user?.avatar?.url ?? "",
  },
  {
    header: { id: "total", label: "app.total", align: "center" },
    title: (row) => toCurrentcy(row.total_price),
    align: "center",
  },
  // {
  //   header: {
  //     id: "total",
  //     label: "label.total_with_feed_and_tva",
  //     align: "center",
  //   },
  //   title: (row) => toCurrentcy(row.total_price_with_fee_and_tva),
  //   align: "center",
  // },
  // {
  //   header: {
  //     id: "payment_type",
  //     label: "order.payment_type",
  //     align: "center",
  //   },
  //   title: (row) => (
  //     <Iconify
  //       width={36}
  //       icon={
  //         orderPaymentTypeIcons[
  //           row?.payment_type as keyof typeof orderPaymentTypeIcons
  //         ] ?? ""
  //       }
  //     />
  //   ),
  //   align: "center",
  // },
  // {
  //   header: { id: "salon", label: "order.salon", align: "left" },
  //   title: (row) => row?.hairdressing_salon?.name ?? "",
  //   align: "left",
  // },
  {
    header: {
      id: "order_from",
      label: "order.order_from",
      align: "left",
      sortable: false,
    },
    title: (row) =>
      row.head_office
        ? row?.head_office.name
        : getFullName(row.stockist?.firstname, row.stockist?.lastname),
    align: "left",
  },
  {
    header: { id: "date_added", label: "label.order_date", align: "center" },
    title: (row) => getAppDate(row?.date_added),
    align: "center",
  },
  {
    header: { id: "status", label: "app.status", align: "left" },
    type: "badge",
    title: (row) => (row?.status ? `label.${row?.status}` : ""),
    badgeColor: (row) =>
      ((
        {
          PENDING: "default",
          DELIVERED: "warning",
          FINISHED: "success",
          PAID: "success",
        } as any
      )[row?.status ?? ""] ?? ""),
    sx: { textTransform: "capitalize" },
  },
];

export default orderColumns;
