import PropTypes from "prop-types";
// @mui
import { Card, CardHeader, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
// utils
import { fShortenNumber } from "@/core/utils/formatNumber";
// components
import Iconify from "@/core/components/iconify";
import Image from "@/core/components/image";
import Scrollbar from "@/core/components/scrollbar";

// ----------------------------------------------------------------------

const StyledBlock = styled((props) => (
  <Stack
    direction="row"
    alignItems="center"
    {...props}
  />
))({
  minWidth: 72,
  flex: "1 1",
});

const StyledItemIcon = styled(Iconify)(({ theme }) => ({
  width: 16,
  height: 16,
  marginRight: theme.spacing(0.5),
  color: theme.palette.text.disabled,
}));

// ----------------------------------------------------------------------

AppTopInstalledCountries.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AppTopInstalledCountries({
  title,
  subheader,
  list,
  ...other
}) {
  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
      />

      <Scrollbar>
        <Stack
          spacing={3}
          sx={{ p: 3 }}
        >
          {list.map((country) => (
            <CountryItem
              key={country.id}
              country={country}
            />
          ))}
        </Stack>
      </Scrollbar>
    </Card>
  );
}

// ----------------------------------------------------------------------

CountryItem.propTypes = {
  country: PropTypes.shape({
    flag: PropTypes.string,
    name: PropTypes.string,
    android: PropTypes.number,
    windows: PropTypes.number,
  }),
};

function CountryItem({ country }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
    >
      <StyledBlock sx={{ minWidth: 120 }}>
        <Image
          disabledEffect
          alt={country.name}
          src={country.flag}
          sx={{ width: 28, mr: 1 }}
        />
        <Typography variant="subtitle2">{country.name}</Typography>
      </StyledBlock>

      <StyledBlock>
        <StyledItemIcon icon="ant-design:android-filled" />
        <Typography variant="body2">
          {fShortenNumber(country.android)}
        </Typography>
      </StyledBlock>

      <StyledBlock>
        <StyledItemIcon icon="ant-design:windows-filled" />
        <Typography variant="body2">
          {fShortenNumber(country.windows)}
        </Typography>
      </StyledBlock>

      <StyledBlock sx={{ minWidth: 88 }}>
        <StyledItemIcon icon="ant-design:apple-filled" />
        <Typography variant="body2">
          {fShortenNumber(country.windows)}
        </Typography>
      </StyledBlock>
    </Stack>
  );
}
