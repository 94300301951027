import { IOrderCreate, IOrderRange, IOrderUpdate } from "@/app/types";
import BaseCrudService from "./BaseCrudService";
import BaseService from "./BaseService";
import { OrderUrls } from "./urls";

class OrderService extends BaseCrudService<
  IOrderCreate,
  IOrderUpdate,
  IOrderRange
> {
  // changeStatus = (uuid: string) => {
  //   return BaseService.putRequest(OrderUrls.CHANGE_STATUS(uuid), {}, true);
  // };
  changeStatus = (obj: {
    uuid: string;
    status?: string;
    stockist_uuid?: string;
  }) => {
    return BaseService.putRequest(
      OrderUrls.CHANGE_STATUS(obj),
      {
        status: obj.status,
        stockist_uuid: obj.stockist_uuid,
      },
      true
    );
  };
}

export default new OrderService(OrderUrls);
