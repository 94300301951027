const administrator = {
  root: "administrateur",
  root_plural: "administrateurs",
  name: "Nom",
  email: "Email",
  role: "Rôle",
  verified: "Vérifié",
  status: "Statut",
  stockist_request: "Requête Stockiste",
};
export default administrator;
