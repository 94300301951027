const administrator = {
  root: "administrator",
  root_plural: "administrators",
  name: "Name",
  email: "Email",
  role: "Role",
  verified: "Checked",
  status: "Status",
  stockist_request: "Stockist query",
};
export default administrator;
