import { IPaymentRequest } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IPaymentRequestReducer } from "./reducers";

export interface IPaymentRequestState extends IBaseCrudState<IPaymentRequest> {}

export const paymentRequestInitialState: IPaymentRequestState = {
  ...baseCrudInitialState,
};

export const slice = createSlice<
  IPaymentRequestState,
  IPaymentRequestReducer,
  typeof sliceNames.paymentRequest
>({
  name: sliceNames.paymentRequest,
  initialState: paymentRequestInitialState,
  reducers,
});

export default slice.reducer;
