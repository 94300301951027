import ProductService from "@/app/services/api/ProductService";
import { IProductCreate, IProductRange, IProductUpdate } from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface IProductEffects
  extends IReduxBaseCrudEffect<
    IProductRange,
    IProductCreate,
    IProductUpdate,
    any
  > {}

class ProductEffects
  extends BaseReduxCrudEffects<IProductRange, IProductCreate, IProductUpdate>
  implements IProductEffects {}

const productEffects = new ProductEffects(slice, ProductService);

export default productEffects;
