import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import sliceNames from "./names";
import customerReducer from "./slices/customer";
import globalDataReducer from "./slices/globalData";
import orderReducer from "./slices/order";
import productReducer from "./slices/product";
import articleCategoryReducer from "./slices/articleCategory";
import gradeReducer from "./slices/grade";
import paymentRequestReducer from "./slices/paymentRequest";
import autoFormReducer from "./slices/autoForm";
import commissionReducer from "./slices/commission";
import provisioningReducer from "./slices/provisioning";
import AmbassadeurReducer from "./slices/bestAmbassadeur";

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  [sliceNames.product]: productReducer,
  [sliceNames.order]: orderReducer,
  [sliceNames.globalData]: globalDataReducer,
  [sliceNames.customer]: customerReducer,
  [sliceNames.articleCategory]: articleCategoryReducer,
  [sliceNames.grade]: gradeReducer,
  [sliceNames.paymentRequest]: paymentRequestReducer,
  [sliceNames.autoForm]: autoFormReducer,
  [sliceNames.commission]: commissionReducer,
  [sliceNames.provisioning]: provisioningReducer,
  [sliceNames.bestAmbassadeur]: AmbassadeurReducer,
});

export default rootReducer;
