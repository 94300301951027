import ArticleCategoryService from "@/app/services/api/ArticleCategoryService";
import BestAmbassadeur from "@/app/services/api/BestAmbassadeur";
import {
  IAmbassadeurCreate,
  IAmbassadeurRange,
  IAmbassadeurUpdate,
} from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface IBestAmbassadeurEffects
  extends IReduxBaseCrudEffect<
    IAmbassadeurRange,
    IAmbassadeurCreate,
    IAmbassadeurUpdate,
    any
  > {}

class BestAmbassadeurEffects
  extends BaseReduxCrudEffects<
    IAmbassadeurRange,
    IAmbassadeurCreate,
    IAmbassadeurUpdate
  >
  implements IBestAmbassadeurEffects {}

const bestAmbassadeurEffects = new BestAmbassadeurEffects(
  slice,
  BestAmbassadeur
);

export default bestAmbassadeurEffects;
