import { DEFAULT_CURRENCY } from "@/app/config-global";
import { useLocales } from "@/app/locales";
import { current } from "@reduxjs/toolkit";

export const toSpacedNumber = (value: number, perCount: number) =>
  String(value)
    .split("")
    .reverse()
    .reduce<string[]>(
      (a, c, i) =>
        (i + 1) % perCount === 0 ? a.concat([c, " "]) : a.concat([c]),
      []
    )
    .join("")
    .split(" ")
    .map((e) => e.split("").reverse().join(""))
    .reverse()
    .join(" ");

const useCurrency = () => {
  const currency = DEFAULT_CURRENCY;
  const {
    currentLang: { value: lang },
  } = useLocales();
  const toCurrency = (value?: number, options?: { noUnit?: boolean }) =>
    Number.isFinite(Number(value))
      ? new Intl.NumberFormat(`${lang}-${lang.toUpperCase()}`, {
          style: options?.noUnit ? undefined : "currency",
          currency: options?.noUnit ? undefined : currency,
        }).format(value ?? 0)
      : "0";
  return toCurrency;
};
export default useCurrency;
