import { IProvisioning } from "@/app/types";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";
import getAppDate from "@/core/utils/getAppDate";
import getFullName, { getValueOrNA } from "@/core/utils/getFullName";

const provisioningColumns: ITableRowColumn<IProvisioning>[] = [
  {
    header: { id: "head_office", label: "label.head_office", align: "left" },
    type: "avatar",
    title: (row) => row.head_office?.name,
    avatarUrl: () => "",
  },
  {
    header: {
      id: "stockist",
      label: "label.stockist",
      align: "left",
    },
    title: (row) =>
      getValueOrNA(
        getFullName(row?.stockist?.firstname, row?.stockist?.lastname)
      ),
    align: "left",
  },
  {
    header: { id: "supply_date", label: "label.supply_date", align: "left" },
    title: (row) => getAppDate(row.supply_date),
    // avatarUrl: (row) => row?.head_office?.fr ?? "",
  },
];

export default provisioningColumns;
