import PaymentRequestService from "@/app/services/api/PaymentRequestService";
import {
  IPaymentRequestCreate,
  IPaymentRequestRange,
  IPaymentRequestUpdate,
} from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface IPaymentRequestEffects
  extends IReduxBaseCrudEffect<
    IPaymentRequestRange,
    IPaymentRequestCreate,
    IPaymentRequestUpdate,
    any
  > {}

class PaymentRequestEffects
  extends BaseReduxCrudEffects<
    IPaymentRequestRange,
    IPaymentRequestCreate,
    IPaymentRequestUpdate
  >
  implements IPaymentRequestEffects {}

const paymentRequestEffects = new PaymentRequestEffects(
  slice,
  PaymentRequestService
);

export default paymentRequestEffects;
