import { DEFAULT_RANGE } from "@/app/config-global";
import { IInitialStateWithLoading } from "@/app/types/BaseReduxTypes";

export interface IBaseCrudState<T = {}> extends IInitialStateWithLoading {
  currentItem: T | null;
  list: {
    current_page: number;
    data: T[];
    per_page: number;
    total: number;
  };
}

export const baseCrudInitialState: IBaseCrudState = {
  currentItem: null,
  list: {
    current_page: DEFAULT_RANGE.page,
    data: [],
    per_page: DEFAULT_RANGE.per_page,
    total: 0,
  },
  error: null,
  isLoading: true,
};
