import { IUploadFiledCreate } from "@/app/types";
import BaseService from "./BaseService";
import { fileUrls } from "./urls";

class FileService {
  static storeFile = (file: IUploadFiledCreate) => {
    return BaseService.postRequest(fileUrls.STORE_FILE, file, true);
  };
  static deleteProductImage = (productUuid: string, fileUuid: string) => {
    return BaseService.deleteRequest(
      fileUrls.DELETE_PRODUCT_IMAGE(productUuid, fileUuid),
      {},
      true
    );
  };
}

export default FileService;
