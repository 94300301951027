import { IGrade } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { IGradeState, gradeInitialState } from ".";

export interface IGradeReducer extends IBaseCrudReducer<IGradeState, IGrade> {
  resetState: (state: IGradeState) => void;
}

class GradeReducer
  extends BaseCrudReducer<IGradeState, IGrade>
  implements IGradeReducer
{
  resetState = (state: IGradeState) => resetStateKeys(state, gradeInitialState);
}

const reducers: IGradeReducer = new GradeReducer();

export default reducers;
