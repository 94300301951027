import {
  IAdministratorRange,
  IArticleCategoryRange,
  IArticleRange,
  IChatBotRange,
  IChatRangeMessage,
  ICustomerRange,
  IHairdressingSalonRange,
  IOrderRange,
  IProductRange,
  IProvisioningRange,
  IVideoRange,
} from "@/app/types";
import { IBookingRange } from "@/app/types/BookingTypes";
import { IGradeRange } from "@/app/types/GradeTypes";
import { buildCrudUrls, buildUrl } from "./utils";

export const fileUrls = {
  STORE_FILE: buildUrl(`/storages/file/upload`),
  DELETE_PRODUCT_IMAGE: (productUuid: string, fileUuid: string) =>
    buildUrl(`/products/${productUuid}/files/${fileUuid}`),
};

export const AuthUrls = {
  LOGIN_USER: buildUrl(`/auth/login/administrator`),
  UPDATE_USER_INFO: buildUrl(`/update`),
  UPDATE_PASSWORD: buildUrl(`/auth/update-password`),
  GET_CONNECTED_INFO: buildUrl(`/auth/me`),
  SEND_EMAIL_RESET_PWD: buildUrl(`/auth/password-recovery`),
  SEND_NEW_PWD: buildUrl(`/auth/reset-password`),
};

export const GlobalUrls = {
  GET_GLOBAL_DATA: buildUrl(`/users/default/data`),
};

export const AdministratorUrls = {
  ...buildCrudUrls<IAdministratorRange>("administrations"),
  DELETE: buildUrl(`/administrations/users`),
};

export const ArticleUrls = {
  ...buildCrudUrls<IArticleRange>("administrations/article"),
  PUBLISH: (articleId: string) =>
    buildUrl(`/administrations/article/toggle-online/${articleId}`),
  ADD_OR_REMOVE_FAVORITE: (articleId: string) =>
    buildUrl(`/administrations/article/list/favoris/${articleId}`),
  LIST_HOME_ARTICLES: ({ category_uuid, ...query }: IArticleRange) =>
    buildUrl(`/administrations/article/list/home`, { query }),
  LIST_BY_CATEGORY: ({ category_uuid, ...query }: IArticleRange) =>
    buildUrl(`/administrations/article/category/${category_uuid ?? ""}`, {
      query,
    }),
  LIST_FAVORITES: ({ category_uuid, ...query }: IArticleRange) =>
    buildUrl(`/administrations/article/list/favoris`, { query }),
};

export const ProductUrls = {
  ...buildCrudUrls<IProductRange>("products"),
  DUPLICATE: (id: string) => buildUrl(`/products/clone/${id}`),
};

export const OrderUrls = {
  ...buildCrudUrls<IOrderRange>("orders"),
  // CHANGE_STATUS: (id: string) => buildUrl(`/orders/${id}`),
  CHANGE_STATUS: (obj: {
    uuid: string;
    status?: string;
    stockist_uuid?: string;
  }) => buildUrl(`/orders/${obj.uuid}`),
};

export const VideoUrls = {
  ...buildCrudUrls<IVideoRange>("administrations/hairstyle-inspiration"),
};

export const ChatBotUrls = {
  ...buildCrudUrls<IChatBotRange>("chat-bot"),
};

export const CustomerUrls = {
  ...buildCrudUrls<ICustomerRange>("users"),
  DELETE: buildUrl("/users/users"),
  LIST_STOCKISTS: (query: object) => buildUrl("/users/stockists", { query }),
  LIST_HEAD_OFFICE: (query: object) => buildUrl("/head_offices", { query }),
  CHANGE_REQUEST_STATE: (query: object) =>
    buildUrl("/users/answer/become-stockist", { query }),
};

export const StatUrls = {
  GET: (resource?: string) => buildUrl(`/statistic/${resource ?? ""}`),
};

export const ChatUrls = {
  UNREAD_COUNT: buildUrl(`/messages/unread/count`),
  LAST_RECEIVED: buildUrl(`/messages/last-received`),
  LIST_CONVERSATION_MESSAGES: (id: string) => buildUrl(`/messages/list/${id}`),
  LIST_CONVERSATIONS: (query: IChatRangeMessage) =>
    buildUrl(`/messages/conversations`, { query }),
  LIST_USER_CONVERSATIONS: (public_id?: string) =>
    buildUrl(`/messages/conversations${public_id ? `/${public_id}` : ""}`),
  LIST_USER_CONVERSATION_MESSAGES: (public_id: string) =>
    buildUrl(`/messages/${public_id}`),
  CREATE_MESSAGE: buildUrl(`/messages`),
  CREATE_MESSAGE_FILE: buildUrl(`/messages/file`),
};

export const HairdressingSalonUrls = {
  ...buildCrudUrls<IHairdressingSalonRange>(
    "administrations/hairdressing-salon"
  ),
  PAGINATE: (query: IHairdressingSalonRange) =>
    buildUrl(`/hairdressing-salons`, { query }),
  GET: (uuid: string) => buildUrl(`/hairdressing-salons/${uuid}`),
};

export const BookingUrls = {
  ...buildCrudUrls<IBookingRange>("bookings"),
};

export const ArticleCategoryUrls = {
  ...buildCrudUrls<IArticleCategoryRange>("categories"),
};

export const GradeUrls = {
  ...buildCrudUrls<IGradeRange>("grades"),
};
export const BestAmbassadeurUrls = {
  ...buildCrudUrls<IGradeRange>("administration/best-sellers"),
};

export const PaymentRequestUrls = {
  ...buildCrudUrls<IGradeRange>("payment-request"),
  CONFIGURE: buildUrl("/administration/configs"),
  GET_CONFIG: buildUrl("/payment-request"),
  CHANGE_PAYMENT_STATUS: (requestUuid: string, status: "PAID" | "REJECTED") =>
    buildUrl(`/payment-request/${requestUuid}/status`, {
      query: { status },
    }),
  GET_SELLERS: buildUrl("/administration/best-sellers"),
};

export const CommissionUrls = {
  ...buildCrudUrls<IGradeRange>("users/biocoins"),
};

export const ProvisioningUrls = {
  ...buildCrudUrls<IProvisioningRange>("products/supplies"),
  CREATE: buildUrl("/products/supply"),
};
