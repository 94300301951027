const order = {
  root: "order",
  root_plural: "orders",
  location: "Place",
  customer: "Customer",
  salon: "Living room",
  payment_type: "Type of payment",
  payment_types: "Types of payment",
  no_data: "No order",
  order_evolution: "Evolution of orders",
  order_from: "Ordered from",
};
export default order;
