const label = {
  title: "Title",
  status_admin: "Enable/Disable",
  search: "Research",
  first_name: "First name",
  last_name: "Last name",
  firstname: "Fist name",
  lastname: "Last name",
  email: "Email address",
  phone_number: "Phone number",
  phonenumber: "Phone number",
  variable_biocoins: "BioCoins variable",
  total_orders: "Total orders",
  role: "Role",
  job: "Work",
  bio: "Bio",
  tags: "Tags",
  content: "Content",
  cover: "Blanket",
  image: "Image",
  drop_or_select_file: "Drop or select a file",
  category: "Category",
  name: "Name",
  name_en: "Name in English",
  name_fr: "Name in French",
  brand: "Brand",
  images: "Images",
  description: "Description",
  description_en: "Description in English",
  sku: "SKU",
  real_price: "Real price",
  current_price: "Current price",
  qty: "Quantity",
  hair_style: "Hair style",
  benefits: "Benefits",
  weight: "Weight",
  is_online: "Upload",
  sub_category: "Subcategory",
  video_link: "Video link",
  image_link: "Image link",
  prestation: "Benefit",
  prestations: "Benefits",
  begin_duration: "Beginning",
  video: "Video",
  video_duration: "Video length",
  hairdressing_salon: "Hairdressing salon",
  password: "Password",
  primary_address: "Main address",
  town: "Town",
  country: "Country",
  year: "Year",
  hairdresser_salon_name: "Hairdressing salon name",
  country_code: "Country code",
  entreprise_name: "Company Name",
  entreprise_phonenumber: "Company phone number",
  website: "Website",
  hair_types: "Hairstyle types",
  people_type_ids: "People types",
  shop_onligne: "Visible online",
  headdress_at_home: "Work at home",
  postal_code: "Zip code",
  province: "Region",
  address_one: "Main address",
  address_two: "Secondary address",
  accept_click_n_collect: "Click N Collect",
  manager_hairdressing_email: "Email address of the show manager",
  parent_profile: "Parent profile",
  profile: "Profile",
  profiles: "Profiles",
  orders_an_bookings: "Orders and reservations",
  profile_count: "Number of profiles",
  formula: "Formula",
  formulas: "Formulas",
  BASIC: "Basic",
  PREMIUM: "Premium",
  register_date: "Date of registration",
  contact_via_chat: "Contact via chat",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  planning: "Schedule",
  start: "Beginning",
  end: "",
  about: "By the way",
  popularity_index: "Popularity Index",
  hairdresser: "Barber",
  hairdressers: "Hairdressers",
  orders: "Commands",
  bookings: "Reservations",
  client_email: "Customer Email",
  salon: "Living room",
  date_added: "Date Added",
  child_profile: "Child profile",
  people_types: "Types of person",
  preferences: "Preferences",
  objective: "Objective",
  last_time_hairdresser: "Last time at a hairdresser",
  hair_conditions: "Hair conditions",
  current_hair_style: "Current hair style",
  hair_type: "Hair type",
  in_stock: "In stock",
  unavailable: "Unavailable",
  position: "Position",
  publish: "Published",
  volume: "Volume",
  status: "Status",
  price: "Price",
  biocoin_cost: "BioCoin Cost",
  stockist_price: "Stockist Award",
  stockist_biocoin_cost: "BioCoin Stockist Award",
  recommended_price: "Recommended price",
  potential_benefits: "Potential benefit",
  ambassador_biocoin: "Ambassador Commission",
  stockist_biocoin_on_ambassador: "Stockist commission on ambassador",
  sponsor_biocoin: "Sponsor commission",
  stockist_biocoin: "Stockist Commission",
  stockist_minimum_qty: "Minimum Stockist Qty",
  number_ordered_times: "Number of times ordered",
  details: "Details",
  categories: "Categories",
  affiliate_code: "Affiliate Code",
  quarter: "Quarter",
  ambassador_committee: "Ambassador Committee",
  ambassadors: "Ambassadors",
  order_time: "Commands",
  code: "Code",
  description_fr: "Description in English",
  features: "Features",
  label: "Entitled",
  biocoins: "Biocoins",
  last_online: "Last time online",
  date_modified: "Date modified",
  order_date: "Order date",
  stockists: "Stockists",
  user: "User",
  amount: "Amount",
  min_biocoins_number: "Minimum number of BioCoins",
  biocoin_value: "Value of BioCoin",
  total_users: "Total users",
  of_today: "Today",
  last_sale: "Last sale",
  paid_plural: "Paid",
  total_biocoins_generated_in_app: "Total BioCoins generated in the app",
  none_female: "No",
  total_biocoins: "Total BioCoins",
  already_paid_plural: "Already paid",
  sales_and_orders: "Sales and orders",
  YEARLY: "This year",
  MONTHLY: "This month",
  WEEKLY: "This week",
  become_stockist: "Become a stockist",
  supplies: "Supplies",
  PENDING: "In progress",
  PAID: "Paid",
  PAID_female: "Paid",
  DELIVERED: "Delivered",
  DELIVERED_female: "Livery",
  FINISHED: "Finished",
  FINISHED_female: "Completed",
  pu: "P.U",
  via_CASH: "Cash",
  via_MOBILE: "Via mobile",
  via_BIOCOINS: "Via BioCoins",
  grade: "",
  head_office: "Head office",
  stockist: "Stockist",
  products: "Products",
  supply_date: "Supply date",
  product: "Product",
};
export default label;
