import PropTypes from "prop-types";
// @mui
import useTranslation from "@/core/hooks/useTranslation";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

// ----------------------------------------------------------------------

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  content: PropTypes.node,
  vairiant: PropTypes.string,
  onClose: PropTypes.func,
  isConfirmLoading: PropTypes.bool,
};

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  variant = "error",
  ...other
}) {
  const t = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      {...other}
    >
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
      {content && (
        <DialogContent sx={{ typography: "body2" }}> {content} </DialogContent>
      )}

      <DialogActions>
        {action}
        {other.onConfirm && (
          <LoadingButton
            loading={other.isConfirmLoading}
            disabled={other.isConfirmLoading}
            variant="contained"
            color={variant}
            onClick={other.onConfirm}
          >
            {other.confirmLabel}
          </LoadingButton>
        )}
        <Button
          variant="outlined"
          color="inherit"
          onClick={onClose}
          disabled={other.disabled}
        >
          {t("app.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
