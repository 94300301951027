import PropTypes from "prop-types";
// @mui
import { Box, Card, CardHeader } from "@mui/material";
// components
import Chart, { useChart } from "@/core/components/chart";
import { isNaN } from "lodash";
import useCurrency from "@/core/hooks/useCurrency";

// ----------------------------------------------------------------------

AppAreaInstalled.propTypes = {
  chart: PropTypes.object,
  years: PropTypes.arrayOf(PropTypes.string),
  onYearChange: PropTypes.func,
  title: PropTypes.string,
  subheader: PropTypes.string,
  currentYear: PropTypes.string,
  actions: PropTypes.any,
};

export default function AppAreaInstalled({
  title,
  subheader,
  chart,
  currentYear,
  ...other
}) {
  const { colors, categories, series, options } = chart;
  const toCurrency = useCurrency();
  const chartOptions = useChart({
    colors,
    xaxis: {
      categories,
    },
    yaxis: {
      labels: {
        formatter: (value) =>
          toCurrency(!isNaN(value) ? value.toFixed(0) : value, {
            noUnit: true,
          }),
      },
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          other.actions
          // <CustomSmallSelect
          //   value={currentYear}
          //   onChange={(event) =>
          //     other.onYearChange
          //       ? other.onYearChange(e.target.value)
          //       : undefined
          //   }
          // >
          //   {(other.years?.map((year) => ({ year })) ?? series).map(
          //     (option) => (
          //       <option
          //         key={option.year}
          //         value={option.year}
          //       >
          //         {console.log({ option })}
          //         {option.year}
          //       </option>
          //     )
          //   )}
          // </CustomSmallSelect>
        }
      />

      {series.map((item) => (
        <Box
          key={item.year}
          sx={{ mt: 3, mx: 3 }}
          dir="ltr"
        >
          {item.year === currentYear && (
            <Chart
              type="line"
              series={item.data}
              options={chartOptions}
              height={other.height ?? 364}
            />
          )}
        </Box>
      ))}
    </Card>
  );
}
