import { IPaymentRequest } from "@/app/types";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";
import getFullName from "@/core/utils/getFullName";

const paymentRequestColumns: ITableRowColumn<IPaymentRequest>[] = [
  {
    header: { id: "user", label: "label.user", align: "left" },
    type: "avatar",
    title: (row) => getFullName(row?.user?.firstname, row?.user?.lastname),
    avatarUrl: (row) => row?.user?.avatar?.url ?? "",
  },
  {
    header: {
      id: "quantity",
      label: "label.qty",
      align: "left",
    },
    title: (row) => row?.quantity ?? "",
    align: "left",
  },
  {
    header: {
      id: "amount",
      label: "label.amount",
      align: "left",
    },
    title: (row) => row?.amount ?? "",
    align: "left",
  },
  {
    header: { id: "status", label: "administrator.status", align: "left" },
    type: "badge",
    title: (row) => `status.${row?.status ?? ""}`,
    badgeColor: (row) =>
      row?.status === "PAID"
        ? "success"
        : row?.status === "PENDING"
        ? "info"
        : "error",
    sx: { textTransform: "capitalize" },
  },
];

export default paymentRequestColumns;
