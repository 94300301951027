import { IArticleCategory } from "@/app/types";
import buildValidators from "@/core/utils/buildValidators";
import { IAutoFormFields } from "@/presentation/components/AutoForm";

const articleCategoryFields = (
  t: Function,
  roles: any[]
): IAutoFormFields<IArticleCategory> => {
  const v = buildValidators(t);
  return {
    title__fr: {
      initialValue: (row) => row?.title?.fr,
      label: "label.name_fr",
      placeholder: "placeholder.name_fr",
      title: "title.name_fr",
      props: {},
      validation: v.string,
    },
    title__en: {
      initialValue: (row) => row?.title?.en,
      label: "label.name_en",
      placeholder: "placeholder.name_en",
      title: "title.name_en",
      props: {},
      validation: v.string,
    },
  };
};

export default articleCategoryFields;
