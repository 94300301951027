import { useLocales } from "@/app/locales";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import ProductService from "@/app/services/api/ProductService";
import { IApplicationState, IProduct } from "@/app/types";
import ConfirmDialog from "@/core/components/confirm-dialog/ConfirmDialog";
import useCurrency from "@/core/hooks/useCurrency";
import useFilters from "@/core/hooks/useFilters";
import useToast from "@/core/hooks/useToast";
import useTranslation from "@/core/hooks/useTranslation";
import TablePage, {
  ICustomBreadcrumbsLink,
} from "@/presentation/components/TablePage";
import { slice } from "@/redux/slices/product";
import productEffects from "@/redux/slices/product/effects";
import React from "react";
import { useSelector } from "react-redux";
import productColumns from "./data/columns";
import {
  productTableTopOptions,
  productToolbarOptions,
} from "./data/constants";
import productFields from "./data/fields";

export interface IProductsPageProps {
  additionalFilters?: Record<string, string | undefined>;
  breadcrumbs?: null | ICustomBreadcrumbsLink[];
  noPage?: boolean;
  noSalonFiler?: boolean;
}

const ProductsPage: React.FC<IProductsPageProps> = ({
  additionalFilters,
  breadcrumbs,
  noPage,
}) => {
  // Store
  const { product, globalData } = useSelector(
    (state: IApplicationState) => state
  );

  // State
  const [productToDuplicate, setProductToDuplicate] =
    React.useState<IProduct | null>(null);
  const [isLoadingDuplication, setIsLoadingDuplication] =
    React.useState<boolean>(false);

  // Hooks
  const t = useTranslation();
  const {
    getData,
    handleKeyword,
    handleCategory_ids,
    handleSub_category_id,
    handleIs_online,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    handleHairdressing_salon_uuid,
    keyword,
    category_ids,
    sub_category_id,
    hairdressing_salon_uuid,
    is_online,
    order,
    order_field,
  } = useFilters({
    effects: productEffects,
    initialState: {
      page: product.list.current_page,
      per_page: product.list.per_page,
      keyword: "",
      category_ids: "",
      sub_category_id: "",
      hairdressing_salon_uuid: "",
      is_online: "",
      order: "",
      order_field: "",
      ...(additionalFilters ?? {}),
    },
  });
  const { currentLang } = useLocales();
  const fCurrency = useCurrency();
  const toast = useToast();

  // Functions
  const onDuplicateProduct = async () => {
    try {
      setIsLoadingDuplication(true);
      const res = await ProductService.duplicate(productToDuplicate!.uuid);
      if (res.ok) {
        setProductToDuplicate(null);
        getData();
        toast.success(t(`product.product_duplicated_successfully`));
      } else {
        const data = await res.json();
        toast.error(
          typeof data.detail === "string"
            ? data.detail
            : `error.unexpected_error_occurred`
        );
      }
    } catch (error) {
      toast.error(`error.unexpected_error_occurred`);
    } finally {
      setIsLoadingDuplication(false);
    }
  };

  return (
    <>
      <TablePage
        model="product"
        // ** Genre **
        // female
        // ** Custom row actions **
        customRowActions={[
          {
            onClick: (row: IProduct) => setProductToDuplicate(row),
            icon: "bxs:duplicate",
            label: "product.duplicate",
          },
        ]}
        // ** Toolbar **
        tableTopOptions={productTableTopOptions}
        toolbarOptionChange={handleIs_online}
        currentToolbarOption={is_online}
        toolbarOptions={productToolbarOptions({
          value: keyword ?? "",
          onChange: handleKeyword,
        })}
        // ** On Select rows **
        idProperty="uuid"
        // ** Table CRUD **
        fields={productFields(
          t,
          currentLang.value,
          globalData.data?.categories
        )}
        stateData={product}
        slice={slice}
        columns={productColumns(fCurrency)}
        effects={productEffects}
        getData={getData}
        // ** Filter options **
        isFiltered={(data) =>
          keyword !== "" ||
          category_ids !== "" ||
          is_online !== "" ||
          hairdressing_salon_uuid !== ""
        }
        onReset={() => {
          handleKeyword("");
          handleCategory_ids("");
          handleIs_online("");
          handleHairdressing_salon_uuid("");
        }}
        // ** Sorting **
        handleFilterOrder={handleOrder}
        handleFilterOrderField={handleOrder_field}
        order={order}
        orderField={order_field}
        // ** Pagination **
        onChangePage={handlePage}
        onChangeRowsPerPage={handlePer_page}
        // ** Table Row Actions **
        showDetailsUrl={(row) => PATH_DASHBOARD.products.view(row?.uuid ?? "")}
        breadcrumbLinks={breadcrumbs}
        noPage={noPage}
      />

      {productToDuplicate && (
        <ConfirmDialog
          open={true}
          variant="success"
          onClose={() => setProductToDuplicate(null)}
          disabled={isLoadingDuplication}
          title={t("product.duplicate")}
          content={t(`product.really_want_to_duplicate_this_product`, {
            productName: productToDuplicate.name?.[currentLang?.value] ?? "",
          })}
          onConfirm={onDuplicateProduct}
          isConfirmLoading={isLoadingDuplication}
          confirmLabel={t("app.confirm")}
          action={null}
        />
      )}
    </>
  );
};

export default ProductsPage;
