import { DEFAULT_NA_STRING, DEFAULT_RANGE } from "@/app/config-global";
import { useLocales } from "@/app/locales";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import { IAppLocale, ICustomer } from "@/app/types";
import ConfirmDialog from "@/core/components/confirm-dialog";
import useCurrency from "@/core/hooks/useCurrency";
import useFilters from "@/core/hooks/useFilters";
import useToast from "@/core/hooks/useToast";
import useTranslation from "@/core/hooks/useTranslation";
import getFullName, { getValueOrNA } from "@/core/utils/getFullName";
import AppModal from "@/presentation/components/AppModal";
import TablePage from "@/presentation/components/TablePage";
import customerEffects from "@/redux/slices/customer/effects";
import gradeEffects from "@/redux/slices/grade/effects";
import { dispatch, useSelector } from "@/redux/store";
import { LoadingButton } from "@mui/lab";
import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import CustomerDetailsModal from "./components/CustommerDetailsModal";
import customerColumns from "./data/columns";
import {
  customerTableTopOptions,
  customerToolbarOptions,
} from "./data/constants";
import customerFields from "./data/fields";

export interface ICustomersPageProps {
  noPage?: boolean;
}

const CustomersPage: React.FC<ICustomersPageProps> = ({ noPage }) => {
  // Store
  const customer = useSelector((state) => state.customer);
  const globalData = useSelector((state) => state.globalData);

  // Hooks
  const { currentLang } = useLocales();
  const t = useTranslation();
  const toast = useToast();
  const {
    getData,
    handleKeyword,
    handleRole_uuid,
    handleStatus,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    handleBecome_stockist,
    handleGrade_uuid,
    keyword,
    become_stockist,
    status,
    order,
    order_field,
    role_uuid,
    grade_uuid,
  } = useFilters({
    effects: customerEffects,
    initialState: {
      page: customer.list.current_page,
      per_page: customer.list.per_page,
      keyword: "",
      become_stockist: "",
      status: "",
      order: "",
      order_field: "",
      role_uuid: "",
      grade_uuid: "",
    },
  });

  // Constants
  const requestStates = [``, `ASKED`, `ACCEPTED`, `REFUSED`].map((status) => ({
    label: status ? t(`status.${status}`) : DEFAULT_NA_STRING,
    value: status,
  }));

  const roles = [
    { label: DEFAULT_NA_STRING, value: "" },
    ...globalData.data.roles.map((role) => ({
      label: role.title[currentLang.value as IAppLocale],
      value: role.uuid ?? "",
    })),
  ];

  // State
  const [rowToChangeStatus, setRowToChangeStatus] =
    React.useState<ICustomer | null>(null);
  const [rowToChangeStockistRequest, setRowToChangeStockistRequest] =
    React.useState<ICustomer | null>(null);
  const [userToShow, setUserToShow] = React.useState<ICustomer | null>(null);
  const [selectedStockistStatus, setSelectedRequestStatus] = React.useState<
    "ACCEPTED" | "REFUSED" | ""
  >("");

  return (
    <>
      <TablePage
        model="customer"
        noPage={noPage}
        // ** Genre **
        // female
        // ** Custom row actions **
        customRowActions={[
          {
            onClick: (row: ICustomer) => setRowToChangeStatus(row),
            icon: "mdi:list-status",
            label: "app.enable_disable",
          },
          {
            onClick: (row: ICustomer) => setRowToChangeStockistRequest(row),
            icon: "tabler:switch-3",
            label: "customer.change_stockist_request",
            hide: (row: ICustomer) =>
              !row.become_stockist || row.become_stockist !== "ASKED",
          },
        ]}
        // ** Toolbar **
        tableTopOptions={customerTableTopOptions}
        toolbarOptionChange={handleStatus}
        currentToolbarOption={status}
        toolbarOptions={customerToolbarOptions(
          requestStates,
          roles,
          {
            value: keyword,
            onChange: handleKeyword,
          },
          {
            value: become_stockist,
            onChange: handleBecome_stockist,
          },
          {
            value: role_uuid,
            onChange: handleRole_uuid,
          },
          {
            value: grade_uuid,
            onChange: handleGrade_uuid,
          }
        )}
        // ** On Select rows **
        idProperty="uuid"
        // ** Table CRUD **
        fields={customerFields(t, roles)}
        stateData={customer}
        columns={customerColumns(currentLang.value)}
        effects={customerEffects}
        getData={getData}
        // ** Filter options **
        isFiltered={(data) =>
          keyword !== "" ||
          become_stockist !== "" ||
          status !== "" ||
          grade_uuid !== "" ||
          role_uuid !== ""
        }
        onReset={() => {
          handleKeyword("");
          handleBecome_stockist("");
          handleStatus("");
          handleRole_uuid("");
          handleGrade_uuid("");
        }}
        // ** Sorting **
        handleFilterOrder={handleOrder}
        handleFilterOrderField={handleOrder_field}
        order={order}
        orderField={order_field}
        // ** Pagination **
        onChangePage={handlePage}
        onChangeRowsPerPage={handlePer_page}
        // ** Table Row Actions **
        onShowDetails={(row) => setUserToShow(row)}
      />

      {userToShow && (
        <CustomerDetailsModal
          customer={userToShow ?? {}}
          onClose={() => setUserToShow(null)}
        />
      )}

      {rowToChangeStatus && (
        <ConfirmDialog
          open={true}
          variant="success"
          onClose={() => setRowToChangeStatus(null)}
          disabled={customer?.isLoading}
          title={t("app.update")}
          content={t(`customer.really_want_to_change_user_status`, {
            status: `status.${
              rowToChangeStatus?.status === "ACTIVED" ? "UNACTIVED" : "ACTIVED"
            }`,
          })}
          onConfirm={() => {
            dispatch(
              customerEffects.update(
                {
                  uuid: rowToChangeStatus?.uuid ?? "",
                  status:
                    rowToChangeStatus?.status === "ACTIVED"
                      ? "UNACTIVED"
                      : "ACTIVED",
                },
                () => {
                  setRowToChangeStatus(null);
                  toast.success(t(`customer.user_status_changed_successfuly`));
                  getData();
                }
              )
            );
          }}
          isConfirmLoading={customer?.isLoading}
          confirmLabel={t("app.confirm")}
          action={null}
        />
      )}

      {rowToChangeStockistRequest && (
        <ConfirmDialog
          open={true}
          variant="success"
          onClose={() => setRowToChangeStockistRequest(null)}
          disabled={customer?.isLoading}
          title={t("customer.change_stockist_request")}
          content={t(`customer.select_a_state_bellow`)}
          onConfirm={() => {
            setSelectedRequestStatus("ACCEPTED");
          }}
          isConfirmLoading={customer?.isLoading}
          confirmLabel={t("app.accept")}
          action={
            <LoadingButton
              loading={customer.isLoading}
              disabled={customer.isLoading}
              variant="contained"
              color={"error"}
              onClick={() => {
                setSelectedRequestStatus("REFUSED");
              }}
            >
              {t("app.reject")}
            </LoadingButton>
          }
        />
      )}

      {selectedStockistStatus && (
        <ConfirmDialog
          open={true}
          variant="success"
          onClose={() => setSelectedRequestStatus("")}
          disabled={customer?.isLoading}
          title={t("customer.change_stockist_request")}
          content={t(
            `customer.${
              selectedStockistStatus === "ACCEPTED"
                ? "really_want_to_accept_this_request"
                : "really_want_to_refuse_this_request"
            }`
          )}
          onConfirm={() => {
            dispatch(
              customerEffects.change_request_state(
                {
                  applicant_uuid: rowToChangeStockistRequest?.uuid ?? "",
                  response: selectedStockistStatus,
                },
                () => {
                  setRowToChangeStockistRequest(null);
                  setSelectedRequestStatus("");
                  getData();
                  toast.success(
                    t(`customer.request_state_changed_successfully`)
                  );
                }
              )
            );
          }}
          isConfirmLoading={customer?.isLoading}
          confirmLabel={t("app.confirm")}
          action={null}
        />
      )}
    </>
  );
};

export default CustomersPage;
