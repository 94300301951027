// @mui
import useTranslation from "@/core/hooks/useTranslation";
import { Checkbox, Stack, Typography } from "@mui/material";

// ----------------------------------------------------------------------

export interface ITableSelectedActionProps {
  sx?: object;
  dense?: boolean;
  action?: any;
  rowCount: number;
  numSelected?: number;
  female?: boolean;
  onSelectAllRows?: (checked: boolean) => void;
}

const TableSelectedAction: React.FC<ITableSelectedActionProps> = ({
  dense,
  action,
  rowCount,
  numSelected,
  onSelectAllRows,
  female,
  sx,
  ...other
}) => {
  if (!numSelected) {
    return null;
  }

  const t = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        pl: 1,
        pr: 2,
        top: 0,
        left: 0,
        width: 1,
        zIndex: 9,
        height: 58,
        position: "absolute",
        bgcolor: "primary.lighter",
        ...(dense && {
          height: 38,
        }),
        ...sx,
      }}
      {...other}
    >
      {onSelectAllRows && (
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={(event) => onSelectAllRows(event.target.checked)}
        />
      )}

      <Typography
        variant="subtitle1"
        sx={{
          ml: 2,
          flexGrow: 1,
          color: "primary.main",
          ...(dense && {
            ml: 3,
          }),
        }}
      >
        {numSelected}{" "}
        {t(
          `app.selected${numSelected > 1 ? "_plural" : ""}${
            female ? "_female" : ""
          }`
        )}
      </Typography>

      {action && action}
    </Stack>
  );
};

export default TableSelectedAction;
