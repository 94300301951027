const status = {
  all: "Tous",
  active: "Actif",
  inactive: "Inactif",
  active_plural: "Actifs",
  inactive_plural: "Inactifs",
  online: "En ligne",
  offline: "Hors ligne",
  paid: "Payé",
  unpaid: "Non payé",
  overdue: "En retard",
  draft: "Brouillon",
  ACTIVED: "Activé",
  UNACTIVED: "Désactivé",
  DRAFT: "Brouillon",
  PUBLISH: "Publié",
  IN_PROGRESS: "En cours",
  PENDING: "Initié",
  BOOK: "Réservé",
  ACCEPTED: "Accepté",
  PAID: "Payé",
  PAID_plural: "Payés",
  REJECTED: "Rejeté",
  REFUSED: "Refusé",
  ASKED: "Demandé",
  ACTIVED_female: "Activée",
  UNACTIVED_female: "Désactivée",
  DRAFT_female: "Brouillon",
  PUBLISH_female: "Publié",
  IN_PROGRESS_female: "En cours",
  PENDING_female: "Initiée",
  BOOK_female: "Réservée",
  ACCEPTED_female: "Acceptée",
  PAID_female: "Payée",
  REJECTED_female: "Rejetée",
  REFUSED_female: "Refusée",
  ASKED_female: "Demandée",
  UNPAID: "Impayé",
  UNPAID_female: "Impayée",
  UNPAID_plural: "Impayés",
};
export default status;
