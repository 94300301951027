import ProvisioningService from "@/app/services/api/ProvisioningService";
import {
  IProvisioningCreate,
  IProvisioningRange,
  IProvisioningUpdate,
} from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface IProvisioningEffects
  extends IReduxBaseCrudEffect<
    IProvisioningRange,
    IProvisioningCreate,
    IProvisioningUpdate,
    any
  > {}

class ProvisioningEffects
  extends BaseReduxCrudEffects<
    IProvisioningRange,
    IProvisioningCreate,
    IProvisioningUpdate
  >
  implements IProvisioningEffects {}

const provisioningEffects = new ProvisioningEffects(slice, ProvisioningService);

export default provisioningEffects;
