import { IProductCreate, IProductRange, IProductUpdate } from "@/app/types";
import BaseCrudService from "./BaseCrudService";
import BaseService from "./BaseService";
import { ProductUrls } from "./urls";

class ProductService extends BaseCrudService<
  IProductCreate,
  IProductUpdate,
  IProductRange
> {
  duplicate = (id: string) => {
    return BaseService.postRequest(ProductUrls.DUPLICATE(id), {}, true);
  };
}

export default new ProductService(ProductUrls);
