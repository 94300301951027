import { PATH_DASHBOARD } from "@/app/routes/paths";
import { ICustomer } from "@/app/types";
import Iconify from "@/core/components/iconify";
import Image from "@/core/components/image";
import Label from "@/core/components/label";
import useCurrency from "@/core/hooks/useCurrency";
import useTranslation from "@/core/hooks/useTranslation";
import getFullName, { getValueOrNA } from "@/core/utils/getFullName";
import AppModal from "@/presentation/components/AppModal";
import OrdersPage from "@/presentation/pages/dashboard/OrdersPage";
import {
  Card,
  Divider,
  Tab,
  Typography,
  Box,
  Stack,
  Tabs,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { isNaN } from "lodash";
import moment from "moment";
import React from "react";
import CommissionsPage from "../../../CommissionPage";

export interface ICustomerDetailsModalProps {
  customer: ICustomer;
  onClose: () => void;
}

const CustomerDetailsModal: React.FC<ICustomerDetailsModalProps> = ({
  onClose,
  customer,
}) => {
  // Hooks
  const t = useTranslation();
  const toCurrency = useCurrency();

  // State
  const [currentTab, setCurrentTab] = React.useState("general");

  // Constants
  const TABS = React.useMemo(
    () => [
      {
        value: "general",
        label: "label.profile",
        icon: <Iconify icon="ic:round-account-box" />,
        component: (
          <Box>
            {Object.keys(customer)
              .filter(
                (key) =>
                  ![
                    "firstname",
                    "lastname",
                    "phonenumber",
                    "status",
                    "date_modified",
                  ].includes(key)
              )
              .map((key, index) =>
                !["string", "number"].includes(typeof (customer as any)[key]) ||
                key === "uuid" ? null : (
                  <Stack key={`${key}${index}`}>
                    <Typography
                      variant="body1"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        color: "text.secondary",
                        "& span": {
                          fontWeight: 700,
                        },
                      }}
                    >
                      {t(`label.${key}`)} <Divider sx={{ flexGrow: 1 }} />
                      <span>
                        {getValueOrNA(
                          (key.includes("cost") || key.includes("price")
                            ? toCurrency
                            : (v: any) => v)(
                            ["status", "become_stockist"].includes(key)
                              ? t(`status.${(customer as any)[key]}`)
                              : !isNaN(Date.parse((customer as any)[key]))
                              ? moment((customer as any)[key]).format("ll")
                              : (customer as any)[key]
                          )
                        )}{" "}
                      </span>
                    </Typography>
                  </Stack>
                )
              )}
          </Box>
        ),
      },
      {
        value: "orders",
        label: "label.orders",
        icon: <Iconify icon="ic:round-receipt" />,
        component: (
          <OrdersPage
            showEvolution
            noPage
            additionalFilters={{ user_uuid: customer?.uuid }}
          />
        ),
      },
      {
        value: "bookings",
        label: "commission.root_plural",
        icon: <Iconify icon="mdi:dollar" />,
        // component: <Box />,
        component: <CommissionsPage noPage />,
      },
    ],
    [currentTab]
  );

  return (
    <AppModal
      breadCrumbLinks={[
        {
          name: "dashboard.root",
          href: PATH_DASHBOARD.root,
        },
        {
          name: "customer.root_plural",
        },
        {
          name: getFullName(customer?.firstname, customer?.lastname),
        },
      ]}
      maxWidth="md"
      onClose={onClose}
    >
      <Stack
        justifyContent={"center"}
        // alignItems={"start"}
        sx={{ mt: 3 }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={2}
          flexWrap="wrap"
          sx={{ mb: 3 }}
        >
          <Stack
            gap={2}
            direction={"row"}
          >
            <Image
              // @ts-ignore
              alt={getFullName(customer?.firstname, customer?.lastname)}
              disableEffect
              src={customer?.avatar?.url ?? ""}
              style={{ width: 95, height: 95, objectFit: "cover" }}
              sx={{
                borderRadius: "999px",
                boxShadow: 1,
              }}
            />
            <ListItemText
              primary={getFullName(customer?.firstname, customer?.lastname)}
              primaryTypographyProps={{
                fontWeight: 600,
                fontSize: "18px",
              }}
              secondary={
                !customer?.phonenumber ? undefined : (
                  <Stack>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      {customer?.phonenumber}
                    </Typography>
                    <Typography
                      sx={{ display: "inline", mt: 0.5, fontWeight: "bold" }}
                      component="span"
                      variant="body2"
                    >
                      {t("label.grade")}: {getValueOrNA(customer?.grade?.label)}
                    </Typography>
                  </Stack>
                )
              }
            />
          </Stack>
          <Box sx={{ ml: "auto" }}>
            {/* @ts-ignore */}
            <Label
              // @ts-ignore
              color={customer?.status === "ACTIVED" ? "success" : "error"}
              title={customer?.status ? t(`status.${customer?.status}`) : ""}
              sx={{ color: "text.secondary" }}
            >
              {t(`status.${customer?.status}`)}
            </Label>
          </Box>
        </Stack>
        <Tabs
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              label={t(tab.label)}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>
      </Stack>
      {TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <Box
              key={tab.value}
              sx={{ mt: 2 }}
            >
              {tab.component}
            </Box>
          )
      )}
    </AppModal>
  );
};

export default CustomerDetailsModal;
