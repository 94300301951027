import BaseService from "./BaseService";
import { StatUrls } from "./urls";

class StatService {
  static get = (resource?: string) => {
    return BaseService.getRequest(StatUrls.GET(resource), true);
  };
}

export default StatService;
