import {
  IArticleCategoryCreate,
  IArticleCategoryRange,
  IArticleCategoryUpdate,
} from "@/app/types";
import BaseCrudService from "./BaseCrudService";
import { ArticleCategoryUrls } from "./urls";

class ArticleCategoryService extends BaseCrudService<
  IArticleCategoryCreate,
  IArticleCategoryUpdate,
  IArticleCategoryRange
> {}

export default new ArticleCategoryService(ArticleCategoryUrls);
