import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React from "react";
import { Eye, EyeOff } from "react-feather";

export type IAppTextFieldProps = TextFieldProps & {};

const AppTextField: React.FC<IAppTextFieldProps> = ({ type, ...other }) => {
  // State
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  // Functions
  const handleShowPassword = () => {
    setShowPassword((state) => !state);
  };

  return (
    <TextField
      type={showPassword ? "text" : type}
      InputProps={
        type === "password"
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword}>
                    {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : undefined
      }
      {...other}
    />
  );
};

export default AppTextField;
