import GLOBAL_DATA_FALLBACK from "@/app/global-data-fallback";
import { IGlobalDataState } from "@/app/types";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";

export const globalDataInitialState: IGlobalDataState = {
  data: GLOBAL_DATA_FALLBACK,
};

export const slice = createSlice<
  typeof globalDataInitialState,
  typeof reducers,
  typeof sliceNames.globalData
>({
  name: sliceNames.globalData,
  initialState: globalDataInitialState,
  reducers,
});

export default slice.reducer;
