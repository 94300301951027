import { IAmbassadeur } from "@/app/types";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";
import getAppDate from "@/core/utils/getAppDate";
import getFullName, { getValueOrNA } from "@/core/utils/getFullName";

// const ambassadeurColumns: ITableRowColumn<IAmbassadeur>[] = [
const ambassadeurColumns: ITableRowColumn<any>[] = [
  {
    header: { id: "name", label: "ambassadeur.adhesion", align: "left" },
    // type: "avatar",
    title: (row) => getAppDate(row?.User?.date_added),
    // title: (row) => getFullName(row?.firstname, row?.lastname),
    // avatarUrl: (row) => row?.avatar?.url ?? "",
  },
  {
    header: {
      id: "phonenumber",
      label: "ambassadeur.root",
      align: "left",
    },
    title: (row) => getFullName(row?.User?.firstname, row?.User?.lastname),
    // title: (row) => row?.phonenumber,
    align: "left",
  },
  {
    header: { id: "role", label: "ambassadeur.parrain", align: "left" },
    // title: (row) => row?.role?.title?.[`${locale as IAppLocale}`],
    title: (row) =>
      getFullName(
        row?.User?.sponsor?.firstname,
        row?.User?.sponsor?.lastname
      ) ?? "pas de parrain",
    align: "left",
  },
  {
    header: { id: "status", label: "ambassadeur.equipe", align: "left" },
    title: (row) => row?.total_team ?? "0",
    // type: "badge",
    // title: (row) => `status.${row?.status ?? ""}`,
    // badgeColor: (row) => (row?.status === "ACTIVED" ? "success" : "error"),
    // sx: { textTransform: "capitalize" },
  },
  {
    header: {
      id: "become_stockist",
      label: "ambassadeur.vente",
      align: "left",
    },
    title: (row) => row?.total_sales,
    // title: (row) =>
    //   !row?.become_stockist
    //     ? DEFAULT_NA_STRING
    //     : `status.${row?.become_stockist ?? ""}_female`,
    // sx: { textTransform: "capitalize" },
  },
  {
    header: {
      id: "become_stockist",
      label: "ambassadeur.com",
      align: "left",
    },
    title: (row) => row?.User?.self_earned_biocoins ?? 0,
    // title: (row) =>
    //   !row?.become_stockist
    //     ? DEFAULT_NA_STRING
    //     : `status.${row?.become_stockist ?? ""}_female`,
    // sx: { textTransform: "capitalize" },
  },
  {
    header: {
      id: "become_stockist",
      label: "ambassadeur.comPieds",
      align: "left",
    },
    title: (row) => row?.User?.sponsored_users_biocoins ?? "",
    // title: (row) =>
    //   !row?.become_stockist
    //     ? DEFAULT_NA_STRING
    //     : `status.${row?.become_stockist ?? ""}_female`,
    // sx: { textTransform: "capitalize" },
  },
  {
    header: {
      id: "become_stockist",
      label: "ambassadeur.comTotal",
      align: "left",
    },
    title: (row) => row?.User?.biocoins ?? 0,
    // title: (row) =>
    //   !row?.become_stockist
    //     ? DEFAULT_NA_STRING
    //     : `status.${row?.become_stockist ?? ""}_female`,
    // sx: { textTransform: "capitalize" },
  },
  {
    header: {
      id: "become_stockist",
      label: "ambassadeur.gain",
      align: "left",
    },
    title: (row) => row.direct_gains,
    // sx: { textTransform: "capitalize" },
  },
  // {
  //   header: {
  //     id: "become_stockist",
  //     label: "ambassadeur.point",
  //     align: "left",
  //   },
  //   // title: (row) =>
  //   //   !row?.become_stockist
  //   //     ? DEFAULT_NA_STRING
  //   //     : `status.${row?.become_stockist ?? ""}_female`,
  //   // sx: { textTransform: "capitalize" },
  // },
];

export default ambassadeurColumns;
