import {
  IProvisioningCreate,
  IProvisioningRange,
  IProvisioningUpdate,
} from "@/app/types";
import BaseCrudService from "./BaseCrudService";
import { ProvisioningUrls } from "./urls";

class ProvisioningService extends BaseCrudService<
  IProvisioningCreate,
  IProvisioningUpdate,
  IProvisioningRange
> {}

export default new ProvisioningService(ProvisioningUrls);
