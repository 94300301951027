import Iconify from "@/core/components/iconify";
import useTranslation from "@/core/hooks/useTranslation";
import AppModal from "@/presentation/components/AppModal";
import AutoForm, { IAutoFormFields } from "@/presentation/components/AutoForm";
import { dispatch, useSelector } from "@/redux/store";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React from "react";
import { slice as autoFormslice } from "@/redux/slices/autoForm";
import { getAutoCompleteLabelStateName } from "@/presentation/components/AutoForm/utils";
import { AUTOCOMPLETE_PREFIX } from "@/app/config-global";

export interface ICreateEditModalProps {
  fields: IAutoFormFields;
  onClose: () => void;
  onSave: (values: any) => void;
  model: string;
  rowToEdit: any;
  female?: boolean;
  stateData?: any;
  idProp?: string;
  slice?: any;
  open?: boolean;
}

const CreateEditModal: React.FC<ICreateEditModalProps> = ({
  fields,
  onClose,
  model,
  onSave,
  rowToEdit,
  female,
  stateData,
  idProp,
  slice,
  open,
}) => {
  const formRef = React.useRef<HTMLButtonElement>(null);
  const t = useTranslation();
  const loadingFile = useSelector((state) => state.autoForm.loadingFile);

  const autocompleteLabelsValues = useSelector(
    (state) => state.autoForm.autocompleteLabels
  );

  React.useEffect(
    () => () => {
      // @ts-ignore
      dispatch(autoFormslice.actions.setValuesToHold(null));
      Object.keys(autocompleteLabelsValues || {}).map((name) => {
        if (name.startsWith(`uuid-${AUTOCOMPLETE_PREFIX}`)) {
          dispatch(
            autoFormslice.actions.setAutoCompleteLabel({
              label: name,
              value: "",
            } as never)
          );
        }
      });
      Object.keys(fields || {}).map((fieldName) => {
        const field = fields[fieldName];
        const _name = getAutoCompleteLabelStateName(field, fieldName);
        dispatch(
          autoFormslice.actions.setAutoCompleteLabel({
            label: _name,
            value: "",
          } as never)
        );
      });
    },
    [open]
  );
  return !open ? null : (
    <AppModal
      onClose={() => onClose()}
      hideClose={loadingFile || stateData?.isLoading}
      title={
        rowToEdit
          ? t(`app.update_model_title${female ? "_female" : ""}`, {
              model: `${model}.root`,
            })
          : t(`app.create_model_title${female ? "_female" : ""}`, {
              model: `${model}.root`,
            })
      }
      breadCrumbLinks={[
        {
          name: rowToEdit
            ? t(`app.update_model_description${female ? "_female" : ""}`, {
                model: `${model}.root`,
              })
            : t(`app.create_model_description${female ? "_female" : ""}`, {
                model: `${model}.root`,
              }),
        },
      ]}
      actions={[
        // @ts-ignore
        <LoadingButton
          key={`${model}-save`}
          loading={loadingFile || stateData?.isLoading}
          variant="contained"
          color="primary"
          onClick={() => formRef.current?.click()}
          startIcon={
            rowToEdit ? (
              <Iconify icon="eva:checkmark-fill" />
            ) : (
              <Iconify icon="eva:plus-fill" />
            )
          }
        >
          {t(`app.${rowToEdit ? "update" : "create"}`)}
        </LoadingButton>,
        <Button
          key={`${model}-cancel`}
          disabled={loadingFile || stateData?.isLoading}
          variant="contained"
          color="error"
          onClick={onClose}
          startIcon={<Iconify icon="eva:close-fill" />}
        >
          {t("app.cancel")}
        </Button>,
      ]}
    >
      <AutoForm
        initialItem={rowToEdit}
        valueOnResetDepends={!open}
        setLoading={
          slice ? () => dispatch(slice.actions.startLoading()) : undefined
        }
        ref={formRef}
        fields={fields}
        onSubmit={(values: any) =>
          onSave({
            ...values,
            ...(rowToEdit && idProp ? { [idProp]: rowToEdit[idProp] } : {}),
          })
        }
        hideButton
      />
    </AppModal>
  );
};

export default CreateEditModal;
