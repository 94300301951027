import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { Box, Divider, MenuItem, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
// routes
import { PATH_AUTH } from "@/app/routes/paths";
// auth
import { useAuthContext } from "@/app/auth/useAuthContext";
// components
import { useLocales } from "@/app/locales";
import { IconButtonAnimate } from "@/core/components/animate";
import { CustomAvatar } from "@/core/components/custom-avatar";
import MenuPopover from "@/core/components/menu-popover";
import { useSnackbar } from "@/core/components/snackbar";
import useTranslation from "@/core/hooks/useTranslation";
import getFullName from "@/core/utils/getFullName";

// ----------------------------------------------------------------------

const OPTIONS = [
  // {
  //   label: "Home",
  //   linkTo: "/",
  // },
  // {
  //   label: "Profile",
  //   linkTo: PATH_DASHBOARD.user.profile,
  // },
  // {
  //   label: "Settings",
  //   linkTo: PATH_DASHBOARD.user.account,
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { user, logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();
  const { currentLang } = useLocales();
  const t = useTranslation();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar
          src={user?.avatar?.thumbnail?.url}
          alt={getFullName(user?.firstname, user?.lastname, user?.email)}
          name={getFullName(user?.firstname, user?.lastname, user?.email)}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            variant="subtitle2"
            noWrap
          >
            {getFullName(user?.firstname, user?.lastname, user?.email)}
          </Typography>

          <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
            noWrap
          >
            {user?.phonenumber}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {/* <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleClickItem(option.linkTo)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        {/* <Divider sx={{ borderStyle: "dashed" }} /> */}

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1 }}
        >
          {t("auth.logout")}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
