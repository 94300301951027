import {
  ITablePageOptionListItem,
  IToolbarOption,
} from "@/presentation/components/TablePage";

export const orderColorByStatus = {
  PENDING: "default",
  BOOK: "info",
  IN_PROGRESS: "warning",
  ACCEPTED: "success",
  PAID: "success",
};

export const orderPaymentTypeIcons = {
  GOOGLE_PAY: "logos:google-pay",
  STRIPE: "logos:stripe",
  APPLE_PAY: "logos:apple-pay",
  PAYPAL: "logos:paypal",
  CASH: "emojione:money-bag",
};

export const orderTableTopOptions = [
  {
    label: "order.root_plural",
    value: "",
  },
  {
    label: "label.supplies",
    value: "True",
  },
];
export const orderToolbarOptions = (
  statuses: ITablePageOptionListItem[],
  roles: ITablePageOptionListItem[],
  keyword: {
    value: string;
    onChange: (value: string) => void;
  },
  status: {
    value: string;
    onChange: (value: string) => void;
  },
  role: {
    value: string;
    onChange: (value: string) => void;
  }
): IToolbarOption[] => [
  {
    name: "admin_search",
    placeholder: "placeholder.search",
    type: "input",
    ...keyword,
  },
  {
    name: "status",
    type: "select",
    options: statuses,
    placeholder: "app.status",
    ...status,
  },
  {
    name: "role_uuid",
    type: "select",
    options: roles,
    placeholder: "app.role",
    ...role,
  },
];
