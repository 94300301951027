import { DEFAULT_RANGE } from "@/app/config-global";
import CustomerService from "@/app/services/api/CustomerService";
import { IAppLocale, ICustomer, IProvisioning } from "@/app/types";
import buildValidators from "@/core/utils/buildValidators";
import getFullName, { getValueOrNA } from "@/core/utils/getFullName";
import { IAutoFormFields } from "@/presentation/components/AutoForm";

const provisioningFields = (
  t: Function,
  lang: IAppLocale
): IAutoFormFields<IProvisioning> => {
  const v = buildValidators(t);
  return {
    head_office_uuid: {
      type: "autocomplete",
      initialOptions: (row) => row?.head_office,
      getOptions: (_initialOption) => [],
      loadOptions: async (inputValue) => {
        const res = await CustomerService.list_head_offices({
          ...DEFAULT_RANGE,
          keyword: inputValue,
        });
        const data = await res.json();
        return data;
      },
      getOptionLabel: (option: any) =>
        !option
          ? ""
          : `${getValueOrNA(option?.name)} (${getValueOrNA(
              option?.address?.country
            )}, ${getValueOrNA(option?.address?.town)}, ${getValueOrNA(
              option?.address?.quarter
            )})`,
      getOptionValue: (option: ICustomer) => option?.uuid,
      initialValue: (row: IProvisioning) => row?.head_office?.uuid,
      label: "label.head_office",
      placeholder: "placeholder.head_office",
      title: "title.head_office",
      props: {},
      validation: v.string,
    },
    stockist_uuid: {
      type: "autocomplete",
      initialOptions: (row) => row?.stockist,
      getOptions: (_initialOption) => [],
      loadOptions: async (inputValue) => {
        const res = await CustomerService.list_stockist({
          ...DEFAULT_RANGE,
          keyword: inputValue,
          filter: "stockist",
        });
        const data = await res.json();
        return data;
      },
      getOptionLabel: (option: ICustomer) =>
        !option
          ? ""
          : `${getFullName(
              option?.firstname,
              option?.lastname
            )} (${getValueOrNA(option?.address?.country)}, ${getValueOrNA(
              option?.address?.town
            )}, ${getValueOrNA(option?.address?.quarter)})`,
      getOptionValue: (option: ICustomer) => option?.uuid,
      initialValue: (row: IProvisioning) => row?.stockist?.uuid,
      label: "label.stockist",
      placeholder: "placeholder.stockist",
      title: "title.stockist",
      props: {},
      validation: v.string,
    },
    products: {
      type: "product-qty",
      initialValue: () => [],
      label: "label.products",
      placeholder: "placeholder.products",
      title: "title.products",
      props: {},
      validation: v.arrayOfObject,
    },
    supply_date: {
      initialValue: (row) => row?.supply_date,
      type: "date",
      label: "label.supply_date",
      placeholder: "",
      title: "title.supply_date",
      props: {},
      useDateTimeFormat: true,
      validation: v.string_nr,
    },
  };
};

export default provisioningFields;
