import { Autocomplete, AutocompleteProps } from "@mui/material";
import React from "react";

export interface IAppAsyncAutocompleteProps
  extends AutocompleteProps<any, any, any, any, any> {
  loadOptions?: (inputValue: string) => Promise<any>;
  options: any[];
  optionsResetDeps?: any[];
  getValue?: (option: any[]) => any;
  keyName?: string;
}

const AppAsyncAutocomplete: React.FC<IAppAsyncAutocompleteProps> = ({
  loadOptions,
  value,
  getValue,
  optionsResetDeps,
  keyName,
  ...props
}) => {
  // State
  const [optionsData, setOptionsData] = React.useState<any[]>(
    props.options ?? []
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  // Functions
  const getOptions = async (inputValue: string) => {
    if (loadOptions) {
      setLoading(true);
      setOptionsData([]);
      try {
        const _values = await loadOptions(inputValue);
        setOptionsData(_values);
      } catch (error) {
        return;
      } finally {
        setLoading(false);
      }
    } else {
      setOptionsData(props.options ?? []);
    }
  };

  // Effects
  React.useEffect(() => {
    if (optionsResetDeps) {
      setOptionsData([]);
    }
  }, [...(optionsResetDeps ?? [])]);

  return (
    <Autocomplete
      {...props}
      key={keyName}
      value={getValue ? getValue(optionsData) : value}
      loading={loadOptions && loading}
      options={optionsData}
      onFocus={(e) => {
        optionsData.length === 0 &&
          loadOptions &&
          getOptions((e.target as any).value ?? "");
        props.onFocus && props.onFocus(e);
      }}
      onInputChange={(event, value) => {
        getOptions(value);
        props.onInputChange && props.onInputChange(event, value, "input");
      }}
    />
  );
};

export default AppAsyncAutocomplete;
