const article = {
  root: "article",
  root_plural: "articles",
  create: "Create an article",
  publish: "Publish",
  enable_comments: "Enable comments",
  preview: "Glimpse",
  post: "Poster",
  update: "Edit an article",
  status_change_success: "Item status successfully changed",
  info_on_article_crud:
    "Don't forget to write the article in the languages **French** and **English**.",
};
export default article;
