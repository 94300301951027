import { ICustomerCreate, ICustomerRange, ICustomerUpdate } from "@/app/types";
import BaseCrudService from "./BaseCrudService";
import BaseService from "./BaseService";
import { CustomerUrls } from "./urls";

export class CustomerServiceClass extends BaseCrudService<
  ICustomerCreate,
  ICustomerUpdate,
  ICustomerRange
> {
  change_request_state = (payload: object) =>
    BaseService.putRequest(
      CustomerUrls.CHANGE_REQUEST_STATE(payload),
      {},
      true
    );
  list_stockist = (payload: object) =>
    BaseService.getRequest(CustomerUrls.LIST_STOCKISTS(payload), true);
  list_head_offices = (payload: object) =>
    BaseService.getRequest(CustomerUrls.LIST_HEAD_OFFICE(payload), true);
}

export default new CustomerServiceClass(CustomerUrls);
