import { IArticleCategory } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { articleCategoryInitialState, IArticleCategoryState } from ".";

export interface IArticleCategoryReducer
  extends IBaseCrudReducer<IArticleCategoryState, IArticleCategory> {
  resetState: (state: IArticleCategoryState) => void;
}

class ArticleCategoryReducer
  extends BaseCrudReducer<IArticleCategoryState, IArticleCategory>
  implements IArticleCategoryReducer
{
  resetState = (state: IArticleCategoryState) =>
    resetStateKeys(state, articleCategoryInitialState);
}

const reducers: IArticleCategoryReducer = new ArticleCategoryReducer();

export default reducers;
