export const formatValuesWithDoubleUnderscores = (values: any) => {
  const formattedObj: any = {};
  Object.keys(values).map((key) => {
    const keysArr = key.split("__");
    const currentObj =
      keysArr.length > 1 ? formattedObj[keysArr[0]] || {} : values[key];
    keysArr.map((keyPart, index) => {
      if (index !== 0) {
        if (index === 1) {
          currentObj[keyPart] = index === keysArr.length - 1 ? values[key] : {};
        } else {
          currentObj[keysArr[index - 1]][keyPart] =
            index === keysArr.length - 1 ? values[key] : {};
        }
      }

      if (index === keysArr.length - 1) {
        formattedObj[keysArr[0]] = currentObj;
      }
    });
  });
  return formattedObj;
};

export const getInitialItemWithDoubleUnderscore = (
  initialItem: any,
  propWithDot: string
) => {
  const keys = propWithDot.split("__");
  let finalValue: any;
  keys.forEach((key, index) => {
    if (index === 0) {
      finalValue = initialItem[key];
    } else {
      finalValue = finalValue[key];
    }
  });
  return finalValue;
};

export const getAutoCompleteLabelStateName = (field: any, fieldName: string) =>
  `${fieldName}-${field.prefix}`;
