// ----------------------------------------------------------------------
import app from "./base/app";
import auth from "./base/auth";
import error from "./base/error";
import internet from "./base/internet";
import label from "./base/label";
import models from "./base/models";
import pagination from "./base/pagination";
import placeholder from "./base/placeholder";
import status from "./base/status";
import title from "./base/title";
import administrator from "./pages/administrator";
import article from "./pages/article";
import chat from "./pages/chat";
import chatBot from "./pages/chatBot";
import customer from "./pages/customer";
import dashboard from "./pages/dashboard";
import login from "./pages/login";
import order from "./pages/order";
import product from "./pages/product";
import professional from "./pages/professional";
import stat from "./pages/stat";
import video from "./pages/video";
import booking from "./pages/booking";
import articleCategory from "./pages/articleCategory";
import paymentRequest from "./pages/paymentRequest";
import grade from "./pages/grade";
import comission from "./pages/comission";
// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.
// ----------------------------------------------------------------------
const fr = {
  models,
  auth,
  status,
  app,
  error,
  internet,
  placeholder,
  label,
  title,
  pagination,
  dashboard,
  administrator,
  product,
  customer,
  order,
  chat,
  video,
  chatBot,
  article,
  stat,
  professional,
  login,
  booking,
  articleCategory,
  paymentRequest,
  grade,
  comission,
};
export default fr;
