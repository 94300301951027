const chat = {
  root: "Conversation",
  root_plural: "Conversations",
  no_data: "Vous n'avez pas de conversation pour le moment",
  no_recipients: "Vous n'avez pas de destinataires",
  type_your_message: "Entrez votre message",
  to: "À",
  conversation_start: "Début de la conversation",
  no_conversation: "Pas de conversation sélectionnée",
  no_conversation_text:
    "Démarrer une conversation en sélectionnant un utilisateur dans les champs 'À' ci-dessus ou sélectionnez une conversation à gauche si vous en avez.",
  you: "Vous",
};
export default chat;
