const status = {
  all: "All",
  active: "Active",
  inactive: "Inactive",
  active_plural: "Assets",
  inactive_plural: "Inactive",
  online: "Online",
  offline: "Offline",
  paid: "Paid",
  unpaid: "Unpaid",
  overdue: "Late",
  draft: "Draft",
  ACTIVED: "Activated",
  UNACTIVED: "Disabled",
  DRAFT: "Draft",
  PUBLISH: "Published",
  IN_PROGRESS: "In progress",
  PENDING: "Insider",
  BOOK: "Reserved",
  ACCEPTED: "Accepted",
  PAID: "Paid",
  PAID_plural: "Paid",
  REJECTED: "Rejected",
  REFUSED: "Refused",
  ASKED: "Requested",
  ACTIVED_female: "Activated",
  UNACTIVED_female: "Disabled",
  DRAFT_female: "Draft",
  PUBLISH_female: "Published",
  IN_PROGRESS_female: "In progress",
  PENDING_female: "Initiated",
  BOOK_female: "Reserved",
  ACCEPTED_female: "Accepted",
  PAID_female: "Paid",
  REJECTED_female: "Rejected",
  REFUSED_female: "Refused",
  ASKED_female: "Requested",
  UNPAID: "Unpaid",
  UNPAID_female: "Unpaid",
  UNPAID_plural: "Unpaid",
};
export default status;
