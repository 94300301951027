import React from "react";

const ScrollTo: React.FC<{ children: any; deps?: any[] }> = ({
  children,
  deps,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [...(deps ?? [])]);

  return <div ref={ref}>{children}</div>;
};

export default ScrollTo;
