const ambassadeur = {
  root: "Ambassadeurs",
  root_plural: "Meilleurs ambassadeurs",
  adhesion: "Adhésion",
  parrain: "Parrain",
  equipe: "Equipe",
  vente: "Ventes",
  com: "COM",
  comPieds: "COM PIEDS",
  comTotal: "COM TOTAL",
  gain: "Gain direct",
  point: "Points cumulés",
};
export default ambassadeur;
