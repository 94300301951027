import { ACCEPTED_FILE_TYPES } from "@/app/config-global";
import FileService from "@/app/services/api/FileService";
import { IGlobalDataState, IProduct } from "@/app/types";
import buildValidators from "@/core/utils/buildValidators";
import { IAutoFormFields } from "@/presentation/components/AutoForm";

const productFields = (
  t: Function,
  lang: string,
  categories: IGlobalDataState["data"]["categories"]
): IAutoFormFields<IProduct> => {
  const v = buildValidators(t);

  return {
    code: {
      // @ts-ignore
      initialValue: (row) => row?.code,
      label: "label.code",
      placeholder: "placeholder.code",
      title: "title.code",
      props: {},
      validation: v.string,
    },
    name__fr: {
      initialValue: (row) => row?.name?.fr,
      label: "label.name_fr",
      placeholder: "placeholder.name_fr",
      title: "title.name_fr",
      props: {},
      validation: v.string,
    },
    name__en: {
      initialValue: (row) => row?.name?.en,
      label: "label.name_en",
      placeholder: "placeholder.name_en",
      title: "title.name_en",
      props: {},
      validation: v.string,
    },
    description__fr: {
      type: `textarea`,
      initialValue: (row) => row?.description?.fr,
      label: "label.description_fr",
      placeholder: "placeholder.description_fr",
      title: "title.description_fr",
      props: {},
      validation: v.string,
    },
    description__en: {
      type: `textarea`,
      initialValue: (row) => row?.description?.en,
      label: "label.description_en",
      placeholder: "placeholder.description_en",
      title: "title.description_en",
      props: {},
      validation: v.string,
    },
    features: {
      // @ts-ignore
      initialValue: (row) => row?.features,
      label: "label.features",
      placeholder: "placeholder.features",
      title: "title.features",
      props: {},
      validation: v.string,
    },
    weight: {
      initialValue: (row) => row?.weight,
      label: "label.weight",
      placeholder: "placeholder.weight",
      title: "title.weight",
      props: {},
      validation: v.string,
    },
    volume: {
      // @ts-ignore
      initialValue: (row) => row?.volume,
      label: "label.volume",
      placeholder: "placeholder.volume",
      title: "title.volume",
      props: {},
      validation: v.string,
    },
    categories: {
      type: "autocomplete",
      multiple: true,
      getOptions: () => categories,
      getOptionLabel: (option) => option?.title?.[lang],
      getOptionValue: (option) => option?.uuid,
      initialValue: (row) => row?.categories?.map((c) => c.uuid),
      label: "label.categories",
      placeholder: "placeholder.categories",
      title: "title.categories",
      props: {},
      validation: v.arrayOfString,
    },
    price: {
      initialValue: (row) => row?.price,
      label: "label.price",
      placeholder: "placeholder.price",
      title: "title.price",
      props: {
        type: "number",
      },
      validation: v.number,
    },
    stockist_price: {
      initialValue: (row) => row?.stockist_price,
      label: "label.stockist_price",
      placeholder: "label.stockist_price",
      title: "label.stockist_price",
      props: {
        type: "number",
      },
      validation: v.number,
    },
    recommended_price: {
      initialValue: (row) => row?.recommended_price,
      label: "label.recommended_price",
      placeholder: "placeholder.recommended_price",
      title: "title.recommended_price",
      props: {
        type: "number",
      },
      validation: v.number,
    },
    ambassador_biocoin: {
      initialValue: (row) => row?.ambassador_biocoin,
      label: "label.ambassador_biocoin",
      placeholder: "placeholder.ambassador_biocoin",
      title: "title.ambassador_biocoin",
      props: {
        type: "number",
      },
      validation: v.number,
    },
    stockist_biocoin_on_ambassador: {
      initialValue: (row) => row?.stockist_biocoin_on_ambassador,
      label: "label.stockist_biocoin_on_ambassador",
      placeholder: "placeholder.stockist_biocoin_on_ambassador",
      title: "title.stockist_biocoin_on_ambassador",
      props: {
        type: "number",
      },
      validation: v.number,
    },
    sponsor_biocoin: {
      initialValue: (row) => row?.sponsor_biocoin,
      label: "label.sponsor_biocoin",
      placeholder: "placeholder.sponsor_biocoin",
      title: "title.sponsor_biocoin",
      props: {
        type: "number",
      },
      validation: v.number,
    },
    stockist_biocoin: {
      initialValue: (row) => row?.stockist_biocoin,
      label: "label.stockist_biocoin",
      placeholder: "placeholder.stockist_biocoin",
      title: "title.stockist_biocoin",
      props: {
        type: "number",
      },
      validation: v.number,
    },
    stockist_minimum_qty: {
      initialValue: (row) => row?.stockist_minimum_qty,
      label: "label.stockist_minimum_qty",
      placeholder: "placeholder.stockist_minimum_qty",
      title: "title.stockist_minimum_qty",
      props: {
        type: "number",
      },
      validation: v.number,
    },
    files: {
      type: "uploader-multiple",
      ignoreFileStoring: (initialItem) => !initialItem,
      fileType: ACCEPTED_FILE_TYPES.image,
      initialValue: (row) =>
        row?.files?.map(
          (file, index) => file?.uuid ?? `product_update_${index}`
        ),
      initialOptions: (row) =>
        row?.files?.map((img, index) => ({
          // @ts-ignore
          uuid: img?.uuid ?? `product_update_${index}`,
          ...(img || {}),
        })),
      label: "label.images",
      placeholder: "placeholder.images",
      onFileDelete: async (row, fileUuid: string) =>
        await FileService.deleteProductImage(row?.uuid ?? "", fileUuid ?? ""),
      title: "title.images",
      validation: v.arrayOfString,
    },
  };
};

export default productFields;
