const models = {
  create_success: "{model} successfully created",
  update_success: "{model} successfully modified",
  delete_success: "{model} successfully deleted",
  delete_bulk_success: "{model} successfully deleted",
  create_success_female: "{model} successfully created",
  update_success_female: "{model} successfully modified",
  delete_success_female: "{model} successfully deleted",
  delete_bulk_success_female: "{model} successfully deleted",
};
export default models;
