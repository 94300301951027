import { IProvisioning } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IProvisioningReducer } from "./reducers";

export interface IProvisioningState extends IBaseCrudState<IProvisioning> {}

export const provisioningInitialState: IProvisioningState =
  baseCrudInitialState;

export const slice = createSlice<
  IProvisioningState,
  IProvisioningReducer,
  typeof sliceNames.provisioning
>({
  name: sliceNames.provisioning,
  initialState: provisioningInitialState,
  reducers,
});

export default slice.reducer;
