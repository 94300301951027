const paymentRequest = {
  root: "BioCoins payment request",
  root_plural: "payment requests",
  get_config_error: "Error recovering configuration",
  request_has_been_accepted: "The request was successfully accepted",
  request_has_been_refused: "The request was successfully denied",
  really_want_to_accept_this_request:
    "Are you sure you want to accept this request?",
  really_want_to_refuse_this_request:
    "Are you sure you want to refuse this request?",
};
export default paymentRequest;
