// @mui
import useTranslation from "@/core/hooks/useTranslation";
import { Box, Breadcrumbs, Link, Stack, Typography } from "@mui/material";
//
import LinkItem from "./LinkItem";

// ----------------------------------------------------------------------

export interface ICustomBreadcrumbsLink {
  name: string;
  href?: string;
}

export interface ICustomBreadcrumbsProps {
  sx?: object;
  action: any;
  links: ICustomBreadcrumbsLink[];
  heading: string;
  moreLink?: string[];
  activeLast?: boolean;
}

const CustomBreadcrumbs: React.FC<ICustomBreadcrumbsProps> = ({
  links,
  action,
  heading,
  moreLink,
  activeLast,
  sx,
  ...other
}) => {
  const lastLink = links.length > 0 ? links[links.length - 1].name : "";
  const t = useTranslation();

  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Stack
        direction="row"
        alignItems="center"
      >
        <Box sx={{ flexGrow: 1 }}>
          {/* HEADING */}
          {heading && (
            <Typography
              variant="h4"
              gutterBottom
            >
              {t(heading).charAt(0).toUpperCase()}
              {t(heading).substring(1)}
            </Typography>
          )}

          {/* BREADCRUMBS */}
          {!!links.length && (
            <Breadcrumbs
              separator={<Separator />}
              {...other}
            >
              {links.map((link) => (
                <LinkItem
                  key={link.name || ""}
                  link={link}
                  activeLast={activeLast}
                  disabled={link.name === lastLink}
                />
              ))}
            </Breadcrumbs>
          )}
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}> {action} </Box>}
      </Stack>

      {/* MORE LINK */}
      {!!moreLink && (
        <Box sx={{ mt: 2 }}>
          {moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: "table" }}
            >
              {href}
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
};

// ----------------------------------------------------------------------

function Separator() {
  return (
    <Box
      component="span"
      sx={{
        width: 4,
        height: 4,
        borderRadius: "50%",
        bgcolor: "text.disabled",
      }}
    />
  );
}

export default CustomBreadcrumbs;
