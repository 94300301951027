import { IProvisioning } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { provisioningInitialState, IProvisioningState } from ".";

export interface IProvisioningReducer
  extends IBaseCrudReducer<IProvisioningState, IProvisioning> {
  resetState: (state: IProvisioningState) => void;
}

class ProvisioningReducer
  extends BaseCrudReducer<IProvisioningState, IProvisioning>
  implements IProvisioningReducer
{
  resetState = (state: IProvisioningState) =>
    resetStateKeys(state, provisioningInitialState);
}

const reducers: IProvisioningReducer = new ProvisioningReducer();

export default reducers;
