import { ICommission } from "@/app/types";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";

const commissionColumns: ITableRowColumn<ICommission>[] = [
  {
    header: { id: "title_fr", label: "label.name_fr", align: "left" },
    type: "avatar",
    title: (row) => row.uuid,
    avatarUrl: (row) => row?.uuid ?? "",
  },
  {
    header: {
      id: "title_en",
      label: "label.name_en",
      align: "left",
    },
    title: (row) => row?.uuid ?? "",
    align: "left",
  },
];

export default commissionColumns;
