import { IOrder } from "@/app/types";
import buildValidators from "@/core/utils/buildValidators";
import { IAutoFormFields } from "@/presentation/components/AutoForm";

const orderFields = (t: Function): IAutoFormFields<IOrder> => {
  const v = buildValidators(t);
  return {
    firstname: {
      initialValue: (row) => "",
      label: "label.first_name",
      placeholder: "placeholder.first_name",
      title: "title.first_name",
      props: {},
      validation: v.string,
    },
    lastname: {
      initialValue: (row) => "",
      label: "label.last_name",
      placeholder: "placeholder.last_name",
      title: "title.last_name",
      props: {},
      validation: v.string,
    },
    email: {
      initialValue: (row) => "",
      label: "label.email",
      placeholder: "placeholder.email",
      title: "title.email",
      props: {},
      validation: v.email,
    },
    phonenumber: {
      initialValue: (row) => "",
      label: "label.phone_number",
      placeholder: "placeholder.phone_number",
      title: "title.phone_number",
      props: {},
      validation: v.number,
    },
    job: {
      initialValue: (row) => "",
      label: "label.job",
      placeholder: "placeholder.job",
      title: "title.job",
      props: {},
      validation: v.number,
    },
    bio: {
      initialValue: (row) => "",
      label: "label.bio",
      placeholder: "placeholder.bio",
      title: "title.bio",
      props: {},
      validation: v.number,
    },
    role_id: {
      initialValue: (row) => "",
      type: "select",
      getOptions: () => [{ label: "admin", value: "admin" }],
      getOptionLabel: (option) => option?.label,
      getOptionValue: (option) => option?.value,
      label: "label.role",
      placeholder: "placeholder.role",
      title: "title.role",
      props: {},
      validation: v.number,
    },
  };
};

export default orderFields;
