// routes
import administrator from "./locales/langs/fr/pages/administrator";
import article from "./locales/langs/fr/pages/article";
import chat from "./locales/langs/fr/pages/chat";
import chatBot from "./locales/langs/fr/pages/chatBot";
import customer from "./locales/langs/fr/pages/customer";
import dashboard from "./locales/langs/fr/pages/dashboard";
import order from "./locales/langs/fr/pages/order";
import product from "./locales/langs/fr/pages/product";
import professional from "./locales/langs/fr/pages/professional";
import video from "./locales/langs/fr/pages/video";
import articleCategory from "./locales/langs/fr/pages/articleCategory";
import { PATH_DASHBOARD } from "./routes/paths";
import sliceNames from "@/redux/names";
import { slice } from "lodash";

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || "";

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

export const DEFAULT_RANGE = {
  page: 1,
  per_page: 10,
  keyword: "",
  order_field: "",
  order: "desc",
};

export const DEFAULT_DEBOUNCE_DURATION = 500;

export const MIN_STAT_YEAR = 2020;

export const ACCEPTED_IMAGES = [".png", ".jpg", ".jpeg"];

export const ACCEPTED_VIDEOS = [".mp4"];

export const ACCEPTED_FILE_TYPES = {
  image: "image",
  video: "video",
};

export const DEFAULT_NA_STRING = "--/--";

export const AUTHORIZED_ROLES = {
  administrator: "administrator",
};

export const DEFAULT_CURRENCY = "XAF";

export const BIOCOIN_CURRENCY = "bcs";

export const DEFAULT_MIN_YEAR = 2019;

export const AUTOCOMPLETE_PREFIX = `product-qty-element-item-`;

export const APP_PERMISSIONS = {
  [sliceNames.stat]: {
    list: [AUTHORIZED_ROLES.administrator],
    read: [AUTHORIZED_ROLES.administrator],
  },
  [sliceNames.customer]: {
    list: [AUTHORIZED_ROLES.administrator],
    read: [AUTHORIZED_ROLES.administrator],
    create: [AUTHORIZED_ROLES.administrator],
    update: [AUTHORIZED_ROLES.administrator],
    delete: [AUTHORIZED_ROLES.administrator],
  },
  [sliceNames.product]: {
    list: [AUTHORIZED_ROLES.administrator],
    read: [AUTHORIZED_ROLES.administrator],
    create: [AUTHORIZED_ROLES.administrator],
    update: [AUTHORIZED_ROLES.administrator],
    delete: [AUTHORIZED_ROLES.administrator],
  },
  [sliceNames.order]: {
    list: [AUTHORIZED_ROLES.administrator],
    read: [AUTHORIZED_ROLES.administrator],
    create: [AUTHORIZED_ROLES.administrator],
    update: [AUTHORIZED_ROLES.administrator],
    delete: [AUTHORIZED_ROLES.administrator],
  },
  [sliceNames.articleCategory]: {
    list: [AUTHORIZED_ROLES.administrator],
    read: [AUTHORIZED_ROLES.administrator],
    create: [AUTHORIZED_ROLES.administrator],
    update: [AUTHORIZED_ROLES.administrator],
    delete: [AUTHORIZED_ROLES.administrator],
  },
  [sliceNames.grade]: {
    list: [AUTHORIZED_ROLES.administrator],
    read: [AUTHORIZED_ROLES.administrator],
    create: [AUTHORIZED_ROLES.administrator],
    update: [AUTHORIZED_ROLES.administrator],
    delete: [AUTHORIZED_ROLES.administrator],
  },
  [sliceNames.paymentRequest]: {
    list: [AUTHORIZED_ROLES.administrator],
    read: [AUTHORIZED_ROLES.administrator],
    create: [AUTHORIZED_ROLES.administrator],
    update: [AUTHORIZED_ROLES.administrator],
    delete: [AUTHORIZED_ROLES.administrator],
  },
  [sliceNames.provisioning]: {
    list: [AUTHORIZED_ROLES.administrator],
    read: [AUTHORIZED_ROLES.administrator],
    create: [AUTHORIZED_ROLES.administrator],
    update: [AUTHORIZED_ROLES.administrator],
    delete: [AUTHORIZED_ROLES.administrator],
  },
  [sliceNames.bestAmbassadeur]: {
    list: [AUTHORIZED_ROLES.administrator],
    read: [AUTHORIZED_ROLES.administrator],
    create: [AUTHORIZED_ROLES.administrator],
    update: [AUTHORIZED_ROLES.administrator],
    delete: [AUTHORIZED_ROLES.administrator],
  },
};
