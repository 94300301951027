import { IProduct } from "@/app/types";
import Image from "@/core/components/image/Image";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";
import { Link, Stack } from "@mui/material";
import moment from "moment";
import { useLocales } from "@/app/locales";

const productColumns: (
  fCurrency: (number: number) => string
) => ITableRowColumn<IProduct>[] = (fCurrency) => {
  const { currentLang } = useLocales();
  return [
    {
      header: { id: "name", label: "label.name", align: "left" },
      type: "avatar",
      title: (row) => row?.name?.[currentLang.value],
      avatarUrl: (row) => row?.files?.[0]?.url ?? "",
    },
    {
      header: {
        id: "price",
        label: "label.price",
        align: "left",
      },
      // type: "badge",
      title: (row: IProduct) => fCurrency(row?.price ?? 0),
      // badgeColor: (row) => (row?.is_online ? "SUCCESS" : "ERROR"),
      // sx: {textTransform: "capitalize"},
    },
    {
      header: {
        id: "biocoin_cost",
        label: "label.biocoin_cost",
        align: "left",
      },
      title: (row: IProduct) => fCurrency(row?.biocoin_cost ?? 0),
      align: "left",
    },
    {
      header: {
        id: "stockist_price",
        label: "label.stockist_price",
        align: "left",
      },
      // title: (row: IProduct) => fCurrency(row?.current_price ?? 0),
      title: (row: IProduct) => fCurrency(row?.stockist_price ?? 0),
      align: "left",
    },
    {
      header: {
        id: "ambassador_committee",
        label: "label.recommended_price",
        align: "left",
      },
      // title: (row: IProduct) => fCurrency(row?.current_price ?? 0),
      title: (row: IProduct) => fCurrency(row?.recommended_price ?? 0),
      align: "left",
    },
    {
      header: {
        id: "order_time",
        label: "label.order_time",
        align: "left",
      },
      // title: (row: IProduct) => fCurrency(row?.current_price ?? 0),
      title: (row: IProduct) => row?.number_ordered_times ?? 0,
      align: "left",
    },
  ];
};

export default productColumns;
