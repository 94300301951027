const error = {
  invalid_email_or_password: "Email ou mot de passe incorrect",
  file_upload_error:
    "Erreur lors de la sauvage du fichier pour le champ : {field}",
  this_field_is_required: "Ce champ est requis",
  enter_a_valid_email_address: "Entrez une adresse email valide",
  this_field_should_be_a_number: "Ce champ doit être numérique",
  add_at_least_one_item: "Ajoutez au moins 01 élément",
  unexpected_error_occurred:
    "Une erreur inattendue s'est produite lors du traitement de la requête",
  start_date_greather_than_end_date:
    "La date de début doit être supérieure à la date de fin",
};
export default error;
