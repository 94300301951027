export const prefix = process.env.REACT_APP_API_URL;
const version = process.env.REACT_APP_API_VERSION;

interface IUrlsOptions {
  query: any;
}

export const buildQuery = (options: IUrlsOptions, path?: string) =>
  Object.keys(options?.query ?? {})
    .map((key, index) => {
      const alreadyHaveQuestionMark: boolean = path?.includes("?") || index > 0;
      return options.query[key]
        ? `${alreadyHaveQuestionMark ? "&" : "?"}${key}=${encodeURIComponent(
            options.query[key]
          )}`
        : "";
    })
    .join("");

export const buildUrl = (path: string, options?: IUrlsOptions) =>
  `${prefix}/api/v${version}${path}${
    options?.query ? buildQuery(options, path) : ""
  }`;

export function buildCrudUrls<IRange>(model: string) {
  return {
    GET: (public_id: string) => buildUrl(`/${model}/${public_id}`),
    PAGINATE: (query: IRange) => buildUrl(`/${model}`, { query }),
    CREATE: buildUrl(`/${model}`),
    UPDATE: buildUrl(`/${model}`),
    DELETE: buildUrl(`/${model}`),
  };
}
