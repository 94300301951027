import PropTypes from "prop-types";
// @mui
import { List, Stack } from "@mui/material";
// locales
import { useLocales } from "@/app/locales";
//
import NavList from "./NavList";
import { StyledSubheader } from "./styles";
import { APP_PERMISSIONS } from "@/app/config-global";
import { useAuthContext } from "@/app/auth/useAuthContext";
import sliceNames from "@/redux/names";

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const { translate } = useLocales();
  const { user } = useAuthContext();

  return (
    <Stack
      sx={sx}
      {...other}
    >
      {data.map((group, index) => {
        const key =
          group.subheader ||
          group.items[0].title ||
          `default-group-key-${index}`;
        const items =
          group.items?.filter((item) => {
            const permissions =
              APP_PERMISSIONS[
                item.permissionProp ||
                  sliceNames[item.title?.split(".")?.[0] ?? ""]
              ]?.list;
            return permissions
              ? permissions.includes(user?.role?.code ?? "")
              : true;
          }) || [];

        return items?.length === 0 ? null : (
          <List
            key={key}
            disablePadding
            sx={{ px: 2 }}
          >
            {group.subheader && (
              <StyledSubheader disableSticky>{`${translate(
                group.subheader
              )}`}</StyledSubheader>
            )}

            {items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
