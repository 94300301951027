import { DialogAnimate } from "@/core/components/animate";
import CustomBreadcrumbs from "@/core/components/custom-breadcrumbs";
import Iconify from "@/core/components/iconify";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import ReactDOM from "react-dom";
import { ICustomBreadcrumbsLink } from "../TablePage";

export interface IAppModalProps {
  onClose: () => void;
  children: any;
  actions?: any;
  title?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  breadCrumbLinks?: ICustomBreadcrumbsLink[];
  hideClose?: boolean;
}

const AppModal: React.FC<IAppModalProps> = ({
  onClose,
  title,
  children,
  actions,
  breadCrumbLinks,
  maxWidth,
  hideClose,
}) => {
  return ReactDOM.createPortal(
    <DialogAnimate
      sx={{}}
      onClose={onClose}
      variants={{}}
      open={true}
      maxWidth={maxWidth ?? "sm"}
    >
      <DialogTitle
        sx={{
          borderBottom: "1px solid",
          borderColor: "#efefef",
          pt: !title && breadCrumbLinks ? 3 : 5,
          pb: 2,
          position: "relative",
        }}
      >
        {/* @ts-ignore */}
        <CustomBreadcrumbs
          heading={title ?? ""}
          links={breadCrumbLinks ?? []}
          sx={{ mb: 0, mt: 0 }}
        />
        {!hideClose && (
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              p: 2,
            }}
          >
            <IconButton onClick={onClose}>
              <Iconify
                width={24}
                icon="eva:close-fill"
              />
            </IconButton>
          </Box>
        )}
      </DialogTitle>
      <DialogContent sx={{ pb: 2 }}>{children}</DialogContent>
      {actions && (
        <DialogActions sx={{ borderTop: "1px solid", borderColor: "#efefef" }}>
          <Stack
            direction="row"
            columnGap={2}
          >
            {actions}
          </Stack>
        </DialogActions>
      )}
    </DialogAnimate>,
    document.body
  );
};

export default AppModal;
