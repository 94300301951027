import {
  IActionWithPayload,
  IBaseCrudError,
  IBaseCrudReducer,
} from "@/app/types/BaseReduxTypes";
import { resetStateKeys } from "@/redux/common/functions";

export default class BaseCrudReducer<IState, IReturnAction>
  implements IBaseCrudReducer<IState, IReturnAction>
{
  // eslint-disable-next-line
  [key: string]: (_state: IState, _action: IActionWithPayload<any>) => void;
  startLoading = (_state: any) => {
    _state.isLoading = true;
  };
  hasError = (_state: any, _action: IActionWithPayload<any>) => {
    _state.error = _action.payload as IBaseCrudError;
  };
  stopLoading = (_state: any) => {
    _state.isLoading = false;
  };
  get = (_state: any, _action: IActionWithPayload<any>) => {
    _state.currentItem = _action.payload;
  };
  paginate = (_state: any, _action: IActionWithPayload<any>) => {
    _state.list = _action.payload;
  };
  create = (_state: any, _action: IActionWithPayload<any>) => {};
  update = (_state: any, _action: IActionWithPayload<any>) => {};
  delete = (_state: any, _action: IActionWithPayload<any>) => {};
  reset = (_state: any, _action: IActionWithPayload<any>) => {
    resetStateKeys(_state, _action.payload);
  };
}
