import PropTypes from "prop-types";
// @mui
import { TableCell, TableRow } from "@mui/material";
//
import useTranslation from "@/core/hooks/useTranslation";
import EmptyContent from "../empty-content";

// ----------------------------------------------------------------------

TableNoData.propTypes = {
  isNotFound: PropTypes.bool,
  colSpan: PropTypes.number,
};

export default function TableNoData({ isNotFound, colSpan }) {
  const t = useTranslation();
  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={colSpan}>
          <EmptyContent
            title={t("app.no_data")}
            sx={{
              "& span.MuiBox-root": { height: 160 },
            }}
          />
        </TableCell>
      ) : (
        <TableCell
          colSpan={colSpan}
          sx={{ p: 0 }}
        />
      )}
    </TableRow>
  );
}
