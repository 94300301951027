import { AnimatePresence, m } from "framer-motion";
import PropTypes from "prop-types";
// @mui
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
// utils
import { fData } from "@/core/utils/formatNumber";
//
import { varFade } from "@/core/components/animate";
import FileThumbnail, { fileData } from "@/core/components/file-thumbnail";
import Iconify from "@/core/components/iconify";

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
  sx: PropTypes.object,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  thumbnail: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default function MultiFilePreview({
  thumbnail,
  files,
  onRemove,
  sx,
  disabled,
}) {
  if (!files?.length) {
    return null;
  }

  return (
    <Box
      sx={{
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <AnimatePresence initial={false}>
        {files.map((file, index) => {
          const { key, name = "", size = 0 } = fileData(file);

          const isNotFormatFile = typeof file === "string";

          if (thumbnail) {
            return (
              <Stack
                key={key + index}
                component={m.div}
                {...varFade().inUp}
                alignItems="center"
                display="inline-flex"
                justifyContent="center"
                sx={{
                  m: 0.5,
                  width: 80,
                  height: 80,
                  borderRadius: 1.25,
                  overflow: "hidden",
                  position: "relative",
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                  ...sx,
                }}
              >
                <FileThumbnail
                  tooltip
                  imageView
                  file={file}
                  sx={{ position: "absolute" }}
                  imgSx={{ position: "absolute" }}
                />

                {onRemove && (
                  <IconButton
                    size="small"
                    onClick={async () => await onRemove(file, index)}
                    sx={{
                      top: 4,
                      right: 4,
                      p: "1px",
                      position: "absolute",
                      color: (theme) => alpha(theme.palette.common.white, 0.72),
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                      "&:hover": {
                        bgcolor: (theme) =>
                          alpha(theme.palette.grey[900], 0.72),
                      },
                    }}
                  >
                    <Iconify
                      icon="eva:close-fill"
                      width={16}
                    />
                  </IconButton>
                )}
              </Stack>
            );
          }

          return (
            <Stack
              key={key + index}
              component={m.div}
              {...varFade().inUp}
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{
                my: 1,
                px: 1,
                py: 0.75,
                borderRadius: 0.75,
                border: (theme) => `solid 1px ${theme.palette.divider}`,
                ...sx,
              }}
            >
              <FileThumbnail file={file} />

              <Stack
                flexGrow={1}
                sx={{ minWidth: 0 }}
              >
                <Typography
                  variant="subtitle2"
                  noWrap
                >
                  {isNotFormatFile ? file : name}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{ color: "text.secondary" }}
                >
                  {isNotFormatFile ? "" : fData(size)}
                </Typography>
              </Stack>

              {onRemove && (
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => onRemove(file, index)}
                >
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              )}
            </Stack>
          );
        })}
      </AnimatePresence>
    </Box>
  );
}
