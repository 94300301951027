import { AUTOCOMPLETE_PREFIX, DEFAULT_RANGE } from "@/app/config-global";
import { useLocales } from "@/app/locales";
import ProductService from "@/app/services/api/ProductService";
import { IProduct } from "@/app/types";
import useTranslation from "@/core/hooks/useTranslation";
import buildValidators from "@/core/utils/buildValidators";
import AutoForm from "@/presentation/components/AutoForm";
import { Box, IconButton, Stack, Button, Typography } from "@mui/material";
import React from "react";
import { Plus, X } from "react-feather";
import { slice } from "@/redux/slices/autoForm";
import { dispatch } from "@/redux/store";
import _ from "lodash";

export interface IProductQtyValue {
  uuid?: string;
  qty: number;
  id?: number;
}

export interface IProductQtyInputProps {
  label?: string;
  onChange: (values: IProductQtyValue[]) => void;
  value?: IProductQtyValue[];
}

const ProductQtyInput: React.FC<IProductQtyInputProps> = ({
  label,
  onChange,
  value,
}) => {
  // State
  const [mounted, setMounted] = React.useState<boolean>(false);
  const [addCount, setAddCount] = React.useState<number>(value?.length || 1);
  const [state, setState] = React.useState<IProductQtyValue[]>([
    { uuid: undefined, qty: 0, id: 1 },
  ]);

  // Effects
  React.useEffect(() => {
    if (!mounted && (value?.length || 0) > 0) {
      setState(
        value && value.length > 0
          ? value!.map((val, i) => ({
              ..._.cloneDeep(val),
              id: i + addCount + 1,
            }))
          : [{ uuid: undefined, qty: 0, id: 1 }]
      );
      setMounted(true);
      setAddCount(value?.length || 1);
    }
  }, [value?.map((v) => `${v.uuid}`)?.join(""), mounted]);

  //  Hooks
  const t = useTranslation();
  const {
    currentLang: { value: lang },
  } = useLocales();

  // Constants
  const v = buildValidators(t);

  // Functions
  const addNewInput = () => {
    setAddCount((c) => {
      setState((s) => [...s, { uuid: undefined, qty: 0, id: c + 1 }]);
      return c + 1;
    });
  };
  const setAutocompleteTextFieldValue = (label: string, value?: string) => {
    dispatch(slice.actions.setAutoCompleteLabel({ label, value } as never));
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          component={"label"}
          variant="body2"
          sx={{
            fontWeight: 600,
            color: "text.secondary",
          }}
        >
          {t(label)}
        </Typography>
        <Button
          color="success"
          startIcon={<Plus size={16} />}
          onClick={addNewInput}
        >
          {t("app.add")}
        </Button>
      </Stack>
      {state.map((data, index) => (
        <Stack
          key={`${AUTOCOMPLETE_PREFIX}${data.id}`}
          direction={"row"}
          sx={{
            alignItems: "center",
          }}
          gap={2}
        >
          <AutoForm
            hideButton
            noValuesHolder
            initialItem={value || []}
            props={{ sx: { my: 0 } }}
            onValueChange={(values) => {
              setState((s) => {
                const newState = s.map((data, i) =>
                  i === index ? { ...data, ...values } : data
                );
                onChange(newState.map((d) => ({ uuid: d.uuid, qty: d.qty })));
                return newState;
              });
            }}
            onSubmit={() => undefined}
            fields={{
              uuid: {
                type: "autocomplete",
                prefix: `${AUTOCOMPLETE_PREFIX}${index}`,
                gridProps: {
                  xs: 6,
                  md: 8,
                },
                // initialOptions: (row) => row?.product,
                initialOptions: (row) => null,
                getOptions: (initialOption) =>
                  initialOption ? [initialOption] : [],
                loadOptions: async (inputValue) => {
                  const res = await ProductService.paginate({
                    ...DEFAULT_RANGE,
                    keyword: inputValue,
                  });
                  const data = await res.json();
                  return data.data;
                },
                getOptionLabel: (option: IProduct) => option?.name?.[lang],
                getOptionValue: (option: IProduct) => option?.uuid,
                initialValue: (initialItem) => initialItem?.[index]?.uuid,
                label: "label.product",
                placeholder: "placeholder.product",
                title: "title.product",
                props: {},
                validation: v.string,
              },
              qty: {
                gridProps: {
                  xs: 6,
                  md: 4,
                },
                initialValue: (initialItem) => initialItem?.[index]?.qty,
                type: "number",
                label: "label.qty",
                placeholder: "placeholder.qty",
                title: "title.qty",
                props: {},
                validation: v.number,
              },
            }}
          />
          <IconButton
            disabled={state.length < 2}
            sx={{
              flexShrink: 0,
              transform: "translateY(8px)",
            }}
            onClick={() => {
              setState((s) => s.filter((d) => d.id !== data.id));
              setAutocompleteTextFieldValue(
                `uuid-${`product-qty-field-${index}`}`,
                ""
              );
            }}
          >
            <X />
          </IconButton>
        </Stack>
      ))}
    </Box>
  );
};

export default ProductQtyInput;
