import {
  IReduxBaseCrudEffect,
  IResourceService,
} from "@/app/types/BaseReduxTypes";
import { execute } from "@/redux/common/functions";

export default class BaseReduxCrudEffects<R, C, U, S = IResourceService>
  implements IReduxBaseCrudEffect<R, C, U, S>
{
  slice;
  ResourceService;
  constructor(slice: any, ResourceService: S & IResourceService) {
    this.slice = slice;
    this.ResourceService = ResourceService;
  }

  get(
    public_id: string,
    callback?: (data: any) => void,
    onError?: (data: any) => void
  ) {
    return execute({
      slice: this.slice,
      inputProps: public_id,
      serviceFn: this.ResourceService.get,
      actionToDispatch: this.slice.actions.get,
      callback,
      onError,
    });
  }

  paginate(range: R) {
    return execute({
      slice: this.slice,
      inputProps: range,
      serviceFn: this.ResourceService.paginate,
      actionToDispatch: this.slice.actions.paginate,
    });
  }

  create(_data: C, callback: (data: any) => void) {
    return execute({
      slice: this.slice,
      inputProps: _data,
      serviceFn: this.ResourceService.create,
      actionToDispatch: this.slice.actions.create,
      callback,
    });
  }

  update(_data: U, callback: (data: any) => void) {
    return execute({
      slice: this.slice,
      inputProps: _data,
      serviceFn: this.ResourceService.update,
      actionToDispatch: this.slice.actions.update,
      callback,
    });
  }

  delete(public_id: string, callback: (data: any) => void) {
    return execute({
      slice: this.slice,
      inputProps: public_id,
      serviceFn: this.ResourceService.delete,
      actionToDispatch: this.slice.actions.delete,
      callback,
    });
  }

  reset(callback?: (data: any) => void) {
    return async (dispatch: any) => {
      dispatch(this.slice.actions.reset(this.slice.getInitialState()));
    };
  }
}
