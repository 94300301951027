const title = {
  title: "Enter the title",
  status_admin: "Enable/Disable",
  search: "Research...",
  first_name: "Enter first name",
  last_name: "Enter the name",
  email: "Enter the email address",
  phone_number: "Enter phone number",
  role: "Select Role",
  job: "Enter the job",
  bio: "Enter Bio",
  tags: "Enter tags",
  image: "Select an image",
  category: "Category",
  name: "Enter the name",
  name_en: "Enter the name in English",
  name_fr: "Enter the name in English",
  brand: "Enter the brand",
  images: "Select images",
  description: "Enter description",
  description_en: "Enter the description in English",
  sku: "Enter the SKU",
  real_price: "Enter the actual price",
  current_price: "Enter the current price",
  qty: "Enter quantity",
  hair_style: "Select hair style",
  benefits: "Select benefits",
  weight: "Enter the weight",
  is_online: "Make the product visible online or not",
  sub_category: "Select a subcategory",
  video_link: "Enter the video link",
  image_link: "Enter the image link",
  prestation: "Select the service",
  prestations: "Select the services",
  begin_duration: "Choose the beginning of the video",
  content: "Write the content",
  video: "Select a video",
  video_duration: "Enter the duration of the video",
  hairdressing_salon: "Select a room",
  hairdresser_salon_name: "Enter the name of the room",
  country_code: "Enter the country code",
  entreprise_name: "Enter the company name",
  entreprise_phonenumber: "Enter the company's phone number",
  website: "Enter the website",
  hair_types: "Select the types of hair",
  people_type_ids: "Select person types",
  shop_onligne: "Is the store visible online?",
  headdress_at_home: "Is home formwork accepted?",
  postal_code: "Enter postal code",
  province: "Enter the region",
  town: "Enter the city",
  address_one: "Enter the primary address",
  address_two: "Enter the alternate address",
  accept_click_n_collect: "Is click N Collect accepted?",
  manager_hairdressing_email: "Enter the email address of the salon manager",
  password: "Enter the password",
  change_status: "Change status",
  title_fr: "Enter the title in English",
  title_en: "Enter the title in English",
  description_fr: "Enter description in English",
  product_hot_point: "Enter the highlights",
  special_price: "Enter the special price",
  stock: "Enter stock",
  sold: "Sold",
  price: "Enter price",
  hair_type: "Select hair type",
  code: "Enter the code",
  features: "Enter features",
  biocoin_cost: "Enter BioCoin cost",
  stockist_price: "Enter the Stockist price",
  stockist_biocoin_cost: "Enter BioCoin Stockist price",
  recommended_price: "Enter recommended price",
  potential_benefits: "Enter the potential benefit",
  ambassador_biocoin: "Enter the number of BioCoin ambassadors",
  stockist_biocoin_on_ambassador:
    "Enter the number of biocoin stockist ambassador",
  sponsor_biocoin: "Enter the number of sponsor BioCoin",
  stockist_biocoin: "Enter the number of stockist BioCoin",
  stockist_minimum_qty: "Enter the minimum stockist quantity",
  label: "Enter the heading",
  biocoins: "Enter the number of BioCoins",
  min_biocoins_number: "Enter the minimum number of BioCoins",
  biocoin_value: "Enter the value of the BioCoin",
  volume: "Enter Volume",
  quarter: "Enter the neighborhood",
  country: "Enter the country",
  affiliate_code: "Enter affiliate code",
  head_office: "Select head office",
  stockist: "Select stockist",
  products: "Select products",
  supply_date: "Select the supply date",
  product: "Select a product",
};
export default title;
