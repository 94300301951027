import { IToolbarOption } from "@/presentation/components/TablePage";

export const productTableTopOptions = [
  {
    label: "status.all",
    value: "",
  },
  // {
  //   label: "status.online",
  //   value: "true",
  // },
  // {
  //   label: "status.offline",
  //   value: "false",
  // },
];
export const productToolbarOptions = (keyword: {
  value: string;
  onChange: (value: string) => void;
}): IToolbarOption[] => [
  {
    name: "admin_search",
    placeholder: "placeholder.search",
    //@ts-ignore
    type: "input",
    ...keyword,
  },
];
