import { useAuthContext } from "@/app/auth/useAuthContext";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import { useSettingsContext } from "@/core/components/settings";
import useTranslation from "@/core/hooks/useTranslation";
import toSentence from "@/core/utils/toSentence";
import { ICustomBreadcrumbsLink } from "@/presentation/components/TablePage";
import {
  Breakpoint,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Skeleton,
  Stack,
} from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumbs from "../custom-breadcrumbs/CustomBreadcrumbs";
import Iconify from "../iconify/Iconify";

interface IPageProps {
  title: string;
  breadcrumbs?: ICustomBreadcrumbsLink[];
  breadcrumbsTitle?: string;
  defaultActionButton?: {
    title: string;
    onClick: () => void;
    icon?: any;
    type?: "icon" | "button";
  };
  fullWidth?: boolean;
  actions?: (isLoading: boolean) => any[];
  children: any;
  isLoading?: boolean;
  permissions?: string[];
  noPermissionUrl?: string;
}

const Page: React.FC<IPageProps> = ({
  title,
  fullWidth,
  children,
  breadcrumbs,
  breadcrumbsTitle,
  actions,
  defaultActionButton,
  isLoading,
  permissions,
  noPermissionUrl,
}) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { themeStretch } = useSettingsContext();
  const Wrapper = fullWidth ? React.Fragment : Container;
  const DefaultButton: any = Button;
  const btnProps: any =
    defaultActionButton?.type === "icon"
      ? {
          icon:
            typeof defaultActionButton.icon === "string" ? (
              <Iconify
                sx={{ height: 24 }}
                icon={defaultActionButton.icon}
              />
            ) : (
              defaultActionButton.icon
            ),
          onClick: defaultActionButton?.onClick,
          title: t(defaultActionButton?.title ?? ""),
          color: "primary",
          variant: "contained",
        }
      : {
          startIcon:
            typeof defaultActionButton?.icon === "string" ? (
              <Iconify icon={defaultActionButton?.icon} />
            ) : (
              defaultActionButton?.icon
            ),
          onClick: defaultActionButton?.onClick,
          title: t(defaultActionButton?.title ?? ""),
          variant: "contained",
        };
  const wrapperProps = fullWidth
    ? {}
    : { maxWidth: themeStretch ? (false as const) : ("lg" as Breakpoint) };

  // Effects
  React.useEffect(() => {
    if (user && permissions && !permissions.includes(user?.role?.code ?? "")) {
      navigate(noPermissionUrl ?? PATH_DASHBOARD.root);
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <title>{toSentence(t(title))}</title>
      </Helmet>
      {isLoading ? (
        <Stack
          justifyContent={"center"}
          alignItems="center"
          sx={{ p: 4 }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Wrapper {...wrapperProps}>
          {breadcrumbs && (
            <CustomBreadcrumbs
              heading={toSentence(t(breadcrumbsTitle ?? title ?? ""))}
              links={breadcrumbs}
              action={
                <Stack
                  direction={"row"}
                  flexWrap="wrap"
                >
                  {actions && actions(!!isLoading)}
                  {defaultActionButton &&
                    (isLoading ? (
                      <Skeleton
                        variant="rectangular"
                        width={106}
                        height={40}
                        sx={{ borderRadius: "10px" }}
                      />
                    ) : (
                      <DefaultButton {...btnProps}>
                        {defaultActionButton.type === "icon"
                          ? btnProps.icon
                          : t(defaultActionButton.title)}
                      </DefaultButton>
                    ))}
                </Stack>
              }
            />
          )}
          {children}
        </Wrapper>
      )}
    </>
  );
};

export default Page;
