import { ICustomer } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { ICustomerReducer } from "./reducers";

export interface ICustomerState extends IBaseCrudState<ICustomer> {}

export const customerInitialState: ICustomerState = baseCrudInitialState;

export const slice = createSlice<
  ICustomerState,
  ICustomerReducer,
  typeof sliceNames.customer
>({
  name: sliceNames.customer,
  initialState: customerInitialState,
  reducers,
});

export default slice.reducer;
