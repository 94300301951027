import { ThunkAction } from "redux-thunk";

interface IExecuteFn {
  slice: any;
  inputProps: any;
  serviceFn: (data: any) => Promise<any>;
  callback?: (data: any, dispatch: any) => void;
  onError?: (data: any) => void;
  actionToDispatch: (result: any) => void;
  startLoadingAction?: Function;
  stopLoadingAction?: Function;
}

export const execute =
  ({
    slice,
    inputProps,
    serviceFn,
    callback,
    onError,
    actionToDispatch: actionsToDispatch,
    startLoadingAction,
    stopLoadingAction,
  }: IExecuteFn): ThunkAction<any, any, any, any> =>
  async (dispatch) => {
    dispatch(slice.actions.hasError(null));
    dispatch(
      startLoadingAction ? startLoadingAction() : slice.actions.startLoading()
    );
    try {
      const res = await serviceFn(inputProps);
      const result = await res.json();
      if (res.status === 200 || res.status === 201) {
        dispatch(actionsToDispatch(result));
        callback && callback(result, dispatch);
      } else {
        const error = {
          message:
            result?.detail?.[0]?.msg ??
            result?.detail ??
            "error.unexpected_error_occurred",
          status: res.status,
        };
        onError && onError(error);
        dispatch(slice.actions.hasError(error));
      }
    } catch (error) {
      dispatch(
        slice.actions.hasError({ message: "error.unexpected_error_occurred" })
      );
      onError && onError(error);
    } finally {
      dispatch(
        stopLoadingAction ? stopLoadingAction() : slice.actions.stopLoading()
      );
    }
  };

export const resetStateKeys = (state: any, initialState: any) => {
  Object.keys(state).forEach((key) => {
    state[key] = (initialState as any)[key];
  });
};
