import { lazy, Suspense } from "react";
// components
import LoadingScreen from "@/core/components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component: React.FC) => (props: object) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(
  lazy(() => import("@/presentation/pages/auth/LoginPage"))
);
export const RegisterPage = Loadable(
  lazy(() => import("@/presentation/pages/auth/RegisterPage"))
);
export const VerifyCodePage = Loadable(
  lazy(() => import("@/presentation/pages/auth/VerifyCodePage"))
);
export const NewPasswordPage = Loadable(
  lazy(() => import("@/presentation/pages/auth/NewPasswordPage"))
);
export const ResetPasswordPage = Loadable(
  lazy(() => import("@/presentation/pages/auth/ResetPasswordPage"))
);

// MAIN
export const Page500 = Loadable(
  lazy(() => import("@/presentation/pages/Page500"))
);
export const Page403 = Loadable(
  lazy(() => import("@/presentation/pages/Page403"))
);
export const Page404 = Loadable(
  lazy(() => import("@/presentation/pages/Page404"))
);
