import { useSnackbar, VariantType } from "notistack";
import toSentence from "../utils/toSentence";

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const toast = (message: string, variant?: VariantType) => {
    enqueueSnackbar(toSentence(message), {
      autoHideDuration: 5000,
      variant,
    });
  };

  return {
    error: (message: string) => toast(message, "error"),
    success: (message: string) => toast(message, "success"),
    info: (message: string) => toast(message, "info"),
    show: (message: string) => toast(message),
  };
};

export default useToast;
