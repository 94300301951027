const stat = {
  root: "statistique",
  root_plural: "statistiques",
  customer_pro_evolution: "Évolution du nombre d'utilisateurs (clients et pro)",
  salon_by_country: "Salon par pays",
  salon_revenue_fn_time: "Évolution des revenus générées par un salon",
  app_revenue_fn_time: "Évolution des revenus de l'application",
  app_formula_revenue_fn_time: "Évolution des formules payées",
  app_booking_revenue_fn_time: "Évolution des réservations payées",
  app_order_revenue_fn_time: "Évolution des commandes payées",
  app_brut_revenue_fn_time: "Évolution du chiffre d'affaires de l'application",
  product_by_profitability_order: "Produits par ordre de rentabilité",
  salon_by_profitability_order: "Salons par ordre de rentabilité",
  salon_by_localisation: "Salons par localisation",
  salon_fn_town: "Salons en fonction de la ville",
  salon_fn_country: "Salons en fonction du pays",
  customer_by_localisation: "Clients par localisation",
  customer_fn_town: "Clients en fonction de la ville",
  customer_fn_country: "Clients en fonction du pays",
  app_users_fn_time: "Évolution des utilisateurs (client et salon)",
  app_salon_by_profit_fn_country: "Pays par ordre de rentabilité des salons",
  formulas: "Formules",
  no_salon_selected: "Pas de salon sélectionné",
  no_salon_selected_text:
    "Tapez le nom du salon que vous recherchez dans les champs ci-dessus pour sélectionner le salon dont vous voulez voir les statistiques",
  app_customer_by_profit_fn_country:
    "Pays par ordre de rentabilité des clients",
};
export default stat;
