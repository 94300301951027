import useFilters from "@/core/hooks/useFilters";
import useTranslation from "@/core/hooks/useTranslation";
import TablePage, {
  ICustomBreadcrumbsLink,
} from "@/presentation/components/TablePage";
import sliceNames from "@/redux/names";
import gradeEffects from "@/redux/slices/grade/effects";
import { useSelector } from "@/redux/store";
import { useTheme } from "@mui/material";
import gradeColumns from "./data/columns";
import { gradeTableTopOptions, gradeToolbarOptions } from "./data/constants";
import gradeFields from "./data/fields";

interface IGradeProps {
  additionalFilters?: Record<string, string | undefined>;
  breadcrumbs?: null | ICustomBreadcrumbsLink[];
  noPage?: boolean;
}
export default function GradesPage({
  additionalFilters,
  breadcrumbs,
  noPage,
}: IGradeProps) {
  // Store
  const { grade, globalData } = useSelector((state) => state);

  // Hooks
  const t = useTranslation();
  const theme: any = useTheme();
  const {
    getData,
    handleKeyword,
    handlePaymentType,
    handleStatus,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    keyword,
    paymentType,
    status,
    order: _order,
    order_field,
  } = useFilters({
    effects: gradeEffects,
    initialState: {
      page: grade.list.current_page,
      per_page: grade.list.per_page,
      keyword: "",
      paymentType: "",
      status: "",
      order: "",
      order_field: "",
      ...(additionalFilters ?? {}),
    },
  });

  return (
    <TablePage
      model={sliceNames.grade}
      noCheckBoxes
      // ** Breadcrumbs **
      breadcrumbLinks={breadcrumbs}
      // ** Genre **
      // female
      // ** Toolbar **
      tableTopOptions={gradeTableTopOptions}
      toolbarOptionChange={handleStatus}
      currentToolbarOption={status}
      toolbarOptions={gradeToolbarOptions({
        value: keyword,
        onChange: handleKeyword,
      })}
      // ** On Select rows **
      idProperty="uuid"
      // ** Table CRUD **
      fields={gradeFields(t, [])}
      stateData={grade}
      columns={gradeColumns}
      effects={gradeEffects}
      getData={getData}
      // ** Filter options **
      isFiltered={(data) =>
        keyword !== "" || paymentType !== "" || status !== ""
      }
      onReset={() => {
        handleKeyword("");
        handlePaymentType("");
        handleStatus("");
      }}
      // ** Sorting **
      handleFilterOrder={handleOrder}
      handleFilterOrderField={handleOrder_field}
      order={_order}
      orderField={order_field}
      // ** Pagination **
      onChangePage={handlePage}
      onChangeRowsPerPage={handlePer_page}
      noPage={noPage}
    />
  );
}
