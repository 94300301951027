const GLOBAL_DATA_FALLBACK = {
  roles: [
    {
      uuid: "e246b48f-d93d-47ba-9298-14468ad23866",
      title: {
        en: "Administrator",
        fr: "Administrateur",
      },
      code: "administrator",
    },
    {
      uuid: "67fa8b21-6f40-4b7b-9665-49d2ba3f8b67",
      title: {
        en: "Stockist",
        fr: "Stockiste",
      },
      code: "stockist",
    },
    {
      uuid: "ef00a3df-d973-42a4-97b6-c91fdce22501",
      title: {
        en: "Ambassador",
        fr: "Ambassadeur",
      },
      code: "ambassador",
    },
    {
      uuid: "fcf60e0c-5b0b-4d30-a35c-54c59c375ef8",
      title: {
        en: "Guest",
        fr: "Invité",
      },
      code: "guest",
    },
  ],
  categories: [
    {
      uuid: "64383148-615a-4f54-8f4e-aabd5af6147f",
      title: {
        en: "Detox",
        fr: "Détox",
      },
      code: "",
      visible: false,
      date_added: "2023-01-27T16:08:58.366026+00:00",
      date_modified: "2023-01-27T16:08:58.366026+00:00",
    },
    {
      uuid: "941e73db-4231-4925-bce7-b00b75330344",
      title: {
        en: "Dried fruits",
        fr: "Fruits séchés",
      },
      code: "",
      visible: false,
      date_added: "2023-01-27T16:08:58.366026+00:00",
      date_modified: "2023-01-27T16:08:58.366026+00:00",
    },
    {
      uuid: "f3773681-8c57-442f-9e82-b501a8f145bd",
      title: {
        en: "Spices",
        fr: "Epices",
      },
      code: "",
      visible: false,
      date_added: "2023-01-27T16:08:58.366026+00:00",
      date_modified: "2023-02-10T16:18:02.806302+00:00",
    },
    {
      uuid: "09b21b74-510f-4f46-b0ee-7c977bab2b56",
      title: {
        en: "Tea",
        fr: "Thé",
      },
      code: "",
      visible: false,
      date_added: "2023-01-27T16:08:58.366026+00:00",
      date_modified: "2023-03-24T11:30:49.759818+00:00",
    },
    {
      uuid: "efe2c615-561e-4dd1-b341-1c55d915848a",
      title: {
        en: "Pouder",
        fr: "Poudre",
      },
      code: "",
      visible: false,
      date_added: "2023-01-29T00:16:23.844618+00:00",
      date_modified: "2023-03-24T11:30:49.759886+00:00",
    },
  ],
};

export default GLOBAL_DATA_FALLBACK;
