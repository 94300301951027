import React from "react";
import {
  Box,
  Card,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
// redux
import { useDispatch, useSelector } from "@/redux/store";
// routes
import { PATH_DASHBOARD } from "@/app/routes/paths";
// components
import Iconify from "@/core/components/iconify";
import Markdown from "@/core/components/markdown";
import { useSettingsContext } from "@/core/components/settings";
import { SkeletonProductDetails } from "@/core/components/skeleton";
// sections
import Page from "@/core/components/page";
import CartWidget from "@/presentation/sections/@dashboard/e-commerce/CartWidget";
import {
  ProductDetailsCarousel,
  ProductDetailsSummary,
} from "@/presentation/sections/@dashboard/e-commerce/details";
import productEffects from "@/redux/slices/product/effects";
import { useParams } from "react-router-dom";
import { useLocales } from "@/app/locales";
import { IAppLocale } from "@/app/types";

// ----------------------------------------------------------------------

const SUMMARY = [
  {
    title: "100% Original",
    description: "Chocolate bar candy canes ice cream toffee cookie halvah.",
    icon: "ic:round-verified",
  },
  {
    title: "10 Day Replacement",
    description: "Marshmallow biscuit donut dragée fruitcake wafer.",
    icon: "eva:clock-fill",
  },
  {
    title: "Year Warranty",
    description: "Cotton candy gingerbread cake I love sugar sweet.",
    icon: "ic:round-verified-user",
  },
];

// ----------------------------------------------------------------------

export default function ProductDetailsPage() {
  const { themeStretch } = useSettingsContext();

  const { uuid } = useParams();

  const dispatch = useDispatch();
  const { currentLang } = useLocales();
  const lang = currentLang.value as IAppLocale;

  const { currentItem: product, isLoading } = useSelector(
    (state) => state.product
  );

  const [currentTab, setCurrentTab] = React.useState("description");

  React.useEffect(() => {
    if (uuid) {
      dispatch(productEffects.get(uuid));
    }
  }, [dispatch, uuid]);

  const handleAddCart = (newProduct: any) => {};

  const handleGotoStep = (step: any) => {};

  const TABS = [
    // {
    //   value: "description",
    //   label: "description",
    //   component: product ? (
    //     <Markdown
    //       sx={{}}
    //       children={product?.description}
    //     />
    //   ) : null,
    // },
    // {
    //   value: 'reviews',
    //   label: `Reviews (0)`,
    //   component: product ? <ProductDetailsReview product={product} /> : null,
    // },
  ];

  return (
    <Page
      title={"product.details"}
      breadcrumbs={[
        { name: "dashboard.root", href: PATH_DASHBOARD.root },
        { name: "product.root_plural", href: PATH_DASHBOARD.products.root },
        {
          name: product?.name?.[lang] ?? "product.details",
        },
      ]}
    >
      {/*
      <CartWidget totalItems={0} />
    */}

      {product && (
        <>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={7}
            >
              <ProductDetailsCarousel product={product} />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={5}
            >
              <ProductDetailsSummary
                product={product}
                cart={[]}
                onAddCart={handleAddCart}
                onGotoStep={handleGotoStep}
              />
            </Grid>
          </Grid>

          {/*
          <Box
            gap={5}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              md: "repeat(3, 1fr)",
            }}
            sx={{ my: 10 }}
          >
            {SUMMARY.map((item) => (
              <Box
                key={item.title}
                sx={{ textAlign: "center" }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: 64,
                    height: 64,
                    mx: "auto",
                    borderRadius: "50%",
                    color: "primary.main",
                    bgcolor: (theme) =>
                      `${alpha(theme.palette.primary.main, 0.08)}`,
                  }}
                >
                  <Iconify
                    icon={item.icon}
                    width={36}
                  />
                </Stack>

                <Typography
                  variant="h6"
                  sx={{ mb: 1, mt: 3 }}
                >
                  {item.title}
                </Typography>

                <Typography sx={{ color: "text.secondary" }}>
                  {item.description}
                </Typography>
              </Box>
            ))}
          </Box>
          <Card>
            <Tabs
              value={currentTab}
              onChange={(event, newValue) => setCurrentTab(newValue)}
              sx={{ px: 3, bgcolor: "background.neutral" }}
            >
              {TABS.map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                />
              ))}
            </Tabs>

            <Divider />

            {TABS.map(
              (tab) =>
                tab.value === currentTab && (
                  <Box
                    key={tab.value}
                    sx={{
                      ...(currentTab === "description" && {
                        p: 3,
                      }),
                    }}
                  >
                    {tab.component}
                  </Box>
                )
            )}
          </Card>
*/}
        </>
      )}
      {isLoading && <SkeletonProductDetails />}
    </Page>
  );
}
