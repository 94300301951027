import React from "react";

const useIsOnline = () => {
  // State
  const [isOnline, setIsOnline] = React.useState<boolean>(true);

  React.useEffect(() => {
    setIsOnline(navigator.onLine);

    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return isOnline;
};

export default useIsOnline;
