import { DEFAULT_RANGE } from "@/app/config-global";
import GradeService from "@/app/services/api/GradeService";
import { IGlobalDataState, IGrade } from "@/app/types";
import {
  ITablePageOptionListItem,
  IToolbarOption,
} from "@/presentation/components/TablePage";

export const customerTableTopOptions = [
  {
    label: "status.all",
    value: "",
  },
  // {
  //   label: "status.active",
  //   value: "ACTIVED",
  // },
  // {
  //   label: "status.inactive",
  //   value: "UNACTIVED",
  // },
];

export const customerToolbarOptions = (
  requestStates: ITablePageOptionListItem[],
  roles: ITablePageOptionListItem[],
  keyword: {
    value: string;
    onChange: (value: string) => void;
  },
  requestState: {
    value: string;
    onChange: (value: string) => void;
  },
  role: {
    value: string;
    onChange: (value: string) => void;
  },
  grade: {
    value: string;
    onChange: (value: string) => void;
  }
): IToolbarOption[] => [
  {
    name: "role_uuid",
    type: "select",
    options: roles,
    placeholder: "app.role",
    ...role,
  },
  {
    name: "grade_uuid",
    type: "autocomplete",
    placeholder: "label.grade",
    getOptionLabel: (value: IGrade) => value?.label ?? "",
    getOptionValue: (value: IGrade) => value?.uuid ?? "",
    getValue: (options: IGrade[]) =>
      options.find((opt) => opt.uuid === grade.value) ?? null,
    loadOptions: async (keyword: string) => {
      const res = await GradeService.paginate({
        ...DEFAULT_RANGE,
        keyword,
      });
      const data = await res.json();
      return data.data;
    },
    onChange: grade.onChange,
  },
  {
    name: "status",
    type: "select",
    options: requestStates,
    placeholder: "administrator.stockist_request",
    ...requestState,
  },
  {
    name: "admin_search",
    placeholder: "placeholder.search",
    type: "input",
    ...keyword,
  },
];
