import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";

export interface IAutoFormState {
  valuesToHold: any | null;
  loadingFile: boolean;
  autocompleteLabels: Record<string, string>;
}

export const statInitialState: IAutoFormState = {
  valuesToHold: null,
  loadingFile: false,
  autocompleteLabels: {},
};

export const slice = createSlice<
  typeof statInitialState,
  typeof reducers,
  typeof sliceNames.autoForm
>({
  name: sliceNames.autoForm,
  initialState: statInitialState,
  reducers,
});

export default slice.reducer;
