import useIsOnline from "@/core/hooks/useIsOnline";
import useToast from "@/core/hooks/useToast";
import useTranslation from "@/core/hooks/useTranslation";
import { Alert, Box } from "@mui/material";
import React from "react";

const InternetStatus: React.FC<{ children: any }> = ({ children }) => {
  // State
  const [mounted, setMounted] = React.useState<boolean>(false);

  // Hooks
  const isOnline = useIsOnline();
  const t = useTranslation();
  const toast = useToast();

  // Effects
  React.useEffect(() => {
    if (mounted && isOnline) {
      toast.success(t("internet.internet_connection_restored"));
    }
    if (!mounted) {
      setMounted(true);
    }
    // eslint-disable-next-line
  }, [isOnline]);

  return (
    <React.Fragment>
      {!isOnline && (
        <Box
          sx={{
            position: "fixed",
            left: 0,
            bottom: 0,
            p: 2,
            zIndex: 99999,
          }}
        >
          <Alert severity="error">{t("internet.its_seems_no_internet")}</Alert>
        </Box>
      )}
      {children}
    </React.Fragment>
  );
};

export default InternetStatus;
