import PropTypes from "prop-types";
// @mui
import { Card, CardHeader } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
// utils
import { fNumber } from "@/core/utils/formatNumber";
// components
import Chart, { useChart } from "@/core/components/chart";
import { CustomSmallSelect } from "@/core/components/custom-input";
import { countries } from "@/app/assets/data";
import useTranslation from "@/core/hooks/useTranslation";
import EmptyContent from "@/core/components/empty-content";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 400;

const LEGEND_HEIGHT = 72;

const StyledChart = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": {
    height: CHART_HEIGHT,
  },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AnalyticsCurrentVisits.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
  country: PropTypes.string,
  setCountry: PropTypes.func,
};

export default function AnalyticsCurrentVisits({
  title,
  subheader,
  chart,
  country,
  setCountry,
  ...other
}) {
  const theme = useTheme();
  const t = useTranslation();

  const { colors, series, options } = chart;

  const chartSeries = series.map((i) => i.value);

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    colors,
    labels: series.map((i) => i.label),
    stroke: {
      colors: [theme.palette.background.paper],
    },
    legend: {
      floating: true,
      horizontalAlign: "center",
    },
    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value) => fNumber(value),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
      />

      <CustomSmallSelect
        value={country}
        onChange={(event) => setCountry(event.target.value)}
        sx={{ mx: 3, mt: 0.5 }}
      >
        {countries.map((option) => (
          <option
            key={option.code}
            value={option.label}
          >
            {option.label}
          </option>
        ))}
      </CustomSmallSelect>

      {chart.series?.length === 0 ? (
        <EmptyContent title={t("app.no_data")} />
      ) : (
        <StyledChart dir="ltr">
          <Chart
            type="pie"
            series={chartSeries}
            options={chartOptions}
            height={280}
          />
        </StyledChart>
      )}
    </Card>
  );
}
