const label = {
  title: "Titre",
  status_admin: "Activer/Désactiver",
  search: "Recherche",
  first_name: "Prénom",
  last_name: "Nom",
  firstname: "Prénom",
  lastname: "Nom",
  email: "Adresse email",
  phone_number: "Numéro de téléphone",
  phonenumber: "Numéro de téléphone",
  variable_biocoins: "Bio coins variables",
  total_orders: "Total des commandes",
  role: "Rôle",
  job: "Travail",
  bio: "Bio",
  tags: "Tags",
  content: "Contenu",
  cover: "Couverture",
  image: "Image",
  drop_or_select_file: "Déposer ou sélectionner un fichier",
  category: "Catégorie",
  name: "Nom",
  name_en: "Nom en anglais",
  name_fr: "Nom en français",
  brand: "Marque",
  images: "Images",
  description: "Description",
  description_en: "Description en anglais",
  sku: "SKI",
  real_price: "Prix réel",
  current_price: "Prix actuel",
  qty: "Quantité",
  hair_style: "Style de cheveux",
  benefits: "Bénéfices",
  weight: "Poids",
  is_online: "Mettre en ligne",
  sub_category: "Sous-catégorie",
  video_link: "Lien de la vidéo",
  image_link: "Lien de l'image",
  prestation: "Prestation",
  prestations: "Prestations",
  begin_duration: "Début",
  video: "Vidéo",
  video_duration: "Durée de la vidéo",
  hairdressing_salon: "Salon",
  password: "Mot de passe",
  primary_address: "Adresse principale",
  town: "Ville",
  country: "Pays",
  year: "Année",
  hairdresser_salon_name: "Nom du salon",
  country_code: "Code du pays",
  entreprise_name: "Nom de l'entreprise",
  entreprise_phonenumber: "Numéro de téléphone de l'entreprise",
  website: "Site web",
  hair_types: "Types de cheveux",
  people_type_ids: "Types de personne",
  shop_onligne: "Visible en ligne",
  headdress_at_home: "Offre à domicile",
  postal_code: "Code postal",
  province: "Région",
  address_one: "Adresse principale",
  address_two: "Adresse secondaire",
  accept_click_n_collect: "Clic N Collecte",
  manager_hairdressing_email: "Adresse email du gérant du salon",
  parent_profile: "Profil parent",
  profile: "Profil",
  profiles: "Profils",
  orders_an_bookings: "Commandes et réservations",
  profile_count: "Nombre de profils",
  formula: "Formule",
  formulas: "Formules",
  BASIC: "Basique",
  PREMIUM: "Prémium",
  register_date: "Date d'inscription",
  contact_via_chat: "Contacter via le chat",
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
  planning: "Planning",
  start: "Début",
  end: "Fin",
  about: "À propos",
  popularity_index: "Index de popularité",
  hairdresser: "Coiffeur",
  hairdressers: "Coiffeurs",
  orders: "Commandes",
  bookings: "Réservations",
  client_email: "Email du client",
  salon: "Salon",
  date_added: "Date d'ajout",
  child_profile: "Profil enfant",
  people_types: "Types de personne",
  preferences: "Préférences",
  objective: "Objectif",
  last_time_hairdresser: "Dernière fois chez un coiffeur",
  hair_conditions: "Conditions de cheveux",
  current_hair_style: "Style de cheveux actuel",
  hair_type: "Type de cheveux",
  in_stock: "En stock",
  unavailable: "Indisponible",
  position: "Position",
  publish: "Publié",
  volume: "Volume",
  status: "Statut",
  price: "Prix",
  biocoin_cost: "Coût BioCoin",
  stockist_price: "Prix Stockiste",
  stockist_biocoin_cost: "Prix BioCoin Stockiste",
  recommended_price: "Prix recommandé",
  potential_benefits: "Bénéfice potentiel",
  ambassador_biocoin: "Commission ambassadeur",
  stockist_biocoin_on_ambassador: "Commission stockiste sur ambassadeur",
  sponsor_biocoin: "Commission sponsor",
  stockist_biocoin: "Commission stockiste",
  stockist_minimum_qty: "Que Stockiste minimum",
  number_ordered_times: "Nombre de fois commandé",
  details: "Détails",
  categories: "Catégories",
  affiliate_code: "Code affilié",
  quarter: "Quartier",
  ambassador_committee: "Commité ambassadeur",
  ambassadors: "Ambassadeurs",
  order_time: "Commandes",
  code: "Code",
  description_fr: "Description en français",
  features: "Fonctionnalités",
  label: "Intitulé",
  biocoins: "BioCoin",
  last_online: "Dernière fois en ligne",
  date_modified: "Date de modification",
  order_date: "Date de commande",
  stockists: "Stockistes",
  user: "Utilisateur",
  amount: "Montant",
  min_biocoins_number: "Nombre minimum de BioCoin",
  biocoin_value: "Valeur du BioCoin",
  total_users: "Totaux utilisateurs",
  of_today: "D'aujourd'hui",
  last_sale: "Dernière vente",
  paid_plural: "Payés",
  total_biocoins_generated_in_app: "Total de BioCoin générés dans l'app",
  none_female: "Aucune",
  total_biocoins: "Total BioCoin",
  already_paid_plural: "Déjà payés",
  sales_and_orders: "Ventes et commandes",
  YEARLY: "Cette année",
  MONTHLY: "Ce mois",
  WEEKLY: "Cette semaine",
  become_stockist: "Devenir stockiste",
  supplies: "Approvisionnements",
  PENDING: "En cours",
  PAID: "Payé",
  PAID_female: "Payée",
  DELIVERED: "Livré",
  DELIVEREDVIEW: "Perçu",
  DELIVERED_female: "Livrée",
  FINISHED: "Terminé",
  FINISHED_female: "Terminée",
  pu: "P.U",
  via_CASH: "En argent comptant",
  via_MOBILE: "Via mobile",
  via_BIOCOINS: "Via BioCoin",
  grade: "Grade",
  head_office: "Maison mère",
  stockist: "Stockiste",
  products: "Produits",
  supply_date: "Date d'approvisionnement",
  product: "Produit",
};
export default label;
