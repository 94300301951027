import { sentenceCase } from "change-case";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
// @mui
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
// routes
// utils
import { fCurrency, fShortenNumber } from "@/core/utils/formatNumber";
// _mock
import { _socials } from "@/app/_mock/arrays";
// components
import { IncrementerButton } from "@/core/components/custom-input";
import FormProvider, { RHFSelect } from "@/core/components/hook-form";
import Iconify from "@/core/components/iconify";
import Label from "@/core/components/label";
import { IAppLocale, IProduct } from "@/app/types";
import useCurrency from "@/core/hooks/useCurrency";
import useTranslation from "@/core/hooks/useTranslation";
import { getValueOrNA } from "@/core/utils/getFullName";
import { useLocales } from "@/app/locales";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

ProductDetailsSummary.propTypes = {
  cart: PropTypes.array,
  onAddCart: PropTypes.func,
  product: PropTypes.object,
  onGotoStep: PropTypes.func,
};

export default function ProductDetailsSummary({
  cart,
  product: _product,
  onAddCart,
  onGotoStep,
  ...other
}: any) {
  const navigate = useNavigate();
  const product = _product as IProduct;
  const toCurrency = useCurrency();
  const theme = useTheme();
  const t = useTranslation();
  const { currentLang } = useLocales();
  const lang = currentLang?.value;

  return (
    <Stack
      spacing={3}
      sx={{
        p: (theme) => ({
          md: theme.spacing(5, 5, 0, 2),
        }),
      }}
      {...other}
    >
      <Stack spacing={0}>
        <Stack
          spacing={2}
          sx={{ mb: 2 }}
        >
          {/* @ts-ignore */}
          <Label
            variant="soft"
            color={product?.status === "DRAFT" ? "default" : "success"}
            sx={{ textTransform: "uppercase", mr: "auto" }}
          >
            {sentenceCase(t(`status.${product?.status || "DRAFT"}`))}
          </Label>

          <Typography
            variant="h3"
            sx={{ mb: 0, color: "primary.main" }}
          >
            {toCurrency(product?.price)}
          </Typography>
        </Stack>

        <Typography
          variant="h5"
          sx={{ mb: 0 }}
        >
          {getValueOrNA(product?.name?.[lang])}
        </Typography>
        {/*
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Rating
            value={totalRating}
            precision={0.1}
            readOnly
          />

          <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
          >
            ({fShortenNumber(totalReview)}
            reviews)
          </Typography>
        </Stack>
          */}

        <Typography
          variant="overline"
          sx={{ marginTop: 0, color: "#688B83" }}
        >
          {`${product?.weight} ${
            product?.volume ? "/ " + product?.volume : ""
          }`}
        </Typography>
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />
      <Stack
        gap={1}
        sx={{
          "& .MuiTypography-root span": {
            color: "text.primary",
          },
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >
          {product.description?.[lang]}
        </Typography>
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />
      <Stack>
        <Typography
          variant="subtitle2"
          sx={{ height: 36, lineHeight: "36px", color: "text.secondary" }}
        >
          {t("label.categories")}
        </Typography>
        <Stack
          flexWrap={"wrap"}
          direction="row"
          spacing={1}
        >
          {product.categories?.length === 0 && (
            <Typography>{t("products_page.product_categories")}</Typography>
          )}
          {product.categories?.map((category, index) => (
            <Box key={category?.uuid}>
              {/* @ts-ignore */}
              <Label>{category?.title?.[lang]}</Label>
            </Box>
          ))}
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />
      <Stack>
        <Typography
          variant="subtitle2"
          sx={{ height: 36, lineHeight: "36px", color: "text.secondary" }}
        >
          {t("label.details")}
        </Typography>
        <Stack>
          {Object.keys(product).map((key, index) =>
            !["string", "number"].includes(typeof (product as any)[key]) ||
            key === "uuid" ? null : (
              <Stack key={`${key}${index}`}>
                <Typography
                  variant="caption"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: "text.secondary",
                    "& span": {
                      fontWeight: 700,
                    },
                  }}
                >
                  {t(`label.${key}`)} <Divider sx={{ flexGrow: 1 }} />
                  <span>
                    {getValueOrNA(
                      (key.includes("cost") || key.includes("price")
                        ? toCurrency
                        : (v: any) => v)(
                        key === "status"
                          ? t(`status.${(product as any)[key]}`)
                          : (product as any)[key]
                      )
                    )}
                  </span>
                </Typography>
              </Stack>
            )
          )}
        </Stack>
      </Stack>

      <Box sx={{ height: 16 }} />
      {/*

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {_socials.map((social) => (
          <IconButton key={social.name}>
            <Iconify icon={social.icon} />
          </IconButton>
        ))}
      </Stack>
      */}
    </Stack>
  );
}
