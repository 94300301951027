const stat = {
  root: "statistics",
  root_plural: "statistics",
  customer_pro_evolution:
    "Evolution of the number of users (customers and pro)",
  salon_by_country: "Salon by country",
  salon_revenue_fn_time: "Evolution of revenues generated by a trade show",
  app_revenue_fn_time: "Evolution of application revenues",
  app_formula_revenue_fn_time: "Evolution of paid formulas",
  app_booking_revenue_fn_time: "Evolution of paid bookings",
  app_order_revenue_fn_time: "Evolution of paid orders",
  app_brut_revenue_fn_time: "Evolution of the turnover of the application",
  product_by_profitability_order: "Products in order of profitability",
  salon_by_profitability_order: "Exhibitions in order of profitability",
  salon_by_localisation: "Lounges by location",
  salon_fn_town: "Function rooms of the city",
  salon_fn_country: "Salons in function of the country",
  customer_by_localisation: "Customers by location",
  customer_fn_town: "Customers in function of the city",
  customer_fn_country: "Clients in function of the country",
  app_users_fn_time: "Evolution of users (customer and salon)",
  app_salon_by_profit_fn_country:
    "Countries in order of profitability of trade shows",
  formulas: "Formulas",
  no_salon_selected: "No salon selected",
  no_salon_selected_text:
    "Type the name of the room you are looking for in the field above to select the room whose statistics you want to see",
  app_customer_by_profit_fn_country:
    "Countries in order of customer profitability",
};
export default stat;
