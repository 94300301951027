const paymentRequest = {
  root: "Requête de paiement BioCoin",
  root_plural: "Requêtes de paiement",
  get_config_error: "Erreur lors de la récupération de la configuration",
  request_has_been_accepted: "La requête a été acceptée avec succès",
  request_has_been_refused: "La requête a été refusée avec succès",
  really_want_to_accept_this_request:
    "Voulez-vous vraiment accepter cette requête ?",
  really_want_to_refuse_this_request:
    "Voulez-vous vraiment refuser cette requête ?",
};
export default paymentRequest;
