import { IOrder } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IOrderReducer } from "./reducers";

export interface IOrderState extends IBaseCrudState<IOrder> {
  list: IBaseCrudState<IOrder>["list"] & {
    total_orders: number;
    total_pending: number;
    total_booked: number;
    total_in_progress: number;
    total_accepted: number;
    total_cost_orders: number;
    total_cost_pending: number;
    total_cost_booked: number;
    total_cost_in_progress: number;
    total_cost_accepted: number;
  };
}

export const orderInitialState: IOrderState =
  baseCrudInitialState as IOrderState;

export const slice = createSlice<
  IOrderState,
  IOrderReducer,
  typeof sliceNames.order
>({
  name: sliceNames.order,
  initialState: orderInitialState,
  reducers,
});

export default slice.reducer;
