import PropTypes from "prop-types";
// @mui
import { Paper, Typography } from "@mui/material";
import useTranslation from "@/core/hooks/useTranslation";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  query: PropTypes.string,
  sx: PropTypes.object,
};

export default function SearchNotFound({ query, sx, ...other }) {
  const t = useTranslation();
  return query ? (
    <Paper
      sx={{
        textAlign: "center",
        ...sx,
      }}
      {...other}
    >
      <Typography
        variant="h6"
        paragraph
      >
        {t("app.not_found")}
      </Typography>

      <Typography variant="body2">
        {t("app.no_result_found_in_the_list")}
        <br />
        {t("app.check_word_spelling")}
      </Typography>
    </Paper>
  ) : (
    <Typography
      variant="body2"
      sx={sx}
    >
      {t("app.enter_a_keyword")}
    </Typography>
  );
}
