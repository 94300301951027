import ArticleCategorysPage from "@/presentation/pages/dashboard/ArticleCategoriesPage";
import CustomersPage from "@/presentation/pages/dashboard/CustomersPage";
import CustomerDetailsPage from "@/presentation/pages/dashboard/CustomersPage/CustomerDetailsPage";
import GradesPage from "@/presentation/pages/dashboard/GradesPage";
import OrdersPage from "@/presentation/pages/dashboard/OrdersPage";
import PaymentRequestsPage from "@/presentation/pages/dashboard/PaymentRequestsPage";
import ProductsPage from "@/presentation/pages/dashboard/ProductsPage";
import ProductDetailsPage from "@/presentation/pages/dashboard/ProductsPage/ProductDetaisPage";
import ProvisioningsPage from "@/presentation/pages/dashboard/ProvisioningsPage";
import BestAmbassadeurPage from "@/presentation/pages/dashboard/BestAmbassadeur";
import StatsPage from "@/presentation/pages/dashboard/StatsPage";
import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
import { PATH_AFTER_LOGIN } from "../config-global";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
import MainLayout from "../layouts/main";
import SimpleLayout from "../layouts/simple";

// config
//
import {
  LoginPage,
  NewPasswordPage,
  Page403,
  Page404,
  //
  Page500,
  RegisterPage,
  ResetPasswordPage,
  // Dashboard: User
  VerifyCodePage,
} from "./elements";
import { PATH_DASHBOARD } from "./paths";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <LoginPage /> },
        { path: "register-unprotected", element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> },
            { path: "verify", element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate
              to={PATH_AFTER_LOGIN}
              replace
            />
          ),
          index: true,
        },
        {
          path: PATH_DASHBOARD.app,
          element: <StatsPage />,
        },
        {
          path: PATH_DASHBOARD.products.root,
          element: <ProductsPage />,
        },
        {
          path: PATH_DASHBOARD.orders.root,
          element: <OrdersPage />,
        },
        {
          path: PATH_DASHBOARD.grades.root,
          element: <GradesPage />,
        },
        {
          path: PATH_DASHBOARD.paymentRequests.root,
          element: <PaymentRequestsPage />,
        },
        {
          path: PATH_DASHBOARD.products.view(":uuid"),
          element: <ProductDetailsPage />,
        },
        {
          path: PATH_DASHBOARD.customers.root,
          element: <CustomersPage />,
        },
        {
          path: PATH_DASHBOARD.customers.view(":public_id"),
          element: <CustomerDetailsPage />,
        },
        {
          path: PATH_DASHBOARD.articleCatigories.root,
          element: <ArticleCategorysPage />,
        },
        {
          path: PATH_DASHBOARD.provisioning.root,
          element: <ProvisioningsPage />,
        },
        {
          path: PATH_DASHBOARD.ambassadeur.root,
          element: <BestAmbassadeurPage />,
        },
        // { path: 'ecommerce', element: <GeneralEcommercePage /> },
        // { path: 'analytics', element: <GeneralAnalyticsPage /> },
        // { path: 'banking', element: <GeneralBankingPage /> },
        // { path: 'booking', element: <GeneralBookingPage /> },
        // { path: 'file', element: <GeneralFilePage /> },
        // {
        //   path: 'e-commerce',
        //   children: [
        //     { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
        //   ],
        // },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        // { element: <HomePage />, index: true },
        // { path: 'about-us', element: <AboutPage /> },
      ],
      // Demo Components
    },
    {
      element: <SimpleLayout />,
      children: [
        // { path: 'pricing', element: <PricingPage /> },
        // { path: 'payment', element: <PaymentPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        // { path: 'maintenance', element: <MaintenancePage /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
      ],
    },
    {
      path: "*",
      element: (
        <Navigate
          to="/404"
          replace
        />
      ),
    },
  ]);
}
