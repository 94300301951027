const chatBot = {
  root: "questionnaire",
  root_plural: "questionnaires",
  chat_bot: "Chat bot",
  question: "Question",
  question_plural: "Questions",
  response: "Réponse",
  response_plural: "Réponses",
  add_a_question: "Ajouter une question",
  add_a_response: "Ajouter une réponse",
  delete_this_response: "Supprimer cette réponse",
  response_base_on_air_type: "Réponse basée sur le type de cheveux",
};
export default chatBot;
