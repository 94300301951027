import { IAmbassadeur } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IAmbassadeurReducer } from "./reducers";

export interface IAmbassadeurState extends IBaseCrudState<IAmbassadeur> {}

export const ambassadeurInitialState: IAmbassadeurState = baseCrudInitialState;

export const slice = createSlice<
  IAmbassadeurState,
  IAmbassadeurReducer,
  typeof sliceNames.bestAmbassadeur
>({
  name: sliceNames.bestAmbassadeur,
  initialState: ambassadeurInitialState,
  reducers,
});

export default slice.reducer;
