import {
  IGradeCreate,
  IGradeRange,
  IGradeUpdate,
} from "@/app/types/GradeTypes";
import BaseCrudService from "./BaseCrudService";
import { GradeUrls } from "./urls";

class GradeService extends BaseCrudService<
  IGradeCreate,
  IGradeUpdate,
  IGradeRange
> {}

export default new GradeService(GradeUrls);
