import { IPaymentRequest } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { IPaymentRequestState, paymentRequestInitialState } from ".";

export interface IPaymentRequestReducer
  extends IBaseCrudReducer<IPaymentRequestState, IPaymentRequest> {
  resetState: (state: IPaymentRequestState) => void;
}

class PaymentRequestReducer
  extends BaseCrudReducer<IPaymentRequestState, IPaymentRequest>
  implements IPaymentRequestReducer
{
  resetState = (state: IPaymentRequestState) =>
    resetStateKeys(state, paymentRequestInitialState);
}

const reducers: IPaymentRequestReducer = new PaymentRequestReducer();

export default reducers;
