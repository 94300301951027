import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box, Link, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
// auth
import { useAuthContext } from "@/app/auth/useAuthContext";
// routes
import { PATH_DASHBOARD } from "@/app/routes/paths";
// components
import { useLocales } from "@/app/locales";
import { CustomAvatar } from "@/core/components/custom-avatar";
import getFullName from "@/core/utils/getFullName";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();
  const { currentLang } = useLocales();

  return (
    <Link
      component={RouterLink}
      to={PATH_DASHBOARD.user.account}
      underline="none"
      color="inherit"
    >
      <StyledRoot>
        <CustomAvatar
          src={user?.avatar?.thumbnail?.url}
          alt={getFullName(user?.firstname, user?.lastname, user?.email)}
          name={getFullName(user?.firstname, user?.lastname, user?.email)}
        />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography
            variant="subtitle2"
            noWrap
          >
            {getFullName(user?.firstname, user?.lastname, user?.email)}
          </Typography>

          <Typography
            variant="body2"
            noWrap
            sx={{ color: "text.secondary" }}
          >
            {user?.phonenumber?.split(/d{3}/).join(" ") ?? ""}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
