import { DEFAULT_RANGE } from "@/app/config-global";
import { useLocales } from "@/app/locales";
import useFilters from "@/core/hooks/useFilters";
import useTranslation from "@/core/hooks/useTranslation";
import TablePage, {
  ICustomBreadcrumbsLink,
} from "@/presentation/components/TablePage";
import sliceNames from "@/redux/names";
import provisioningEffects from "@/redux/slices/provisioning/effects";
import PaymentRequestService from "@/app/services/api/PaymentRequestService";
import BestAmbassadeur from "@/app/services/api/BestAmbassadeur";
import bestAmbassadeurEffects from "@/redux/slices/bestAmbassadeur/effects";
import paymentRequestEffects from "@/redux/slices/paymentRequest/effects";
import ambassadeurColumns from "./data/columns";
import {
  ambassadeurTableTopOptions,
  ambassadeurToolbarOptions,
} from "./data/constants";
import ambassadeurFields from "./data/fields";

interface IPaymentRequestProps {
  additionalFilters?: Record<string, string | undefined>;
  breadcrumbs?: null | ICustomBreadcrumbsLink[];
  noPage?: boolean;
}
export default function BestAmbassadeurPage({
  additionalFilters,
  breadcrumbs,
  noPage,
}: IPaymentRequestProps) {
  // Hooks
  const t = useTranslation();
  const {
    currentLang: { value: lang },
  } = useLocales();
  const {
    getData,
    handleKeyword,
    handlePaymentType,
    handleStatus,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    keyword,
    paymentType,
    status,
    order: _order,
    order_field,
  } = useFilters({
    effects: bestAmbassadeurEffects,
    initialState: {
      page: 1,
      per_page: DEFAULT_RANGE.per_page,
      keyword: "",
      paymentType: "",
      status: "",
      order: "",
      order_field: "",
      ...(additionalFilters ?? {}),
    },
  });

  return (
    <TablePage
      model={sliceNames.bestAmbassadeur}
      // ** Breadcrumbs **
      breadcrumbLinks={breadcrumbs}
      // ** Genre **
      // female
      // ** Toolbar **
      tableTopOptions={ambassadeurTableTopOptions}
      toolbarOptionChange={handleStatus}
      currentToolbarOption={status}
      toolbarOptions={ambassadeurToolbarOptions({
        value: keyword,
        onChange: handleKeyword,
      })}
      // ** On Select rows **
      idProperty="uuid"
      // ** Table CRUD **
      noCreate
      noUpdate
      noDelete
      noDeleteBulk
      noCheckBoxes
      fields={ambassadeurFields(t, lang)}
      columns={ambassadeurColumns}
      effects={bestAmbassadeurEffects}
      getData={getData}
      // ** Filter options **
      isFiltered={() => keyword !== "" || paymentType !== "" || status !== ""}
      onReset={() => {
        handleKeyword("");
        handlePaymentType("");
        handleStatus("");
      }}
      // ** Sorting **
      handleFilterOrder={handleOrder}
      handleFilterOrderField={handleOrder_field}
      order={_order}
      orderField={order_field}
      // ** Pagination **
      onChangePage={handlePage}
      onChangeRowsPerPage={handlePer_page}
      noPage={noPage}
    />
  );
}
