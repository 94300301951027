import BaseCrudService from "./BaseCrudService";
import BaseService from "./BaseService";
import {
  IAmbassadeurRange,
  IAmbassadeurCreate,
  IAmbassadeurUpdate,
} from "@/app/types/BestAmbassadeurTypes";
import { BestAmbassadeurUrls } from "./urls";

class BestAmbassadeur extends BaseCrudService<
  IAmbassadeurCreate,
  IAmbassadeurUpdate,
  IAmbassadeurRange
> {
  //   configure = (values: any) =>
  //     BaseService.putRequest(PaymentRequestUrls.CONFIGURE, values, true);
  //   getConfig = () => BaseService.getRequest(PaymentRequestUrls.GET_CONFIG, true);
  //   getSellers = () =>
  //     BaseService.getRequest(PaymentRequestUrls.GET_SELLERS, true);
  //   acceptRequest = (requestId: string) =>
  //     BaseService.putRequest(
  //       PaymentRequestUrls.CHANGE_PAYMENT_STATUS(requestId, "PAID"),
  //       {},
  //       true
  //     );
  //   refuseRequest = (requestId: string) =>
  //     BaseService.putRequest(
  //       PaymentRequestUrls.CHANGE_PAYMENT_STATUS(requestId, "REJECTED"),
  //       {},
  //       true
  //     );
}

export default new BestAmbassadeur(BestAmbassadeurUrls);
