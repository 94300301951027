import ArticleCategoryService from "@/app/services/api/ArticleCategoryService";
import {
  IArticleCategoryCreate,
  IArticleCategoryRange,
  IArticleCategoryUpdate,
} from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface IArticleCategoryEffects
  extends IReduxBaseCrudEffect<
    IArticleCategoryRange,
    IArticleCategoryCreate,
    IArticleCategoryUpdate,
    any
  > {}

class ArticleCategoryEffects
  extends BaseReduxCrudEffects<
    IArticleCategoryRange,
    IArticleCategoryCreate,
    IArticleCategoryUpdate
  >
  implements IArticleCategoryEffects {}

const articleCategoryEffects = new ArticleCategoryEffects(
  slice,
  ArticleCategoryService
);

export default articleCategoryEffects;
