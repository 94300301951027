const title = {
  title: "Entrez le titre",
  status_admin: "Activer/Désactiver",
  search: "Recherche...",
  first_name: "Entrez le prénom",
  last_name: "Entrez le nom",
  email: "Entrez l'adresse email",
  phone_number: "Entrez le numéro de téléphone",
  role: "Sélectionnez le rôle",
  job: "Entrez le travail",
  bio: "Entrez la Bio",
  tags: "Entrez les tags",
  image: "Sélectionnez une image",
  category: "Catégorie",
  name: "Entrez le nom",
  name_en: "Entrez le nom en anglais",
  name_fr: "Entrez le nom en français",
  brand: "Entrez la marque",
  images: "Sélectionnez les images",
  description: "Entrez la description",
  description_en: "Entrez la description en anglais",
  sku: "Entrez le SKI",
  real_price: "Entrez le prix réel",
  current_price: "Entrez le prix actuel",
  qty: "Entrez la quantité",
  hair_style: "Sélectionnez le style de cheveux",
  benefits: "Sélectionnez les bénéfices",
  weight: "Entrez le poids",
  is_online: "Rendre le produit visible en ligne ou non",
  sub_category: "Sélectionnez une sous-catégorie",
  video_link: "Entrez le lien de la vidéo",
  image_link: "Entrez le lien de l'image",
  prestation: "Sélectionnez la prestation",
  prestations: "Sélectionnez les prestations",
  begin_duration: "Choisissez le début de la vidéo",
  content: "Écrivez le contenu",
  video: "Sélectionnez une vidéo",
  video_duration: "Entrez la durée de la vidéo",
  hairdressing_salon: "Sélectionnez un salon",
  hairdresser_salon_name: "Entrez le nom du salon",
  country_code: "Entrez le code du pays",
  entreprise_name: "Entrez le nom de l'entreprise",
  entreprise_phonenumber: "Entrez le numéro de téléphone de l'entreprise",
  website: "Entrez le site web",
  hair_types: "Sélectionnez les types de cheveux",
  people_type_ids: "Sélectionnez les types de personne",
  shop_onligne: "La boutique est-elle visible en ligne ?",
  headdress_at_home: "La offre à domicile est-elle acceptée ?",
  postal_code: "Entrez le code postal",
  province: "Entrez la région",
  town: "Entrez la ville",
  address_one: "Entrez l'adresse principale",
  address_two: "Entrez l'adresse secondaire",
  accept_click_n_collect: "Le clic N Collecte est-il accepté ?",
  manager_hairdressing_email: "Entrez l'adresse email du gérant du salon",
  password: "Entrez le mot de passe",
  change_status: "Changer le status",
  title_fr: "Entrez le titre en français",
  title_en: "Entrez le titre en anglais",
  description_fr: "Entrez la description en français",
  product_hot_point: "Entrez les points saillants",
  special_price: "Entrez le prix de solde",
  stock: "Entrez le stock",
  sold: "Vendu",
  price: "Entrez le prix",
  hair_type: "Sélectionnez le type de cheveux",
  code: "Entrez le code",
  features: "Entrez les fonctionnalités",
  biocoin_cost: "Entrez le coût BioCoin",
  stockist_price: "Entrez le prix Stockiste",
  stockist_biocoin_cost: "Entrez le prix BioCoin Stockiste",
  recommended_price: "Entrez le prix recommandé",
  potential_benefits: "Entrez le bénéfice potentiel",
  ambassador_biocoin: "Entrez le nombre de BioCoin ambassadeur",
  stockist_biocoin_on_ambassador:
    "Entrez le nombre de BioCoin stockiste ambassadeur",
  sponsor_biocoin: "Entrez le nombre de BioCoin sponsor",
  stockist_biocoin: "Entrez le nombre de BioCoin stockiste",
  stockist_minimum_qty: "Entrez la quantité stockistes minimum",
  label: "Entrez l'intitulé",
  biocoins: "Entrez le nombre de BioCoin",
  min_biocoins_number: "Entez le nombre minimum de BioCoin",
  biocoin_value: "Entrez la valeur du BioCoin",
  volume: "Entrez le volume",
  quarter: "Entrez le quartier",
  country: "Entrez le pays",
  affiliate_code: "Entrez le code affilié",
  head_office: "Sélectionnez la maison mère",
  stockist: "Sélectionnez le stockiste",
  products: "Sélectionnez les produits",
  supply_date: "Sélectionnez la date d'approvisionnement",
  product: "Sélectionnez un produit",
};
export default title;
