import { IOrder } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { IOrderState, orderInitialState } from ".";

export interface IOrderReducer extends IBaseCrudReducer<IOrderState, IOrder> {
  resetState: (state: IOrderState) => void;
}

class OrderReducer
  extends BaseCrudReducer<IOrderState, IOrder>
  implements IOrderReducer
{
  resetState = (state: IOrderState) => resetStateKeys(state, orderInitialState);
}

const reducers: IOrderReducer = new OrderReducer();

export default reducers;
