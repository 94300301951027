import useTranslation from "@/core/hooks/useTranslation";
import buildValidators from "@/core/utils/buildValidators";
import { IAutoFormFields } from "@/presentation/components/AutoForm";

const paymentRequestFields = (
  t: any
): IAutoFormFields<{
  min_biocoins_paid?: number;
  value_biocoins?: number;
}> => {
  const v = buildValidators(t);
  return {
    min_biocoins_paid: {
      initialValue: (row) => row?.min_biocoins_paid,
      label: "label.min_biocoins_number",
      placeholder: "placeholder.min_biocoins_number",
      title: "title.min_biocoins_number",
      validation: v.number,
    },
    value_biocoins: {
      initialValue: (row) => row?.value_biocoins,
      label: "label.biocoin_value",
      placeholder: "placeholder.biocoin_value",
      title: "title.biocoin_value",
      validation: v.number,
    },
  };
};

export default paymentRequestFields;
