import { useState } from "react";
import * as Yup from "yup";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// @mui
import { LoadingButton } from "@mui/lab";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
// routes
// auth
import { useAuthContext } from "@/app/auth/useAuthContext";
// components
import FormProvider, { RHFTextField } from "@/core/components/hook-form";
import Iconify from "@/core/components/iconify";
import useTranslation from "@/core/hooks/useTranslation";
import buildValidators from "@/core/utils/buildValidators";

// ----------------------------------------------------------------------

export default function AuthLoginForm() {
  const { login } = useAuthContext();
  const t = useTranslation();
  const v = buildValidators(t);

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    login: v.phone_number,
    password: v.password,
  });

  const defaultValues = {
    login: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.login, data.password);
    } catch (error) {
      console.error(error);
      reset();
      setError("afterSubmit", {
        ...error,
        message: t(
          error.message === "error.invalid_email_or_password"
            ? error.message
            : "error.unexpected_error_occurred"
        ),
      });
    }
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <RHFTextField
          name="login"
          label={t("label.phone_number")}
          InputProps={{ type: "tel" }}
        />
        <RHFTextField
          name="password"
          label={t("label.password")}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        alignItems="flex-end"
        sx={{ my: 2 }}
      >
        {/* <Link
          component={RouterLink}
          to={PATH_AUTH.resetPassword}
          variant="body2"
          color="inherit"
          underline="always"
        >
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitSuccessful || isSubmitting}
        sx={{
          bgcolor: "text.primary",
          color: (theme) =>
            theme.palette.mode === "light" ? "common.white" : "grey.800",
          "&:hover": {
            bgcolor: "text.primary",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
          },
        }}
      >
        {t("login.root")}
      </LoadingButton>
    </FormProvider>
  );
}
