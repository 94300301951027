import { IGrade } from "@/app/types";
import buildValidators from "@/core/utils/buildValidators";
import { IAutoFormFields } from "@/presentation/components/AutoForm";

const gradeFields = (t: Function, roles: any[]): IAutoFormFields<IGrade> => {
  const v = buildValidators(t);
  return {
    label: {
      initialValue: (row) => row?.label,
      label: "label.label",
      placeholder: "placeholder.label",
      title: "title.label",
      props: {},
      validation: v.string,
    },
    biocoins: {
      initialValue: (row) => row?.biocoins,
      label: "label.biocoins",
      placeholder: "placeholder.biocoins",
      title: "title.biocoins",
      props: {},
      validation: v.string,
    },
  };
};

export default gradeFields;
