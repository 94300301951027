import { DEFAULT_DEBOUNCE_DURATION, DEFAULT_RANGE } from "@/app/config-global";
import { IRequestRange } from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import { store } from "@/redux/store";
import React from "react";
import _ from "lodash";

interface IUseFilters<S> {
  effects?: IReduxBaseCrudEffect<IRequestRange & S, any, any, any>;
  getData?: (range?: S) => void;
  initialState: S;
}

export interface IUserFiltersEvent {
  name: string;
  value: any;
}

export default function useFilters<S extends object = {}>({
  effects,
  initialState,
  getData: customGetData,
}: IUseFilters<S>) {
  // Hooks
  const dispatch = store.dispatch;

  // State
  const [filterForm, setFilterForm] = React.useState<S>(initialState);
  const [shouldResetPage, setShouldResetPage] = React.useState<boolean>(false);
  const filterFunctions = React.useMemo<{
    [key: string]: (value: any) => void;
  }>(() => {
    const filterFns: { [key: string]: (value: any) => void } = {};
    Object.keys(initialState).map((key) => {
      filterFns[`handle${key.charAt(0).toUpperCase()}${key.substring(1)}`] = (
        value: any
      ) => onFilterChange({ name: key, value });
    });
    return filterFns;
  }, []);

  // Function
  const onFilterChange = (e: IUserFiltersEvent) => {
    setShouldResetPage(e.name === "keyword");
    setFilterForm((state: any) => ({ ...state, [e.name]: e.value }));
  };
  const getData = React.useMemo(
    () =>
      _.debounce(
        customGetData
          ? () => customGetData(filterForm)
          : () => {
              effects &&
                dispatch(
                  effects.paginate({
                    ...DEFAULT_RANGE,
                    ...filterForm,
                    ...(shouldResetPage ? { page: DEFAULT_RANGE.page } : {}),
                  })
                );
            },
        DEFAULT_DEBOUNCE_DURATION
      ),
    [filterForm, shouldResetPage]
  );

  // Effects
  React.useEffect(() => {
    getData.cancel();
    getData();
    return () => {
      getData.cancel();
      effects && effects.reset && dispatch(effects.reset());
    };
  }, [filterForm, shouldResetPage]);

  return {
    getData,
    ...filterForm,
    ...filterFunctions,
  };
}
