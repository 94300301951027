// @mui
import {
  Box,
  Card,
  Divider,
  Grid,
  LinearProgress,
  selectClasses,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Page from "@/core/components/page";
import React from "react";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import useTranslation from "@/core/hooks/useTranslation";
import { AppAreaInstalled } from "@/presentation/sections/@dashboard/general/app";
import { useTheme } from "@mui/material/styles";
import toSentence from "@/core/utils/toSentence";
import Iconify from "@/core/components/iconify";
import StatService from "@/app/services/api/StatService";
import useToast from "@/core/hooks/useToast";
import {
  IBioCoinStats,
  ISalesStats,
  IOrderUserStats,
} from "@/app/types/StatTypes";
import Scrollbar from "@/core/components/scrollbar";
import InvoiceAnalytic from "@/presentation/sections/@dashboard/invoice/InvoiceAnalytic";
import { AnalyticsWebsiteVisits } from "@/presentation/sections/@dashboard/general/analytics";
import useCurrency from "@/core/hooks/useCurrency";
import { fPercent } from "@/core/utils/formatNumber";
import CustomersPage from "../CustomersPage";
import getFullName from "@/core/utils/getFullName";
import { CustomSmallSelect } from "@/core/components/custom-input";
import { BindOptions } from "dgram";
import { BIOCOIN_CURRENCY, DEFAULT_MIN_YEAR } from "@/app/config-global";
// assets

// ----------------------------------------------------------------------

export default function IndexPage() {
  // Hooks
  const t = useTranslation();
  const toast = useToast();
  const toCurrency = useCurrency();

  // State
  const [loadingSales, setLoadingSales] = React.useState<boolean>(false);
  const [loadingBiocoins, setLoadingBiocoins] = React.useState<boolean>(false);
  const [loadingUsersOrders, setLoadingUsersOrders] =
    React.useState<boolean>(false);
  const [salesStats, setSalesStats] = React.useState<ISalesStats | null>(null);
  const [biocoinsStats, setBiocoinsStats] =
    React.useState<IBioCoinStats | null>(null);
  const [usersOrdersStats, setUsersOrdersStats] =
    React.useState<IOrderUserStats | null>(null);

  // Selects state
  const [salesSelect, setSalesSelect] = React.useState<string>("YEARLY");
  const [biocoinsSelect, setBiocoinsSelect] = React.useState<string>("YEARLY");

  const theme = useTheme();
  const currentYear = new Date().getFullYear();

  const getResourceData = (
    resource: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    callback: (_data: any) => void
  ) => {
    setLoading(true);
    StatService.get(resource)
      .then(async (res) => await res.json())
      .then((data) => callback(data))
      .catch(() => {
        toast.error(t("error.unexpected_error_occurred"));
      })
      .finally(() => setLoading(false));
  };

  // Utils
  const resolveLineChartLabel = (selectedView: string) =>
    selectedView === "YEARLY"
      ? [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]
      : selectedView === "MONTHLY"
      ? Array(new Date(currentYear, new Date().getMonth() + 1, 0).getDate())
          .fill("")
          .map((_, index) => `${index + 1 < 10 ? "0" : ""}${index + 1}`)
      : ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const resolveBarChartLabel = (selectedView: string) =>
    selectedView === "YEARLY"
      ? Array(12)
          .fill("")
          .map(
            (_, index) =>
              `${index + 1 < 10 ? "0" : ""}${index + 1}/01/${currentYear}`
          )
      : selectedView === "MONTHLY"
      ? Array(new Date(currentYear, new Date().getMonth() + 1, 0).getDate())
          .fill("")
          .map((_, index) => {
            return `${new Date().getMonth() + 1}/${index + 1}/${currentYear}`;
          })
      : Array(7)
          .fill("")
          .map((_, index) => {
            const curr = new Date();
            const last_day = new Date(
              curr.getFullYear(),
              curr.getMonth() + 1,
              0
            ).getDate();
            const firstday = new Date(
              curr.setDate(curr.getDate() - curr.getDay())
            ).getDate();
            const builded_day = firstday + index + 1;

            const isNextMonth = builded_day > last_day;
            const isNextYear = curr.getMonth() === 11 && isNextMonth;
            return `${curr.getMonth() + (isNextMonth ? 2 : 1)}/${
              isNextMonth ? builded_day - last_day : builded_day
            }/${currentYear + (isNextYear ? 1 : 0)}`;
          });

  // Get data
  React.useEffect(() => {
    getResourceData(`sales?filter=${salesSelect}`, setLoadingSales, (data) =>
      setSalesStats(data)
    );
  }, [salesSelect]);

  React.useEffect(() => {
    getResourceData(
      `biocoins?filter=${biocoinsSelect}`,
      setLoadingBiocoins,
      (data) => setBiocoinsStats(data)
    );
  }, [biocoinsSelect]);
  React.useEffect(() => {
    getResourceData(
      `user-order?start_date=${DEFAULT_MIN_YEAR}-01-01&end_date=${new Date().getFullYear()}-12-31`,
      setLoadingUsersOrders,
      (data) => setUsersOrdersStats(data)
    );
  }, []);
  return (
    <Page
      title="dashboard.root"
      breadcrumbsTitle="dashboard.root"
      breadcrumbs={[
        {
          name: "dashboard.root",
          href: PATH_DASHBOARD.root,
        },
        {
          name: "app.details",
        },
      ]}
    >
      {" "}
      {loadingUsersOrders ? (
        <CardSkeleton />
      ) : (
        <Card>
          {" "}
          <Scrollbar>
            {" "}
            <Stack
              direction="row"
              justifyContent="center"
              gap={3}
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: "dashed" }}
                />
              }
              sx={{ p: 4, flexWrap: "wrap" }}
            >
              {[
                {
                  global: usersOrdersStats?.users?.number_of_users ?? 0,
                  difference: usersOrdersStats?.users?.difference ?? 0,
                },
                {
                  global: usersOrdersStats?.orders?.number_of_orders ?? 0,
                  difference: usersOrdersStats?.orders?.difference ?? 0,
                },
              ].map((item, index) => (
                <Stack
                  key={`dashboard-card-stat-item-${index}`}
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    gap={1}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "60px",
                        height: "60px",
                        borderRadius: "999px",
                        backgroundColor:
                          index === 1 ? "warning.lighter" : "success.lighter",
                        color: index === 1 ? "warning.main" : "success.main",
                      }}
                    >
                      <Iconify
                        sx={{ height: 32 }}
                        icon={
                          index === 1
                            ? "fa6-solid:file-invoice-dollar"
                            : "mdi:users"
                        }
                      />
                    </Box>
                    <Stack justifyContent="space-between">
                      <Typography sx={{ color: "text.secondary" }}>
                        {t(
                          index === 1
                            ? "label.total_orders"
                            : "label.total_users"
                        )}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: 24,
                          display: "flex",
                          gap: 0.5,
                          alignItems: "center",
                        }}
                      >
                        <span>{item.global}</span>
                        <Typography
                          variant="caption"
                          sx={{
                            color:
                              item.difference > 0
                                ? "success.main"
                                : item.difference < 0
                                ? "error.main"
                                : "info.main",
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                          >
                            <Iconify
                              icon={
                                item.difference > 0
                                  ? "ic:round-arrow-drop-up"
                                  : item.difference < 0
                                  ? "ic:round-arrow-drop-down"
                                  : "octicon:dash-16"
                              }
                            />
                            <span>
                              {item.difference === 0 ? "" : item.difference}
                            </span>
                          </Stack>
                        </Typography>
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ pb: 2 }}
            ></Stack>
          </Scrollbar>
        </Card>
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 1, mt: 5 }}
        gap={3}
      >
        {!loadingSales ? (
          <>
            <Typography variant="subtitle1">
              {toSentence(t("app.sales"))}
            </Typography>

            <ViewTypeSelect
              value={salesSelect}
              onChange={(value) => setSalesSelect(value)}
            />
          </>
        ) : (
          <>
            <CardSkeleton
              width={300}
              height={8}
            />
            <CardSkeleton
              width={70}
              height={8}
            />
          </>
        )}
      </Stack>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={4}
        >
          {loadingSales ? (
            <CardSkeleton height={368} />
          ) : (
            <Card
              sx={{
                p: 3,
                pb: 5,
                backgroundColor: "primary.darker",
                color: "common.white",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "center" }}
              >
                {t("label.of_today")}
              </Typography>
              <Stack
                direction="row"
                gap={"10%"}
                sx={{ mt: 3 }}
                justifyContent="center"
              >
                {[
                  { value: salesStats?.today?.amount ?? 0, unit: "FCFA" },
                  {
                    value: salesStats?.today?.biocoins ?? 0,
                    unit: BIOCOIN_CURRENCY,
                  },
                ].map((item, index) => (
                  <Box
                    sx={{
                      border: "3px solid",
                      borderColor: "primary.main",
                      flexShrink: 0,
                      width: "40%",
                      aspectRatio: "1/1",
                      borderRadius: 999,
                      backgroundColor: "primary.lighter",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      color: "text.primary",
                    }}
                  >
                    <Typography variant="h5">
                      {toCurrency(item.value, { noUnit: true })}
                    </Typography>
                    <Typography variant="caption">{item.unit}</Typography>
                  </Box>
                ))}
              </Stack>
              <Stack sx={{ mt: 4 }}>
                <Typography
                  variant="caption"
                  sx={{ mb: 1 }}
                >
                  {t("label.last_sale")}
                </Typography>
                {salesStats?.last ? (
                  <>
                    <Typography variant="subtitle1">
                      {toCurrency(salesStats?.last?.amount ?? 0)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: "text.secondary", my: 0.5 }}
                    >
                      {getFullName(
                        salesStats?.last?.user?.firstname,
                        salesStats?.last?.user?.lastname
                      )}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    variant="caption"
                    sx={{ color: "text.secondary" }}
                  >
                    {t("label.none_female")}
                  </Typography>
                )}
              </Stack>
            </Card>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
        >
          {loadingSales ? (
            <CardSkeleton height={368} />
          ) : (
            <AnalyticsWebsiteVisits
              title={""}
              subheader=""
              height={260}
              chart={{
                colors: [
                  theme.palette.primary.main,
                  theme.palette.secondary.main,
                ],
                labels: resolveBarChartLabel(salesSelect),
                series: [
                  {
                    name: t("label.stockists"),
                    type: "column",
                    fill: "solid",
                    data: salesStats?.sales?.stockists ?? [],
                  },
                  {
                    name: t("label.ambassadors"),
                    type: "column",
                    fill: "gradient",
                    data: salesStats?.sales?.head_offices ?? [],
                  },
                  // {
                  //   name: "Team C",
                  //   type: "line",
                  //   fill: "solid",
                  //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                  // },
                ],
              }}
            />
          )}
        </Grid>
      </Grid>
      <Stack>
        <Box sx={{ mt: 2 }}>
          {loadingSales ? (
            <CardSkeleton height={459} />
          ) : (
            <AppAreaInstalled
              title={toSentence(t("order.root_plural"))}
              currentYear={currentYear}
              subheader=""
              years={[currentYear]}
              colors={[
                theme.palette.secondary.main,
                theme.palette.secondary.main,
              ]}
              chart={{
                categories: resolveLineChartLabel(salesSelect),
                series: [
                  {
                    year: currentYear,
                    data: [
                      {
                        name: t("label.stockists"),
                        data: salesStats?.orders?.stockists ?? [],
                      },
                      {
                        name: t("label.ambassadors"),
                        data: salesStats?.orders?.ambassadors ?? [],
                      },
                    ],
                  },
                ],
              }}
            />
          )}
        </Box>
      </Stack>
      <Stack sx={{ mt: 5 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Typography variant="subtitle1">
            {toSentence(t("label.biocoins"))}
          </Typography>

          <ViewTypeSelect
            value={biocoinsSelect}
            onChange={(value) => setBiocoinsSelect(value)}
          />
        </Stack>
        {loadingBiocoins ? (
          <CardSkeleton height={118} />
        ) : (
          <Card sx={{ p: 3, mb: 2 }}>
            <Typography
              gutterBottom
              variant="subtitle1"
            >
              {t("label.total_biocoins")}:{" "}
              <span>
                {toCurrency(biocoinsStats?.total ?? 0, { noUnit: true })}{" "}
                {BIOCOIN_CURRENCY}
              </span>
            </Typography>
            <ProgressItem
              progress={{
                label: t("label.already_paid_plural"),
                value: Number(
                  ((biocoinsStats?.paid ?? 0) * 100) /
                    (biocoinsStats?.total ?? 1)
                ),
                details: (
                  <Box sx={{ mt: 2 }}>
                    {["PAID", "UNPAID"].map((stat) => (
                      <Stack
                        key={`bcs-stat-${stat}`}
                        direction={"row"}
                        gap={2}
                        alignItems="center"
                      >
                        <Box
                          sx={{
                            backgroundColor:
                              stat === "PAID" ? "success.main" : "gray",
                            borderRadius: 12,
                            flexShrink: 0,
                            width: 12,
                            height: 12,
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 600, color: "text.secondary" }}
                        >
                          {t(`status.${stat}_plural`)}:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 600 }}
                        >
                          {toCurrency(
                            biocoinsStats?.[
                              stat.toLowerCase() as keyof typeof biocoinsStats
                            ] ?? 0,
                            {
                              noUnit: true,
                            }
                          )}{" "}
                          {BIOCOIN_CURRENCY}
                        </Typography>
                      </Stack>
                    ))}
                  </Box>
                ),
              }}
            />
          </Card>
        )}
        {/*
        <AnalyticsWebsiteVisits
          title={""}
          subheader=""
          height={150}
          chart={{
            colors: [theme.palette.primary.main, theme.palette.secondary.main],
            labels: [
              `01/01/${currentYear}`,
              `02/01/${currentYear}`,
              `03/01/${currentYear}`,
              `04/01/${currentYear}`,
              `05/01/${currentYear}`,
              `06/01/${currentYear}`,
              `07/01/${currentYear}`,
              `08/01/${currentYear}`,
              `09/01/${currentYear}`,
              `10/01/${currentYear}`,
              `11/01/${currentYear}`,
              `12/01/${currentYear}`,
            ],
            series: [
              {
                name: "",
                type: "column",
                fill: "solid",
                data: [12, 23, 54, 45, 67, 67, 68, 89, 25, 78, 190],
              },
            ],
          }}
        />
          */}
      </Stack>
      <Stack sx={{ mt: 3 }}>
        <Typography
          sx={{ mb: 1 }}
          variant="subtitle1"
        >
          Utilisateurs
        </Typography>
        <CustomersPage noPage />
      </Stack>
    </Page>
  );
}

// ---------------------------------------------------
export function CardSkeleton({
  height,
  width,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <Skeleton
      sx={{ p: 0, m: 0, borderRadius: "10px" }}
      variant="rectangular"
      height={height ?? 140}
      width={width ?? "100%"}
    />
  );
}

export function ProgressItem({
  progress,
}: {
  progress: { label: string; value: number; details?: any };
}) {
  const { label, value, details } = progress;

  return (
    <Box>
      <Box sx={{ mb: 1.5, display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2">{label}&nbsp;-&nbsp;</Typography>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >
          {fPercent(value)}
        </Typography>
      </Box>

      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          "& .MuiLinearProgress-bar": { bgcolor: "primary.main" },
          "&.MuiLinearProgress-determinate": { bgcolor: "divider" },
        }}
      />
      {details}
    </Box>
  );
}

export function ViewTypeSelect({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  const options = ["YEARLY", "MONTHLY", "WEEKLY"];
  const t = useTranslation();
  return (
    <CustomSmallSelect
      value={value}
      onChange={(e: any) => onChange(e.target.value)}
    >
      {options.map((option) => (
        <option
          key={`custom-select-${option}`}
          value={option}
        >
          {t(`label.${option}`)}
        </option>
      ))}
    </CustomSmallSelect>
  );
}
