import BaseService from "./BaseService";
import { GlobalUrls } from "./urls";

class GlobalService {
  static load_global_data = () => {
    if ((window as any).mocked) {
      // return get_global_data();
    } else {
      return BaseService.getRequest(GlobalUrls.GET_GLOBAL_DATA, false);
    }
  };
}

export default GlobalService;
