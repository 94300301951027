import * as Yup from "yup";

const buildValidators = (t: Function) => ({
  string: Yup.string().required(t("error.this_field_is_required")),
  boolean: Yup.boolean().required(t("error.this_field_is_required")),
  password: Yup.string().required(t("error.this_field_is_required")),
  string_nr: Yup.string(),
  password_nr: Yup.string(),
  email: Yup.string()
    .email(t("error.enter_a_valid_email_address"))
    .required(t("error.this_field_is_required")),
  number: Yup.number()
    .typeError(t("error.this_field_should_be_a_number"))
    .required(t("error.this_field_is_required")),

  number_nr: Yup.number().typeError(t("error.this_field_should_be_a_number")),
  arrayOfString: Yup.array()
    .of(Yup.string())
    .min(1, "error.add_at_least_one_item"),
  arrayOfString_nr: Yup.array()
    .of(Yup.string())
    .min(0, "error.add_at_least_one_item"),
  arrayOfObject: Yup.array()
    .of(Yup.object())
    .min(1, "error.add_at_least_one_item"),
  arrayOfObject_nr: Yup.array().of(Yup.object()),
});

export default buildValidators;
