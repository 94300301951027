import {
  ACCEPTED_FILE_TYPES,
  ACCEPTED_IMAGES,
  ACCEPTED_VIDEOS,
} from "@/app/config-global";
import FileService from "@/app/services/api/FileService";
import AppAsyncAutocomplete from "@/core/components/app-async-autocomplete";
import Editor from "@/core/components/editor";
import ScrollTo from "@/core/components/scroll-to";
import SearchNotFound from "@/core/components/search-not-found/SearchNotFound";
import { Upload } from "@/core/components/upload";
import useToast from "@/core/hooks/useToast";
import useTranslation from "@/core/hooks/useTranslation";
import toBase64 from "@/core/utils/toBase64";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  FormControlLabel,
  Grid,
  GridTypeMap,
  MenuItem,
  Stack,
  Switch,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import React from "react";
import * as Yup from "yup";
import AppTextField from "../AppTextField";
import PlanningSelect from "../PlanningSelect";
import {
  formatValuesWithDoubleUnderscores,
  getAutoCompleteLabelStateName,
  getInitialItemWithDoubleUnderscore,
} from "./utils";
import { slice } from "@/redux/slices/autoForm";
import { dispatch, useDispatch, useSelector } from "@/redux/store";
import ProductQtyInput from "@/core/components/product-qty-input";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface IFieldData<T> {
  gridProps?: Omit<
    OverridableComponent<GridTypeMap<{}, "div">>,
    "container" | "item"
  >;
  initialValue: string | ((initialItem: T) => any | undefined);
  initialImage?: string | ((initialItem: T) => any | undefined);
  label: string;
  placeholder: string;
  title?: string;
  props?: Record<string, any>;
  validation: any | ((initialItem: T) => any);
  type?: "autocomplete" | "product-qty" | string;
  getOptionLabel?: (value: any) => any;
  getOptionValue?: (value: any) => any;
  checkFn?: (value: any) => any;
  ignoreItem?: (option: any, selectedItems: any[]) => boolean;
  getOptions?: (initialValue?: any) => any[];
  single?: boolean;
  useDateTimeFormat?: boolean;
  collection?: string;
  multiple?: boolean;
  freeSolo?: boolean;
  prefix?: string;
  loadOptions?: (value: any) => Promise<any>;
  renderOptions?: (props: any, opt: any, { inputValue, selected }: any) => any;
  initialOptions?: (row: T) => any;
  hide?: boolean;
  hidden?: (value: any) => boolean;
  onChangeCallback?: (formik: any, brutData?: any) => void;
  initialArray?: { label: string; value: string }[];
  switchValues?: [string | number | boolean, string | number | boolean];
  accept?: string[];
  ignoreFileStoring?: (initialItem: any) => boolean;
  apiName?: string;
  onFileDelete?: (row: T, fileUuid: string) => Promise<void>;
  fileType?:
    | typeof ACCEPTED_FILE_TYPES.image
    | typeof ACCEPTED_FILE_TYPES.video;
}

export type IField<T = any> = ((formik?: any) => IFieldData<T>) | IFieldData<T>;

export type IAutoFormFields<T = any> = Record<string, IField<T>>;

interface Props {
  loading?: boolean;
  formikRef?: any;
  ref?: any;
  hideButton?: boolean;
  initialItem?: any;
  noValuesHolder?: boolean;
  fields: IAutoFormFields;
  onSubmit: (values: any) => void;
  onValueChange?: (values: any) => void;
  setLoading?: (loading: boolean) => void;
  valueOnResetDepends?: any | null;
  props?: {
    cols?: number;
    gapX?: number;
    gapY?: number;
    sx?: SxProps;
  };
  useDateTimeFormat?: boolean;
}

const AutoForm: React.FC<Props> = React.forwardRef(
  (
    {
      formikRef,
      loading,
      initialItem,
      fields,
      props,
      onSubmit,
      hideButton,
      setLoading,
      noValuesHolder,
      valueOnResetDepends,
      onValueChange,
      useDateTimeFormat,
    },
    ref
  ) => {
    // Hooks
    const toast = useToast();
    const dispatch = useDispatch();

    // State
    const [isRemovingFile, setIsRemovingFile] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    // Store
    const autoFormState = useSelector((state) => state.autoForm);
    const valuesHolder = autoFormState.valuesToHold;

    // Functions
    const setUploadingFile = (state: boolean) => {
      dispatch(slice.actions.setLoadingFile(state as never));
    };
    const setValuesToHold = (values: any) =>
      dispatch(slice.actions.setValuesToHold(values));
    const initFormik = () => {
      const initialValues: { [Property in keyof typeof fields]: any } = {};
      const validations: any = {};

      for (let x = 0; x < Object.keys(fields).length; x++) {
        const fieldName: string = Object.keys(fields)[x];
        const field =
          fields[fieldName] instanceof Function
            ? // @ts-ignore
              fields[fieldName]()
            : fields[fieldName];
        if (!(field.hidden && field.hidden(initialItem))) {
          if (typeof field?.validation !== typeof undefined) {
            initialValues[fieldName] =
              field.initialValue instanceof Function
                ? (field.initialValue as Function)(initialItem)
                : !fieldName.includes("__")
                ? field.initialValue
                : initialItem
                ? getInitialItemWithDoubleUnderscore(initialItem, fieldName)
                : "";
            if (field.initialOptions) {
              field.initialOptions =
                field.initialOptions instanceof Function
                  ? (field.initialOptions as Function)(initialItem)
                  : !fieldName.includes("__")
                  ? field.initialOptions
                  : initialItem
                  ? getInitialItemWithDoubleUnderscore(initialItem, fieldName)
                  : "";
            }
            validations[fieldName] =
              field.validation instanceof Function
                ? field.validation(initialItem)
                : field.validation;
          }
        }
      }

      return { initialValues, validationSchema: Yup.object(validations) };
    };

    // Hooks
    const t = useTranslation();
    const formik = useFormik({
      ...initFormik(),
      enableReinitialize: true,
      onSubmit: async (values: any) => {
        try {
          {
            setLoading && setLoading(true);
            setSubmitting(true);

            // Store files
            for (let x = 0; x < Object.keys(fields).length; x++) {
              const fieldName = Object.keys(fields)[x];
              const field: any =
                fields[fieldName] instanceof Function
                  ? (fields[fieldName] as Function)()
                  : fields[fieldName];
              if (["uploader", "uploader-multiple"].includes(field.type)) {
                if (
                  !values[fieldName] ||
                  (typeof values[fieldName] === "string" &&
                    values[fieldName].length <= 0) ||
                  (Array.isArray(values[fieldName]) &&
                    values[fieldName].length <= 0) ||
                  (field?.ignoreFileStoring &&
                    field?.ignoreFileStoring(initialItem))
                ) {
                  continue;
                }
                const b64List =
                  field.type === "uploader"
                    ? values[fieldName]
                      ? [values[fieldName]]
                      : []
                    : values[fieldName];
                const uuidList: string[] = [];
                try {
                  for (let y = 0; y < b64List.length; y++) {
                    const b64 = b64List[y];
                    if (b64?.includes(";base64,")) {
                      try {
                        setUploadingFile(true);
                        const res: any = await FileService.storeFile({
                          file_name:
                            String(new Date().getTime()) +
                            "." +
                            String(
                              b64.split(";")[0].split("/")[1]
                            ).toLocaleLowerCase(),
                          base_64: b64,
                        });
                        const data = await res.json();
                        uuidList.push(data.uuid);
                      } catch (error) {
                        toast.error(
                          t("error.file_upload_error", {
                            field: field.label ?? "",
                          })
                        );
                      } finally {
                        setUploadingFile(false);
                      }
                    } else {
                      uuidList.push(b64);
                    }
                  }
                } finally {
                  setUploadingFile(false);
                }
                values[
                  field.type === "uploader" &&
                  !uuidList[0]?.includes(";base64,")
                    ? field.apiName ?? fieldName
                    : fieldName
                ] = field.type === "uploader" ? uuidList[0] ?? "" : uuidList;
              } else if (field.type === "date") {
                if (field.useDateTimeFormat || useDateTimeFormat) {
                  values[fieldName] = `${
                    String(values[fieldName]) ?? ""
                  }T14:57:30.309Z`;
                }
              }
            }
            onSubmit(formatValuesWithDoubleUnderscores(values));
          }
        } catch (_e) {
          return;
        } finally {
          setLoading && setLoading(false);
          setSubmitting(false);
        }
      },
    });

    const values = formik.values;

    // Functions
    const resolveFilePreview = (formik: any, field: any, fieldName: any) => {
      return (
        (!String((values as any)[fieldName]).includes(";base64,")
          ? field.initialImage instanceof Function
            ? field.initialImage(initialItem)
            : field.initialImage
          : (values as any)[fieldName]) ?? {}
      );
    };

    const removeFileFromFormik = (fieldName: string, index: number) => {
      formik.setFieldValue(
        fieldName,
        ((values as any)[fieldName] ?? []).filter(
          (_: any, i: number) => index !== i
        )
      );
    };
    const removeFileFormDb = async (
      fieldName: string,
      field: any,
      index: number
    ) => {
      try {
        setIsRemovingFile(true);
        const res = await field.onFileDelete(
          initialItem,
          values?.[fieldName]?.[index]
        );
        const data = await res.json();
        if ([200, 201].includes(res.status)) {
          removeFileFromFormik(fieldName, index);
        } else {
          toast.error(
            typeof data?.detail === "string"
              ? data.detail
              : "error.unexpected_error_occurred"
          );
        }
      } catch {
        toast.error("error.unexpected_error_occurred");
      } finally {
        setIsRemovingFile(false);
      }
    };

    // Effects
    React.useEffect(() => {
      if (formikRef) {
        formikRef.current = formik;
      }
    }, [formik]);

    React.useEffect(() => {
      if (valuesHolder && !noValuesHolder) {
        formik.setValues({ ...valuesHolder });
      }
    }, [submitting]);

    React.useEffect(
      () => () => {
        if (valueOnResetDepends) {
          // @ts-ignore
          dispatch(slice.actions.setValuesToHold(null));
        }
      },
      [valueOnResetDepends]
    );

    React.useEffect(() => {
      if (onValueChange && !submitting) {
        onValueChange(values);
      }
    }, [values]);

    return (
      <form
        onSubmit={(e) => {
          if (!noValuesHolder) {
            setValuesToHold({ ...formik.values });
          }

          formik.handleSubmit(e);
        }}
        onReset={formik.handleReset}
      >
        <Stack>
          <Grid
            container
            spacing={2}
            columns={props?.cols}
            sx={{ my: 2, ...(props?.sx || {}) }}
          >
            {Object.keys(fields)
              .filter((k) => !!fields[k])
              .map((fieldName, index) => {
                const field =
                  fields[fieldName] instanceof Function
                    ? // @ts-ignore
                      fields[fieldName](formik)
                    : fields[fieldName];
                const isInvalid =
                  !!(formik.errors as any)[fieldName] &&
                  !!(formik.touched as any)[fieldName];
                return (
                  <FieldComponent
                    index={index}
                    field={field}
                    fieldName={fieldName}
                    isInvalid={isInvalid}
                    initialItem={initialItem}
                    formik={formik}
                    props={props}
                    values={values}
                    removeFileFormDb={removeFileFormDb}
                    removeFileFromFormik={removeFileFromFormik}
                    isRemovingFile={isRemovingFile}
                    resolveFilePreview={resolveFilePreview}
                  />
                );
              })}
          </Grid>
          {hideButton ? (
            <button
              type="submit"
              //@ts-ignore
              ref={ref}
              style={{ display: "none" }}
            />
          ) : (
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={loading || formik.isSubmitting}
            >
              {t("app.save")}
            </LoadingButton>
          )}
        </Stack>
      </form>
    );
  }
);

function FieldComponent({
  index,
  field,
  fieldName,
  isInvalid,
  initialItem,
  formik,
  props,
  values,
  removeFileFormDb,
  removeFileFromFormik,
  isRemovingFile,
  resolveFilePreview,
}: any) {
  const t = useTranslation();
  const _name = getAutoCompleteLabelStateName(field, fieldName);
  const autocompleteTextFieldValue = useSelector(
    (state) => state.autoForm.autocompleteLabels[_name] ?? ""
  );

  const setAutocompleteTextFieldValue = (value?: string) => {
    dispatch(
      slice.actions.setAutoCompleteLabel({ label: _name, value } as never)
    );
  };

  return (
    field &&
    !field.hide &&
    !(field.hidden && field.hidden(initialItem)) && (
      <Grid
        {...{ xs: props?.columns || 12 }}
        {...(field.gridProps || {})}
        item
        key={`auto-form-field-${fieldName}`}
      >
        {field.type === "autocomplete" ? (
          <AppAsyncAutocomplete
            key={`auto-form-autocomplete-${fieldName}`}
            multiple={field.multiple}
            freeSolo={field.freeSolo}
            sx={{ minWidth: 240 }}
            popupIcon={null}
            noOptionsText={
              <SearchNotFound
                query={t(field.label)}
                sx={{}}
              />
            }
            onChange={(event, value) => {
              if (!field.multiple) {
                field.initialOptions = Array.isArray(value) ? value : [value];
              }
              formik.setFieldValue(
                fieldName,
                Array.isArray(value)
                  ? value.map((val) => field.getOptionValue(val))
                  : field.getOptionValue(value)
              );

              // Setting label
              if (!field.multiple) {
                setAutocompleteTextFieldValue(
                  Array.isArray(value)
                    ? value.map((val) => field.getOptionValue(val))
                    : field.getOptionLabel(value)
                );
              }
            }}
            getValue={
              field.multiple
                ? (options: any[]) =>
                    (options || [])?.filter((opt) =>
                      values[fieldName]?.includes(field.getOptionValue(opt))
                    )
                : undefined
            }
            value={
              field.multiple
                ? !field.freeSolo
                  ? (field.getOptions(field.initialOptions) ?? []).filter(
                      (option: any) =>
                        Array.isArray(values[fieldName])
                          ? values[fieldName].includes(
                              field.getOptionValue(option)
                            )
                          : false
                    )
                  : values[fieldName]
                : (Array.isArray(field.initialOptions)
                    ? field.initialOptions
                    : field.getOptions(field.initialOptions) ?? []
                  ).find(
                    (option: any) =>
                      field.getOptionValue(option || {}) === values[fieldName]
                  )
            }
            onInputChange={(_event: any, value: any) =>
              field.getOptions
                ? field.getOptions(field.initialOptions).filter((opt: any) =>
                    t(field.getOptionLabel(opt || {}))
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  )
                : []
            }
            options={
              field.getOptions ? field.getOptions(field.initialOptions) : []
            }
            loadOptions={field.loadOptions}
            getOptionLabel={field.getOptionLabel}
            renderInput={(params) => (
              <TextField
                label={t(field.label)}
                placeholder={t(field.placeholder)}
                title={t(field.title)}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  onChange: (e: any) => {
                    params.inputProps.onChange!(e);
                    setAutocompleteTextFieldValue(e.target.value);
                  },
                  value: autocompleteTextFieldValue,
                }}
              />
            )}
            renderOption={
              field.renderOptions ??
              ((props: any, opt: any, { inputValue, selected }: any) => (
                // @ts-ignore
                <Box
                  {...(props || {})}
                  sx={{ p: 1 }}
                >
                  {field.getOptionLabel(opt)}
                </Box>
              ))
            }
            renderTags={(selectedRecipients: any, getTagProps: any) =>
              selectedRecipients.map((recipient: any, index: number) => (
                <Chip
                  {...getTagProps({ index })}
                  key={field.getOptionValue(recipient)}
                  size="small"
                  label={field.getOptionLabel(recipient)}
                  avatar={<></>}
                />
              ))
            }
          />
        ) : field.type === "uploader-multiple" ? (
          <Box>
            <Upload
              accept={
                field.accept ??
                (field.fileType
                  ? field.fileType === ACCEPTED_FILE_TYPES.video
                    ? ACCEPTED_VIDEOS
                    : field.fileType === ACCEPTED_FILE_TYPES.image
                    ? ACCEPTED_IMAGES
                    : undefined
                  : undefined)
              }
              thumbnail
              files={((values as any)[fieldName] ?? []).map((val: string) =>
                val.includes(";base64,")
                  ? val
                  : (field.initialOptions ?? []).find(
                      (opt: any) => opt.uuid === val
                    )?.thumbnail?.url ??
                    (field.initialOptions ?? []).find(
                      (opt: any) => opt.uuid === val
                    )?.url
              )}
              onRemoveAll={() => formik.setFieldValue(fieldName, [])}
              onRemove={async (_: any, index: number) => {
                if (
                  field.onFileDelete &&
                  !values?.[fieldName]?.[index]?.includes(";base64,")
                ) {
                  await removeFileFormDb(fieldName, field, index);
                } else {
                  removeFileFromFormik(fieldName, index);
                }
              }}
              multiple
              label={t(field.label)}
              error={!!isInvalid}
              onDrop={(data: any[]) => {
                data.map((data) => {
                  toBase64(data).then((base64) => {
                    formik.setFieldValue(fieldName, [
                      ...(values[fieldName] ?? []),
                      base64,
                    ]);
                  });
                });
              }}
              {...(field.props || {})}
              disabled={isRemovingFile}
            />
          </Box>
        ) : field.type === "uploader" ? (
          <Upload
            accept={
              field.accept ??
              (field.fileType
                ? field.fileType === ACCEPTED_FILE_TYPES.video
                  ? ACCEPTED_VIDEOS
                  : field.fileType === ACCEPTED_FILE_TYPES.image
                  ? ACCEPTED_IMAGES
                  : undefined
                : undefined)
            }
            // file={field.initialFile}
            file={resolveFilePreview(formik, field, fieldName)}
            label={t(field.label)}
            error={!!isInvalid}
            onDrop={(data: any) => {
              toBase64(data[0]).then((base64) => {
                formik.setFieldValue(fieldName, base64);
                field.onChangeCallback && field.onChangeCallback(formik, data);
              });
            }}
            {...(field.props || {})}
          />
        ) : field.type === "editor" ? (
          <Editor
            key={fieldName}
            name={fieldName}
            id={fieldName}
            value={(values as any)[fieldName]}
            onChange={(value: string) => {
              formik.setFieldValue(fieldName, value);
            }}
            // onBlur={formik.handleBlur}
            placeholder={t(field.placeholder)}
            label={t(field.label)}
            error={!!isInvalid}
            {...(field.props || {})}
          />
        ) : field.type === "switch" ? (
          <FormControlLabel
            label={t(field.label)}
            labelPlacement="end"
            control={
              <Switch
                key={fieldName}
                name={fieldName}
                placeholder={t(field.placeholder)}
                title={t(field.title)}
                checked={field.checkFn && field.checkFn(values[fieldName])}
                onChange={(e) =>
                  formik.setFieldValue(
                    fieldName,
                    (values as any)[fieldName] === field.switchValues[0]
                      ? field.switchValues[1]
                      : field.switchValues[0]
                  )
                }
              />
            }
          />
        ) : field.type === "select" ? (
          <TextField
            key={fieldName}
            name={fieldName}
            select
            fullWidth
            label={t(field.label)}
            placeholder={t(field.placeholder)}
            title={t(field.title)}
            value={(values as any)[fieldName]}
            defaultValue={(values as any)[fieldName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    maxHeight: 260,
                  },
                },
              },
            }}
            sx={{
              textTransform: "capitalize",
            }}
          >
            {field.getOptions &&
              field.getOptions(field.initialOption)?.map((option: any) => (
                <MenuItem
                  key={fieldName + field.getOptionValue(option)}
                  selected={
                    (values as any)[fieldName]
                      ? String((values as any)[fieldName]) ===
                        String(field.getOptionValue(option))
                      : false
                  }
                  value={field.getOptionValue(option)}
                  sx={{
                    mx: 1,
                    borderRadius: 0.75,
                    typography: "body2",
                    textTransform: "capitalize",
                  }}
                >
                  {field.getOptionLabel(option)}
                </MenuItem>
              ))}
          </TextField>
        ) : field.type === "planning" ? (
          <PlanningSelect
            label={t(field.label)}
            // placeholder={t(field.placeholder)}
            // title={t(field.title)}
            onChange={(value) => {
              formik.setFieldValue(fieldName, value);
            }}
            // onBlur={formik.handleBlur}
            // invalid={isInvalid}
            value={(values as any)[fieldName]}
            // type={field.type}
            {...(field.props || {})}
          />
        ) : field.type === "product-qty" ? (
          <ProductQtyInput
            label={t(field.label)}
            // placeholder={t(field.placeholder)}
            // title={t(field.title)}
            onChange={(value) => {
              formik.setFieldValue(fieldName, value);
            }}
            // onBlur={formik.handleBlur}
            // invalid={isInvalid}
            // type={field.type}
            {...(field.props || {})}
            value={(values as any)[fieldName]}
          />
        ) : field.type === "textarea" ? (
          <TextField
            fullWidth
            multiline
            rows={4}
            maxRows={Infinity}
            name={fieldName}
            id={fieldName}
            label={t(field.label)}
            placeholder={t(field.placeholder)}
            title={t(field.title)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={isInvalid}
            value={(values as any)[fieldName]}
            type={field.type}
            {...(field.props || {})}
          />
        ) : (
          <AppTextField
            fullWidth
            name={fieldName}
            id={fieldName}
            label={t(field.label)}
            placeholder={t(field.placeholder)}
            title={t(field.title)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={isInvalid}
            value={(values as any)[fieldName]}
            type={field.type}
            {...(field.props || {})}
          />
        )}
        {isInvalid && (
          <ScrollTo>
            <Typography
              variant="caption"
              color="error"
            >
              {t((formik.errors as any)[fieldName] as string)}
            </Typography>
          </ScrollTo>
        )}
      </Grid>
    )
  );
}

export default AutoForm;
