import { IAutoFormState } from ".";

type IAutoFormReducer = (state: IAutoFormState, action: any) => void;

const reducers: Record<string, IAutoFormReducer> = {
  setValuesToHold: (state, action) => {
    state.valuesToHold = action.payload;
  },
  resetLabels: (state) => {
    Object.keys(state.autocompleteLabels).forEach((key) => {
      (state.autocompleteLabels as any)[key] = "";
    });
  },
  setAutoCompleteLabel: (
    state,
    action: {
      type: string;
      payload: {
        label: string;
        value: string;
      };
    }
  ) => {
    state.autocompleteLabels[action.payload.label] = action.payload.value;
  },
  setLoadingFile: (state, action) => {
    state.loadingFile = action.payload;
  },
};

export default reducers;
