import { ICommission } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { commissionInitialState, ICommissionState } from ".";

export interface ICommissionReducer
  extends IBaseCrudReducer<ICommissionState, ICommission> {
  resetState: (state: ICommissionState) => void;
}

class CommissionReducer
  extends BaseCrudReducer<ICommissionState, ICommission>
  implements ICommissionReducer
{
  resetState = (state: ICommissionState) =>
    resetStateKeys(state, commissionInitialState);
}

const reducers: ICommissionReducer = new CommissionReducer();

export default reducers;
