const chatBot = {
  root: "quiz",
  root_plural: "quiz",
  chat_bot: "Chat bot",
  question: "Question",
  question_plural: "Questions",
  response: "Answer",
  response_plural: "Answers",
  add_a_question: "Add a question",
  add_a_response: "Add an answer",
  delete_this_response: "Delete this answer",
  response_base_on_air_type: "Response based on hair type",
};
export default chatBot;
