import CommissionService from "@/app/services/api/CommissionService";
import {
  ICommissionCreate,
  ICommissionRange,
  ICommissionUpdate,
} from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface ICommissionEffects
  extends IReduxBaseCrudEffect<
    ICommissionRange,
    ICommissionCreate,
    ICommissionUpdate,
    any
  > {}

class CommissionEffects
  extends BaseReduxCrudEffects<
    ICommissionRange,
    ICommissionCreate,
    ICommissionUpdate
  >
  implements ICommissionEffects {}

const commissionEffects = new CommissionEffects(slice, CommissionService);

export default commissionEffects;
