const placeholder = {
  title: "Titre",
  status_admin: "Activer/Désactiver",
  search: "Recherche...",
  first_name: "Prénom",
  last_name: "Nom",
  email: "Adresse email",
  phone_number: "Numéro de téléphone",
  role: "Rôle",
  job: "Travail",
  bio: "Bio",
  image: "Image",
  write_something: "Écrivez quelque chose d'exceptionnel...",
  meta_title: "Meta titre",
  meta_description: "Meta description",
  meta_keywords: "Meta mots-clés",
  drop_or_select_file:
    "Déposer un fichier ou cliquez ici sélectionner un fichier",
  category: "Catégorie",
  name: "Nom",
  name_en: "Nom en anglais",
  name_fr: "Nom en français",
  brand: "Marque",
  images: "Images",
  description: "Description",
  description_en: "Description en anglais",
  sku: "SKI",
  real_price: "Prix réel",
  current_price: "Prix actuel",
  qty: "Quantité",
  hair_style: "Style de cheveux",
  benefits: "Bénéfices",
  weight: "Poids",
  is_online: "Mettre en ligne",
  sub_category: "Sous-catégorie",
  video_link: "Lien de la vidéo",
  image_link: "Lien de l'image",
  prestation: "Prestation",
  prestations: "Prestations",
  begin_duration: "Début",
  content: "Écrivez quelque chose de merveilleux...",
  video: "Vidéo",
  video_duration: "Durée de la vidéo",
  tags: "Tags",
  hairdressing_salon: "Salon",
  hairdresser_salon_name: "Nom du salon",
  country_code: "Code du pays",
  entreprise_name: "Nom de l'entreprise",
  entreprise_phonenumber: "Numéro de téléphone de l'entreprise",
  website: "Site web",
  hair_types: "Types de cheveux",
  people_type_ids: "Types de personne",
  shop_onligne: "Visible en ligne",
  headdress_at_home: "Offre à domicile",
  postal_code: "Code postal",
  province: "Région",
  town: "Ville",
  country: "Pays",
  address_one: "Adresse principale",
  address_two: "Adresse secondaire",
  accept_click_n_collect: "Clic N Collecte",
  manager_hairdressing_email: "Adresse email du gérant du salon",
  accepted_files: "Fichiers acceptés",
  browse: "Importer",
  password: "Mot de passe",
  publish: "Publié",
  title_fr: "Titre en français",
  title_en: "Titre en anglais",
  description_fr: "Description en français",
  product_hot_point: "Points saillants",
  special_price: "Prix de solde",
  stock: "Stock",
  sold: "Vendu",
  hair_type: "Type de cheveux",
  price: "Prix",
  code: "Code",
  features: "Fonctionnalités",
  categories: "Catégories",
  biocoin_cost: "Coût BioCoin",
  stockist_price: "Prix Stockiste",
  stockist_biocoin_cost: "Prix BioCoin Stockiste",
  recommended_price: "Prix recommandé",
  potential_benefits: "Bénéfice potentiel",
  ambassador_biocoin: "BioCoin ambassadeur",
  stockist_biocoin_on_ambassador: "BioCoin stockiste ambassadeur",
  sponsor_biocoin: "BioCoin sponsor",
  stockist_biocoin: "BioCoin Stockiste",
  stockist_minimum_qty: "Que Stockiste minimum",
  label: "Intitulé",
  biocoins: "BioCoin",
  min_biocoins_number: "Nombre minimum de BioCoin",
  biocoin_value: "Valeur du BioCoin",
  affiliate_code: "Code affilié",
  quarter: "Quartier",
  volume: "Volume",
  head_office: "Maison mère",
  stockist: "Stockiste",
  products: "Produits",
  supply_date: "Date d'approvisionnement",
  product: "Produit",
};
export default placeholder;
