// @mui
import useTranslation from "@/core/hooks/useTranslation";
import { Box, FormControlLabel, Switch, TablePagination } from "@mui/material";

// ----------------------------------------------------------------------

export interface ITablePaginationCustomProps {
  count?: number;
  page?: number;
  dense?: boolean;
  onChangeDense?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  rowsPerPage?: number;
  onPageChange: Function;
  onRowsPerPageChange: Function;
  rowsPerPageOptions?: number[];
  sx?: any;
}

const TablePaginationCustom: React.FC<ITablePaginationCustomProps> = ({
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25],
  sx,
  ...other
}) => {
  const t = useTranslation();
  return (
    <Box sx={{ position: "relative", ...sx }}>
      {/* @ts-ignore */}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        {...other}
      />

      {onChangeDense && (
        <FormControlLabel
          label={t("pagination.dense")}
          control={
            <Switch
              checked={dense}
              onChange={onChangeDense}
            />
          }
          sx={{
            pl: 2,
            py: 1.5,
            top: 0,
            position: {
              sm: "absolute",
            },
          }}
        />
      )}
    </Box>
  );
};

export default TablePaginationCustom;
