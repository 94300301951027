import { IAmbassadeur } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { ambassadeurInitialState, IAmbassadeurState } from ".";

export interface IAmbassadeurReducer
  extends IBaseCrudReducer<IAmbassadeurState, IAmbassadeur> {
  resetState: (state: IAmbassadeurState) => void;
}

class AmbassadeurReducer
  extends BaseCrudReducer<IAmbassadeurState, IAmbassadeur>
  implements IAmbassadeurReducer
{
  resetState = (state: IAmbassadeurState) =>
    resetStateKeys(state, ambassadeurInitialState);
}

const reducers: IAmbassadeurReducer = new AmbassadeurReducer();

export default reducers;
