const product = {
  root: "product",
  root_plural: "products",
  name: "Name",
  name_en: "Name in English",
  salon: "Living room",
  category: "Category",
  createdAt: "Created on",
  price: "Price",
  details: "Product Details",
  sub_category: "Subcategory",
  really_want_to_duplicate_this_product:
    "Really want to duplicate this product ({productName}) ?",
  duplicate: "Duplicate",
  product_duplicated_successfully: "Product duplicated successfully",
};
export default product;
