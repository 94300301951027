import { ICommission } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { ICommissionReducer } from "./reducers";

export interface ICommissionState extends IBaseCrudState<ICommission> {}

export const commissionInitialState: ICommissionState = baseCrudInitialState;

export const slice = createSlice<
  ICommissionState,
  ICommissionReducer,
  typeof sliceNames.commission
>({
  name: sliceNames.commission,
  initialState: commissionInitialState,
  reducers,
});

export default slice.reducer;
