import { instanceOf } from "prop-types";
import BaseService from "./BaseService";
import { buildCrudUrls } from "./utils";

export default class BaseCrudService<ICreate, IUpdate, IRange, IUrls = {}> {
  urls: ReturnType<typeof buildCrudUrls> & IUrls;
  constructor(urls: any) {
    this.urls = urls;
  }
  get = (public_id: string) => {
    return BaseService.getRequest(this.urls.GET(public_id), true);
  };
  paginate = (query: IRange) => {
    return BaseService.getRequest(this.urls.PAGINATE(query), true);
  };
  create = (data: ICreate) => {
    return BaseService.postRequest(this.urls.CREATE, data as object, true);
  };
  update = (data: IUpdate) => {
    return BaseService.putRequest(this.urls.UPDATE, data as object, true);
  };
  delete = (uuid: string | string[]) => {
    return BaseService.deleteRequest(
      this.urls.DELETE,
      typeof uuid === "string" ? [uuid] : uuid,
      true
    );
  };
}
