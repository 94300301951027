const models = {
  create_success: "{model} créé avec succès",
  update_success: "{model} modifié avec succès",
  delete_success: "{model} supprimé avec succès",
  delete_bulk_success: "{model} supprimés avec succès",
  create_success_female: "{model} créée avec succès",
  update_success_female: "{model} modifiée avec succès",
  delete_success_female: "{model} supprimée avec succès",
  delete_bulk_success_female: "{model} supprimées avec succès",
};
export default models;
