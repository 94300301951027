// @mui
import { Skeleton, Stack, TableCell, TableRow } from "@mui/material";

// ----------------------------------------------------------------------

export default function TableSkeleton({
  cols,
  id,
  hasCheckboxes = false,
  ...other
}) {
  return (
    <TableRow {...other}>
      {hasCheckboxes && (
        <TableCell>
          <Skeleton
            variant="rectangular"
            width={25}
            height={25}
            sx={{ borderRadius: 1, flexShrink: 0 }}
          />
        </TableCell>
      )}
      <TableCell>
        <Stack
          direction={"row"}
          spacing={3}
          alignItems={"center"}
          minWidth={"300px"}
        >
          <Skeleton
            variant="rectangular"
            width={40}
            height={40}
            sx={{ borderRadius: 1, flexShrink: 0 }}
          />
          <Skeleton
            variant="text"
            width={`100%`}
            height={20}
          />
        </Stack>
      </TableCell>
      {Array(cols ? (cols > 0 ? cols - 1 : 0) : 4)
        .fill("")
        .map((_, index) => (
          <TableCell key={`table-skeleton-col-${index}-${id}`}>
            <Skeleton
              variant="text"
              width={`100%`}
              height={20}
            />
          </TableCell>
        ))}
    </TableRow>
  );
}
