import { useTranslation } from "react-i18next";
// utils
import localStorageAvailable from "@/core/utils/localStorageAvailable";
// components
import { useSettingsContext } from "@/core/components/settings";
//
import { allLangs, defaultLang } from "./config-lang";
import { IAppLocale } from "../types";
import { Localization } from "@mui/material/locale";

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();

  const { onChangeDirectionByLang } = useSettingsContext();

  const storageAvailable = localStorageAvailable();

  const langStorage = storageAvailable
    ? localStorage.getItem("i18nextLng")
    : "";

  // @ts-ignore
  const currentLang: {
    label: string;
    value: IAppLocale;
    systemValue: Localization;
    icon: string;
  } = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newlang: any) => {
    i18n.changeLanguage(newlang);
    //@ts-ignore
    onChangeDirectionByLang(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: string, options?: any) => translate(text, options),
    currentLang,
    allLangs,
  };
}
