// @mui
import { Stack, Typography } from "@mui/material";
// auth
import { useAuthContext } from "@/app/auth/useAuthContext";
// routes
// layouts
import LoginLayout from "@/app/layouts/login";
//
import useTranslation from "@/core/hooks/useTranslation";
import AuthLoginForm from "./AuthLoginForm";

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();
  const t = useTranslation();

  return (
    <LoginLayout>
      <Stack
        justifyContent={"center"}
        sx={{ minHeight: "70vh" }}
      >
        <Stack
          spacing={2}
          sx={{ position: "relative", mb: 6 }}
          justifyContent={"center"}
        >
          <Typography variant="h4">{t("login.sign_in_here")}</Typography>
          <Typography
            variant="body2"
            color="gray"
          >
            {t("login.welcome")}
          </Typography>

          <Stack
            direction="row"
            spacing={0.5}
          >
            {/* <Typography variant="body2">New user?</Typography>

          <Link
            component={RouterLink}
            to={PATH_AUTH.register}
            variant="subtitle2"
          >
            Create an account
          </Link> */}
          </Stack>

          {/* <Tooltip
          title={method}
          placement="left"
        >
          <Box
            component="img"
            alt={method}
            src={`/assets/icons/auth/ic_${method}.png`}
            sx={{ width: 32, height: 32, position: "absolute", right: 0 }}
          />
        </Tooltip> */}
        </Stack>

        {/* <Alert
        severity="info"
        sx={{ mb: 3 }}
      >
        Use email : <strong>demo@minimals.cc</strong> / password :
        <strong> demo1234</strong>
      </Alert> */}

        <AuthLoginForm />

        {/* <AuthWithSocial /> */}
      </Stack>
    </LoginLayout>
  );
}
