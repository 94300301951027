import { IGrade } from "@/app/types";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";

const gradeColumns: ITableRowColumn<IGrade>[] = [
  {
    header: { id: "label", label: "label.label", align: "left" },
    type: "avatar",
    title: (row) => row.label,
    avatarUrl: (row) => row?.label ?? "",
  },
  {
    header: {
      id: "biocoins",
      label: "label.biocoins",
      align: "left",
    },
    title: (row) => row?.biocoins ?? "",
    align: "left",
  },
];

export default gradeColumns;
