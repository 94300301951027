const customer = {
  root: "utilisateur",
  root_plural: "utilisateurs",
  salon: "Salon",
  client_detail: "Détails de l'utilisateur",
  salon_detail: "Détails du salon",
  no_profile: "Pas se profile",
  hair_objective_1: "Force",
  hair_objective_2: "Longueur",
  hair_objective_3: "N'ouvrir",
  hair_objective_4: "Définition",
  hair_state_client_current_1: "En bonne santé",
  hair_state_client_current_2: "Cassant",
  hair_state_client_current_3: "Sécheresse du cuir chevelu",
  hair_state_client_current_4: "Problème de pellicules",
  hair_state_client_current_5: "Cheveux gras",
  hair_state_client_current_6: "Perte de cheveux",
  hair_state_client_current_7: "Autre",
  hair_last_client_current_1: "Moins de 3 semaines",
  hair_last_client_current_2: "Moins de 3 mois",
  hair_last_client_current_3: "Plus de 3 mois",
  really_want_to_change_user_status:
    "Voulez-vous vraiment changer le statut de cette utilisateur en {status} ?",
  change_stockist_request: "Changer l'état de la requête",
  really_want_to_change_stockist_request:
    "Voulez-vous vraiment changer l'état de cette requête ?",
  select_a_state_bellow:
    "Sélectionnez un état de requête à attribuer à cet utilisateur ci-dessous.",
  really_want_to_change_request_state:
    "Voulez-vous vraiment changer l'état de la requête de cette utilisateur en {status} ?",
  request_state_changed_successfully: "État de la requête changée avec succès",
  really_want_to_refuse_this_request:
    "Voulez-vous vraiment refuser cette requête ?",
  really_want_to_accept_this_request:
    "Voulez-vous vraiment accepter cette requête ?",
  user_status_changed_successfuly: "Statut de l'utilisateur changé avec succès",
};
export default customer;
