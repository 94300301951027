import { useLocales } from "@/app/locales";

const useTranslation = () => {
  const { translate } = useLocales();

  const t = (key?: string, variables?: { [key: string]: string }) => {
    let translated = translate(typeof key === "string" ? key : "");
    if (variables) {
      Object.keys(variables).map((key) => {
        translated = translated.replaceAll(
          `{${key}}`,
          translate(variables[key])
        );
      });
    }
    return translated;
  };
  return t;
};

export type ITranslateFunction = ReturnType<typeof useTranslation>;
export default useTranslation;
