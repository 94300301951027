import BaseService from "./BaseService";
import { AuthUrls } from "./urls";

class AuthService {
  static update_userInfo = (data: any) => {
    return BaseService.putRequest(AuthUrls.UPDATE_USER_INFO, data, true);
  };

  static update_password = (data: any) => {
    return BaseService.putRequest(AuthUrls.UPDATE_PASSWORD, data, true);
  };

  static login = (info: object) => {
    if ((window as any).mocked) {
      // return login_mock(info);
    } else {
      return BaseService.postRequest(AuthUrls.LOGIN_USER, info, false);
    }
  };
}

export default AuthService;
