const app = {
  total: "Total",
  clear: "Erase",
  status: "Status",
  accounts: "Accounts",
  messages: "Messages",
  media: "Media",
  content: "Content",
  sales: "Sales",
  add: "Add",
  create: "Create",
  update: "Modify",
  edit: "Modify",
  delete: "Delete",
  role: "Role",
  list: "List",
  no_data: "No data",
  save: "Record",
  cancel: "Cancel",
  desc: "Decensant",
  asc: "Ascendant",
  actions: "Actions",
  details: "Details",
  see: "To see",
  create_model_title: "Create a {model}",
  update_model_title: "Edit a {model}",
  delete_model_title: "Suprrimer un {model}",
  create_model_title_female: "Create a {model}",
  update_model_title_female: "Edit a {model}",
  delete_model_title_female: "Delete a {model}",
  delete_model_confirm: "Are you sure you want to delete this {model}?",
  delete_model_confirm_plural:
    "Are you sure you want to delete these {count} {model}?",
  delete_model_confirm_vowel_plural:
    "Are you sure you want to delete these {count} {model}?",
  delete_model_confirm_vowel: "Are you sure you want to delete this {model}?",
  delete_model_confirm_female: "Are you sure you want to delete this {model}?",
  delete_model_confirm_vowel_female:
    "Are you sure you want to delete this {model}?",
  update_model_description:
    "Edit a {model} by editing the fields below and click on the 'Edit' button to confirm the changes.",
  create_model_description:
    "Create a {model} by filling in the fields below and click on the 'Create' button to save it.",
  update_model_description_female:
    "Modify a {model} by modifying the fields below and click on the 'Edit' button to confirm the changes.",
  create_model_description_female:
    "Create a {model} by filling in the fields below and click on the 'Create' button to save it.",
  french: "French",
  english: "English",
  application: "Application",
  selected: "Selected",
  selected_female: "Selected",
  selected_plural: "Selected",
  selected_female_plural: "Selected",
  selected_plural_female: "Selected",
  STRIPE: "Stripe",
  APPLE_PAY: "Apple Pay",
  GOOGLE_PAY: "Google Pay",
  PAYPAL: "PayPal",
  remove_all: "Delete All",
  infos: "News",
  table: "Table",
  calendar: "Calendar",
  date: "Date",
  address: "Address",
  payment: "Payment",
  delivery: "Delivery",
  history: "Historical",
  today: "Today",
  month: "Month",
  day: "Day",
  year: "Year",
  week: "Week",
  agenda: "Agenda",
  no_events: "No event",
  filters: "Filters",
  range: "Interval",
  colors: "Colours",
  color: "Colour",
  events: "Events",
  event: "Event",
  reset: "Reset",
  confirm: "Confirm",
  booy: "Masculine",
  girl: "Female",
  page_not_found: "Page not found",
  page_not_found_title: "Sorry we can't find this page!",
  page_not_found_text:
    "Sorry we can't find the page you're looking for, it may be a spelling error.",
  go_to_home: "Go to home page",
  enable_disable: "Enable/Disable",
  not_found: "Not found",
  no_result_found_in_the_list: "We didn't find a result in the list",
  check_word_spelling: "Please check spelling",
  enter_a_keyword: "Enter a keyword",
  configure: "Configure",
  configuration: "Configuration",
  accept: "Accept",
  refuse: "Refuse",
  all: "All",
  change_status: "Change status",
  none_female: "No",
  reject: "Rejecting",
};
export default app;
