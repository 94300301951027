import { ACCEPTED_FILE_TYPES } from "@/app/config-global";
import { User } from "@/app/types";
import buildValidators from "@/core/utils/buildValidators";
import { IAutoFormFields } from "@/presentation/components/AutoForm";

const usersFields = (t: Function, roles: any[]): IAutoFormFields<User> => {
  const v = buildValidators(t);
  return {
    avatar: {
      type: "uploader",
      apiName: "avatar_uuid",
      fileType: ACCEPTED_FILE_TYPES.image,
      initialValue: (row) => row?.avatar?.uuid ?? "",
      initialImage: (row) => row?.avatar?.medium?.url,
      ignoreFileStoring: (initialItem) => !initialItem,
      label: "label.image",
      placeholder: "placeholder.image",
      title: "title.image",
      validation: v.string_nr,
    },
    firstname: {
      initialValue: (row) => row?.firstname,
      label: "label.first_name",
      placeholder: "placeholder.first_name",
      title: "title.first_name",
      props: {},
      validation: v.string,
    },
    lastname: {
      initialValue: (row) => row?.lastname,
      label: "label.last_name",
      placeholder: "placeholder.last_name",
      title: "title.last_name",
      props: {},
      validation: v.string,
    },
    // affiliate_code: {
    //   initialValue: (row) => row?.affiliate_code,
    //   label: "label.affiliate_code",
    //   placeholder: "placeholder.affiliate_code",
    //   title: "title.affiliate_code",
    //   props: {},
    //   validation: v.string,
    // },
    address__quarter: {
      initialValue: (row) => row?.address?.quarter,
      label: "label.quarter",
      placeholder: "placeholder.quarter",
      title: "title.quarter",
      props: {},
      validation: v.string,
    },
    address__town: {
      initialValue: (row) => row?.address?.town,
      label: "label.town",
      placeholder: "placeholder.town",
      title: "title.town",
      props: {},
      validation: v.string,
    },
    address__country: {
      initialValue: (row) => row?.address?.country,
      label: "label.country",
      placeholder: "placeholder.country",
      title: "title.country",
      props: {},
      validation: v.string,
    },
    // email: {
    //   initialValue: (row) => row?.phonenumber,
    //   label: "label.email",
    //   placeholder: "placeholder.email",
    //   title: "title.email",
    //   props: {},
    //   validation: v.email,
    // },
    password: {
      initialValue: (row) => "",
      type: "password",
      label: "label.password",
      placeholder: "placeholder.password",
      title: "title.password",
      // hidden: (row) => row,
      props: {},
      validation: v.password_nr,
    },
    phonenumber: {
      initialValue: (row) => row?.phonenumber,
      label: "label.phone_number",
      placeholder: "placeholder.phone_number",
      title: "title.phone_number",
      props: {},
      validation: v.number,
    },
    // job: {
    //   initialValue: (row) => row?.job,
    //   label: "label.job",
    //   placeholder: "placeholder.job",
    //   title: "title.job",
    //   props: {},
    //   validation: v.string_nr,
    // },
    // bio: {
    //   initialValue: (row) => row?.bio,
    //   type: "textarea",
    //   label: "label.bio",
    //   placeholder: "placeholder.bio",
    //   title: "title.bio",
    //   props: {},
    //   validation: v.string_nr,
    // },
    role_uuid: {
      initialValue: (row) => row?.role?.uuid,
      type: "select",
      getOptions: () => roles,
      getOptionLabel: (option) => option?.label,
      getOptionValue: (option) => option?.value,
      label: "label.role",
      placeholder: "placeholder.role",
      title: "title.role",
      props: {},
      validation: v.string,
    },
  };
};

export default usersFields;
