import { DEFAULT_NA_STRING } from "@/app/config-global";
import { User, IAppLocale } from "@/app/types";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";
import getFullName from "@/core/utils/getFullName";

const usersColums: (locale: IAppLocale) => ITableRowColumn<User>[] = (
  locale: IAppLocale
) => [
  {
    header: { id: "name", label: "administrator.name", align: "left" },
    type: "avatar",
    title: (row) => getFullName(row?.firstname, row?.lastname),
    avatarUrl: (row) => row?.avatar?.url ?? "",
  },
  {
    header: {
      id: "phonenumber",
      label: "label.phonenumber",
      align: "left",
    },
    title: (row) => row?.phonenumber,
    align: "left",
  },
  {
    header: { id: "role", label: "administrator.role", align: "left" },
    title: (row) => row?.role?.title?.[`${locale as IAppLocale}`],
    align: "left",
  },
  {
    header: { id: "status", label: "administrator.status", align: "left" },
    type: "badge",
    title: (row) => `status.${row?.status ?? ""}`,
    badgeColor: (row) => (row?.status === "ACTIVED" ? "success" : "error"),
    sx: { textTransform: "capitalize" },
  },
  {
    header: {
      id: "become_stockist",
      label: "administrator.stockist_request",
      align: "left",
    },
    title: (row) =>
      !row?.become_stockist
        ? DEFAULT_NA_STRING
        : `status.${row?.become_stockist ?? ""}_female`,
    sx: { textTransform: "capitalize" },
  },
];

export default usersColums;
