const error = {
  invalid_email_or_password: "Incorrect email or password",
  file_upload_error: "Error wilding file for field: {field}",
  this_field_is_required: "This field is required",
  enter_a_valid_email_address: "Please enter a valid email address",
  this_field_should_be_a_number: "This field must be numeric",
  add_at_least_one_item: "Add at least 01 element",
  unexpected_error_occurred:
    "An unexpected error occurred while processing the request",
  start_date_greather_than_end_date:
    "The start date must be greater than the end date",
};
export default error;
