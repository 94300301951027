const placeholder = {
  title: "Title",
  status_admin: "Enable/Disable",
  search: "Research...",
  first_name: "Forename",
  last_name: "Name",
  email: "Email address",
  phone_number: "Phone number",
  role: "Role",
  job: "Work",
  bio: "Bio",
  image: "Image",
  write_something: "Write something exceptional...",
  meta_title: "Meta title",
  meta_description: "Meta description",
  meta_keywords: "Meta keywords",
  drop_or_select_file: "Upload a file or click here select a file",
  category: "Category",
  name: "Name",
  name_en: "Name in English",
  name_fr: "Name in French",
  brand: "Mark",
  images: "Footage",
  description: "Description",
  description_en: "Description in English",
  sku: "SKU",
  real_price: "Real price",
  current_price: "Current price",
  qty: "Quantity",
  hair_style: "Hair style",
  benefits: "Benefits",
  weight: "Weight",
  is_online: "Upload",
  sub_category: "Subcategory",
  video_link: "Video link",
  image_link: "Image link",
  prestation: "Benefit",
  prestations: "Benefits",
  begin_duration: "Beginning",
  content: "Write something wonderful...",
  video: "Video",
  video_duration: "Video length",
  tags: "Tags",
  hairdressing_salon: "Living room",
  hairdresser_salon_name: "Salon Name",
  country_code: "Country code",
  entreprise_name: "Company Name",
  entreprise_phonenumber: "Company phone number",
  website: "Website",
  hair_types: "Types of cheuveux",
  people_type_ids: "Types of person",
  shop_onligne: "Visible online",
  headdress_at_home: "Formwork at home",
  postal_code: "Zip code",
  province: "Region",
  town: "",
  country: "Country",
  address_one: "Main address",
  address_two: "Secondary address",
  accept_click_n_collect: "Click N Collect",
  manager_hairdressing_email: "Email address of the show manager",
  accepted_files: "Accepted files",
  browse: "Import",
  password: "Password",
  publish: "Published",
  title_fr: "Title in English",
  title_en: "Title in English",
  description_fr: "Description in English",
  product_hot_point: "Highlights",
  special_price: "Special price",
  stock: "Stock",
  sold: "Sold",
  hair_type: "Hair type",
  price: "Price",
  code: "Code",
  features: "Features",
  categories: "Categories",
  biocoin_cost: "Cost Biocoin",
  stockist_price: "Stockist Award",
  stockist_biocoin_cost: "Biocoin Stockist Award",
  recommended_price: "Recommended price",
  potential_benefits: "Potential benefit",
  ambassador_biocoin: "Biocoin ambassador",
  stockist_biocoin_on_ambassador: "Biocoin stockist ambassador",
  sponsor_biocoin: "Biocoin sponsor",
  stockist_biocoin: "Biocoin Stockist",
  stockist_minimum_qty: "Minimum Stockist Qty",
  label: "Entitled",
  biocoins: "Biocoins",
  min_biocoins_number: "Minimum number of Biocoins",
  biocoin_value: "Value of Biocoin",
  affiliate_code: "Affiliate Code",
  quarter: "Quarter",
  volume: "Volume",
  head_office: "Head office",
  stockist: "Stockist",
  products: "Products",
  supply_date: "Supply date",
  product: "Product",
};
export default placeholder;
