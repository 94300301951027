import React from "react";
import useToast from "./useToast";
import useTranslation from "./useTranslation";

export default function useToastError(error?: { message: string } | null) {
  const toast = useToast();
  const t = useTranslation();
  React.useEffect(() => {
    if (error?.message) {
      toast.error(t(error?.message));
    }
  }, [error]);
}
