import { IArticleCategory } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IArticleCategoryReducer } from "./reducers";

export interface IArticleCategoryState
  extends IBaseCrudState<IArticleCategory> {}

export const articleCategoryInitialState: IArticleCategoryState =
  baseCrudInitialState;

export const slice = createSlice<
  IArticleCategoryState,
  IArticleCategoryReducer,
  typeof sliceNames.articleCategory
>({
  name: sliceNames.articleCategory,
  initialState: articleCategoryInitialState,
  reducers,
});

export default slice.reducer;
