import React from "react";
import useFilters from "@/core/hooks/useFilters";
import useTranslation from "@/core/hooks/useTranslation";
import TablePage, {
  ICustomBreadcrumbsLink,
} from "@/presentation/components/TablePage";
import sliceNames from "@/redux/names";
import paymentRequestEffects from "@/redux/slices/paymentRequest/effects";
import { useDispatch, useSelector } from "@/redux/store";
import paymentRequestColumns from "./data/columns";
import {
  paymentRequestTableTopOptions,
  paymentRequestToolbarOptions,
} from "./data/constants";
import paymentRequestFields from "./data/fields";
import AppModal from "@/presentation/components/AppModal";
import AutoForm from "@/presentation/components/AutoForm";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import PaymentRequestService from "@/app/services/api/PaymentRequestService";
import useToast from "@/core/hooks/useToast";
import { IPaymentRequest } from "@/app/types";
import ConfirmDialog from "@/core/components/confirm-dialog";
import { slice as autoFormslice } from "@/redux/slices/autoForm";
import { DEFAULT_NA_STRING } from "@/app/config-global";

interface IPaymentRequestProps {
  additionalFilters?: Record<string, string | undefined>;
  breadcrumbs?: null | ICustomBreadcrumbsLink[];
  noPage?: boolean;
}
export default function PaymentRequestsPage({
  additionalFilters,
  breadcrumbs,
  noPage,
}: IPaymentRequestProps) {
  // Store
  const paymentRequest = useSelector((state) => state.paymentRequest);

  // State
  const [requestToAccept, setRequestToAccept] =
    React.useState<IPaymentRequest | null>(null);
  const [requestToRefuse, setRequestToRefuse] =
    React.useState<IPaymentRequest | null>(null);

  // Hooks
  const t = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const [showConfig, setShowConfig] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [config, setConfig] = React.useState<any>(false);
  const {
    getData,
    handleKeyword,
    handlePaymentType,
    handleStatus,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    keyword,
    paymentType,
    status,
    order: _order,
    order_field,
  } = useFilters({
    effects: paymentRequestEffects,
    initialState: {
      page: paymentRequest.list.current_page,
      per_page: paymentRequest.list.per_page,
      keyword: "",
      paymentType: "",
      status: "",
      order: "",
      order_field: "",
      ...(additionalFilters ?? {}),
    },
  });

  // Constants
  const statuses = [``, "PENDING", "PAID", "REJECTED"].map((status) => ({
    label: status ? t(`status.${status}`) : DEFAULT_NA_STRING,
    value: status,
  }));

  // Functions
  const getConfig = () => {
    setLoading(true);
    PaymentRequestService.getConfig()
      .then(async (res) => {
        if (res.status === 200 || res.status === 201) {
          const data = await res.json();
          return data;
        } else {
          throw new Error("invalid response status");
        }
      })
      .then((data) => {
        setConfig(data);
      })
      .catch(() => {
        toast.error(t("paymentRequest.get_config_error"));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const saveConfig = (values: any) => {
    setLoading(true);
    PaymentRequestService.configure(values)
      .then(async (res) => {
        if (res.status === 200 || res.status === 201) {
          const data = await res.json();
          return data;
        } else {
          throw new Error("invalid response status");
        }
      })
      .then(() => {
        // setConfig(data);
        setConfig(values);
        setShowConfig(false);
        toast.success(
          t(`models.update_success_female`, {
            model: `app.configuration`,
          })
        );
      })
      .catch(() => {
        toast.error(t("error.unexpected_error_occurred"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const acceptPayementRequest = () => {
    setLoading(true);
    PaymentRequestService.acceptRequest(requestToAccept?.uuid ?? "")
      .then(async (res) => {
        if (res.status === 200 || res.status === 201) {
          const data = await res.json();
          return data;
        } else {
          throw new Error("invalid response status");
        }
      })
      .then(() => {
        setRequestToAccept(null);
        toast.success(t("paymentRequest.request_has_been_accepted"));
        getData();
      })
      .catch(() => {
        toast.error(t("error.unexpected_error_occurred"));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const refusePayementRequest = () => {
    setLoading(true);
    PaymentRequestService.refuseRequest(requestToRefuse?.uuid ?? "")
      .then(async (res) => {
        if (res.status === 200 || res.status === 201) {
          const data = await res.json();
          return data;
        } else {
          throw new Error("invalid response status");
        }
      })
      .then(() => {
        setRequestToRefuse(null);
        toast.success(t("paymentRequest.request_has_been_refused"));
        getData();
      })
      .catch(() => {
        toast.error(t("error.unexpected_error_occurred"));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getConfig();
  }, []);

  React.useEffect(() => {
    if (!showConfig) {
      // @ts-ignore
      dispatch(autoFormslice.actions.setValuesToHold(null));
    }
  }, [showConfig]);

  return (
    <>
      <TablePage
        model={sliceNames.paymentRequest}
        noCheckBoxes
        // ** Breadcrumbs **
        breadcrumbLinks={breadcrumbs}
        // ** Genre **
        female
        // ** Custom row actions **
        customRowActions={[
          {
            onClick: (row) => setRequestToAccept(row),
            icon: "fluent-mdl2:accept-medium",
            label: "app.accept",
          },
          {
            onClick: (row) => setRequestToRefuse(row),
            icon: "healthicons:refused",
            label: "app.refuse",
          },
        ]}
        // ** Toolbar **
        tableTopOptions={paymentRequestTableTopOptions}
        toolbarOptionChange={() => null}
        currentToolbarOption={""}
        toolbarOptions={paymentRequestToolbarOptions(
          statuses,
          {
            value: keyword,
            onChange: handleKeyword,
          },
          {
            value: status,
            onChange: handleStatus,
          }
        )}
        // ** On Select rows **
        idProperty="uuid"
        // ** Table CRUD **
        onAdd={loading ? undefined : () => setShowConfig(true)}
        addBtnText="app.configure"
        addBtnIcon="material-symbols:settings"
        noUpdate
        noDelete
        fields={{}}
        stateData={paymentRequest}
        columns={paymentRequestColumns}
        effects={paymentRequestEffects}
        getData={getData}
        // ** Filter options **
        isFiltered={(data) =>
          keyword !== "" || paymentType !== "" || status !== ""
        }
        onReset={() => {
          handleKeyword("");
          handlePaymentType("");
          handleStatus("");
        }}
        // ** Sorting **
        handleFilterOrder={handleOrder}
        handleFilterOrderField={handleOrder_field}
        order={_order}
        orderField={order_field}
        // ** Pagination **
        onChangePage={handlePage}
        onChangeRowsPerPage={handlePer_page}
        noPage={noPage}
      />

      {showConfig && (
        <AppModal
          title="app.configuration"
          breadCrumbLinks={[
            {
              name: "dashboard.root",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "paymentRequest.root_plural",
            },
            {
              name: "app.configuration",
            },
          ]}
          onClose={() => setShowConfig(false)}
        >
          <AutoForm
            loading={loading}
            initialItem={config}
            fields={paymentRequestFields(t)}
            onSubmit={saveConfig}
          />
        </AppModal>
      )}

      {requestToAccept && (
        <ConfirmDialog
          open={true}
          onClose={() => setRequestToAccept(null)}
          disabled={loading}
          title={t("app.accept")}
          content={t(`paymentRequest.really_want_to_accept_this_request`)}
          onConfirm={acceptPayementRequest}
          isConfirmLoading={loading}
          confirmLabel={t("app.confirm")}
          action={null}
        />
      )}
      {requestToRefuse && (
        <ConfirmDialog
          open={true}
          onClose={() => setRequestToRefuse(null)}
          disabled={loading}
          title={t("app.refuse")}
          content={t(`paymentRequest.really_want_to_refuse_this_request`)}
          onConfirm={refusePayementRequest}
          isConfirmLoading={loading}
          confirmLabel={t("app.confirm")}
          action={null}
        />
      )}
    </>
  );
}
