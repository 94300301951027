import { IGrade } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IGradeReducer } from "./reducers";

export interface IGradeState extends IBaseCrudState<IGrade> {
  list: IBaseCrudState<IGrade>["list"] & {};
}

export const gradeInitialState: IGradeState = {
  ...baseCrudInitialState,
};

export const slice = createSlice<
  IGradeState,
  IGradeReducer,
  typeof sliceNames.grade
>({
  name: sliceNames.grade,
  initialState: gradeInitialState,
  reducers,
});

export default slice.reducer;
