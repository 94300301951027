export default function getFullName(
  firstName?: string,
  lastName?: string,
  fallback?: string
) {
  return firstName || lastName
    ? `${firstName ? `${firstName} ` : ""}${lastName}`
    : fallback ?? "";
}

export const getValueOrNA = (value: any) => value || "--/--";
