import React from "react";

// i18n
import "@/app/locales/i18n";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lightbox
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";

// map
import "@/core/utils/mapboxgl";
import "mapbox-gl/dist/mapbox-gl.css";

// editor
import "react-quill/dist/quill.snow.css";

// slick-carousel
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
// @mui
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// redux
import { persistor, store } from "@/redux/store";
// routes
import Router from "@/app/routes";
// theme
import ThemeProvider from "@/app/theme";
// locales
import ThemeLocalization, { useLocales } from "@/app/locales";
// components
import { MotionLazyContainer } from "@/core/components/animate";
import { StyledChart } from "@/core/components/chart";
import ScrollToTop from "@/core/components/scroll-to-top";
import { SettingsProvider, ThemeSettings } from "@/core/components/settings";
import SnackbarProvider from "@/core/components/snackbar";

// Check our docs
// https://docs.minimals.cc/authentication/js-version

import { AuthProvider } from "@/app/auth/JwtContext";
import moment from "moment";
import "moment/locale/fr";
import InternetStatus from "./core/components/internet-status";
import globalDataEffects from "./redux/slices/globalData/effects";
// import { AuthProvider } from './auth/Auth0Context';
// import { AuthProvider } from './auth/FirebaseContext';
// import { AuthProvider } from './auth/AwsCognitoContext';

// ----------------------------------------------------------------------

const Wrapper: React.FC<{ children: any }> = ({ children }) => {
  moment.locale("fr", { week: { dow: 0 } });
  const {
    currentLang: { value: lang },
  } = useLocales();
  const dispatch = store.dispatch;
  React.useEffect(() => {
    moment.updateLocale(lang, null);
  }, [lang]);
  React.useEffect(() => {
    dispatch(globalDataEffects.get());
  }, []);
  return children;
};

export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <Wrapper>
            <PersistGate
              loading={null}
              persistor={persistor}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider>
                  <BrowserRouter>
                    <ScrollToTop />
                    <MotionLazyContainer>
                      <ThemeProvider>
                        <ThemeSettings>
                          <ThemeLocalization>
                            <SnackbarProvider>
                              <InternetStatus>
                                <StyledChart />
                                <Router />
                              </InternetStatus>
                            </SnackbarProvider>
                          </ThemeLocalization>
                        </ThemeSettings>
                      </ThemeProvider>
                    </MotionLazyContainer>
                  </BrowserRouter>
                </SettingsProvider>
              </LocalizationProvider>
            </PersistGate>
          </Wrapper>
        </ReduxProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}
