const chat = {
  root: "Conversation",
  root_plural: "Conversations",
  no_data: "You don't have a conversation at the moment",
  no_recipients: "You don't have recipients",
  type_your_message: "Enter your message",
  to: "At",
  conversation_start: "Start of coverage",
  no_conversation: "No conversation selected",
  no_conversation_text:
    "Start a conversation by selecting a user in the 'To' field above or select a conversation on the left if you have one.",
  you: "You",
};
export default chat;
