import GradeService from "@/app/services/api/GradeService";
import { IGradeCreate, IGradeRange, IGradeUpdate } from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface IGradeEffects
  extends IReduxBaseCrudEffect<IGradeRange, IGradeCreate, IGradeUpdate, any> {}

class GradeEffects
  extends BaseReduxCrudEffects<IGradeRange, IGradeCreate, IGradeUpdate>
  implements IGradeEffects {}

const gradeEffects = new GradeEffects(slice, GradeService);

export default gradeEffects;
