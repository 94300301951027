import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import {
  Box,
  Card,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
// _mock
import { _socials } from "@/app/_mock/arrays";
// components
import { CustomTextField } from "@/core/components/custom-input";
import Iconify from "@/core/components/iconify";
import MenuPopover from "@/core/components/menu-popover";
import useTranslation from "@/core/hooks/useTranslation";
import EmptyContent from "@/core/components/empty-content";
import getFullName, { getValueOrNA } from "@/core/utils/getFullName";
import AppModal from "@/presentation/components/AppModal";
import { CustomAvatar } from "@/core/components/custom-avatar";
import Label from "@/core/components/label";
import { useLocales } from "@/app/locales";
import Image from "@/core/components/image";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import { DEFAULT_NA_STRING } from "@/app/config-global";
import { useSelector } from "@/redux/store";

// ----------------------------------------------------------------------

CustomerProfilesPage.propTypes = {
  profiles: PropTypes.array,
};

export default function CustomerProfilesPage({ profiles }: any) {
  const [searchFriends, setSearchFriends] = useState("");
  const [profileToShow, setProfileToShow] = useState<any | null>(null);
  const dataFiltered = applyFilter(profiles, searchFriends) ?? [];
  const t = useTranslation();

  const isNotFound = dataFiltered.length === 0;

  return (
    <>
      <Stack
        spacing={3}
        justifyContent="space-between"
        direction={{ xs: "column", sm: "row" }}
        sx={{ my: 5 }}
      >
        <CustomTextField
          // @ts-ignore
          width={220}
          size="small"
          value={searchFriends}
          onChange={(e: any) => setSearchFriends(e.target.value)}
          placeholder={t("placeholder.search")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {isNotFound ? (
        <>
          {/* @ts-ignore */}
          <EmptyContent title={t("customer.no_profile")} />
        </>
      ) : (
        <Box
          gap={3}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          }}
        >
          {dataFiltered
            ?.map((a: any) => a)
            .sort((a: any) => a?.is_parent)
            .map((friend: any) => (
              <FriendCard
                key={friend.uuid}
                friend={friend}
                setProfileToShow={setProfileToShow}
              />
            ))}
        </Box>
      )}

      {profileToShow && (
        <ProfileModal
          profile={profileToShow}
          onClose={() => setProfileToShow(null)}
        />
      )}
    </>
  );
}

// ----------------------------------------------------------------------

FriendCard.propTypes = {
  friend: PropTypes.object,
  setProfileToShow: PropTypes.func,
};

function FriendCard({
  friend,
  setProfileToShow,
}: {
  friend: any;
  setProfileToShow: any;
}) {
  const t = useTranslation();
  const avatarUrl = friend?.avatar?.url ?? "";

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event: any) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleDelete = () => {
    handleClosePopover();
  };

  const handleEdit = () => {
    handleClosePopover();
    setProfileToShow(friend);
  };

  return (
    <>
      <Card
        sx={{
          py: 5,
          display: "flex",
          position: "relative",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
          "&:hover": {
            boxShadow: 4,
          },
        }}
        role="button"
        onClick={handleEdit}
      >
        <CustomAvatar
          // @ts-ignore
          alt={getFullName(friend.firstname, friend.lastname, friend.email)}
          name={getFullName(friend.firstname, friend.lastname, friend.email)}
          src={avatarUrl}
          sx={{ width: 64, height: 64, mb: 3 }}
        />

        <Link
          variant="subtitle1"
          color="text.primary"
        >
          {getFullName(friend.firstname, friend.lastname, friend.email)}
        </Link>

        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            mb: 1,
            mt: 0.5,
          }}
        >
          <Stack
            direction="row"
            spacing={3}
            alignItems={"center"}
          >
            {friend.email}
          </Stack>
        </Typography>

        {/*
        <Stack
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          {_socials.map((social) => (
            <IconButton
              key={social.name}
              sx={{
                color: social.color,
                "&:hover": {
                  bgcolor: alpha(social.color, 0.08),
                },
              }}
            >
              <Iconify icon={social.icon} />
            </IconButton>
          ))}
        </Stack> */}

        {/*
        <IconButton
          color={openPopover ? "inherit" : "default"}
          onClick={handleOpenPopover}
          sx={{ top: 8, right: 8, position: "absolute" }}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      */}
      </Card>

      {/* @ts-ignore */}
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{}}
      >
        <MenuItem onClick={handleEdit}>
          <Iconify icon="eva:eye-fill" />
          {t("app.details")}
        </MenuItem>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------
function ProfileModal({
  profile,
  onClose,
}: {
  profile: any | null;
  onClose: () => void;
}) {
  const t = useTranslation();
  const { currentLang } = useLocales();
  const { globalData } = useSelector((state) => state);
  return (
    <AppModal
      onClose={onClose}
      breadCrumbLinks={[
        { name: "dashboard.root", href: PATH_DASHBOARD.root },
        { name: `customer.root_plural`, href: PATH_DASHBOARD.customers.root },
        { name: `label.profile` },
        {
          name:
            getFullName(
              profile?.firstname,
              profile?.lastname,
              profile?.email
            ) ?? "",
        },
      ]}
    >
      <Stack
        spacing={3}
        divider={<Divider />}
        sx={{ py: 3 }}
      >
        <Stack
          direction="row"
          gap={3}
        >
          <Stack
            direction="row"
            gap={3}
          >
            <CustomAvatar
              // @ts-ignore
              variant="rectangular"
              src={profile?.avatar?.medium?.url ?? ""}
              alt={getFullName(
                profile?.firstname,
                profile?.lastname,
                profile?.email
              )}
              name={getFullName(
                profile?.firstname,
                profile?.lastname,
                profile?.email
              )}
              sx={{
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "common.white",
                width: { xs: 80 },
                height: { xs: 80 },
              }}
            />
            <Stack>
              <Stack>
                {[
                  getFullName(profile?.firstname, profile?.lastname),
                  profile?.email,
                  t(profile?.sex ? `app.${profile.sex}` : ""),
                  profile?.birthday
                    ? String(profile?.birthday).split("-").reverse().join("/")
                    : "",
                ].map((val, index) => (
                  <Stack
                    key={`${val}${index}`}
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{
                      color: index === 0 ? undefined : "text.secondary",
                    }}
                  >
                    {index > 0 && (
                      <Iconify
                        icon={
                          [
                            "",
                            "material-symbols:alternate-email-rounded",
                            "ph:gender-intersex-bold",
                            "mdi:birthday-cake",
                          ][index]
                        }
                      />
                    )}
                    <Typography variant={index === 0 ? "subtitle1" : "body2"}>
                      {getValueOrNA(val)}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>

          <Box sx={{ mb: 1, ml: "auto" }}>
            {/* @ts-ignore */}
            <Label
              color={profile?.is_parent ? "primary" : "secondary"}
              startIcon={
                <Iconify
                  icon={
                    profile?.is_parent
                      ? "raphael:parent"
                      : "tabler:mood-kid-filled"
                  }
                />
              }
            >
              {profile?.is_parent
                ? t("label.parent_profile")
                : t("label.child_profile")}
            </Label>
          </Box>
        </Stack>

        <Box>
          <Typography
            variant="subtitle2"
            sx={{ color: "text.secondary" }}
          >
            {t("label.hair_types")}
          </Typography>
          <Stack
            justifyContent="start"
            gap={3}
            sx={{ mt: 2 }}
          >
            {profile?.hair_type?.length === 0 && (
              <Typography>{t("app.no_data")}</Typography>
            )}
            {profile?.hair_type?.map((ht: any, index: number) => (
              <Box
                key={`ht-${index}`}
                sx={{ position: "relative", height: 100, width: 100 }}
              >
                <Image
                  // @ts-ignore
                  src={ht?.url ?? ""}
                  alt={ht?.code ?? ""}
                  sx={{ position: "relative", h: "100%", w: "100%" }}
                />
              </Box>
            ))}
          </Stack>
        </Box>

        <Box>
          <Typography
            variant="subtitle2"
            sx={{ color: "text.secondary" }}
          >
            {t("label.people_types")}
          </Typography>
          <Stack
            justifyContent="start"
            gap={3}
            sx={{ mt: 2 }}
          >
            {profile?.people_types?.length === 0 && (
              <Typography>{t("app.no_data")}</Typography>
            )}
            {profile?.people_types?.map((pt: any, index: number) => (
              <Box
                key={`ht-${index}`}
                sx={{ position: "relative", height: 100, width: 100 }}
              >
                <Image
                  // @ts-ignore
                  src={pt?.url ?? ""}
                  alt={pt?.code ?? ""}
                  sx={{ position: "relative", h: "100%", w: "100%" }}
                />
              </Box>
            ))}
          </Stack>
        </Box>

        <Box>
          <Typography
            variant="subtitle2"
            sx={{ color: "text.secondary" }}
          >
            {t("label.preferences")}
          </Typography>
          <Stack
            justifyContent="start"
            gap={3}
            sx={{ mt: 2 }}
            divider={
              <Divider
                sx={{ boderStyle: "dashed", borderColor: "text.secondary" }}
              />
            }
          >
            {profile?.preferences?.length === 0 && (
              <Typography>{t("app.no_data")}</Typography>
            )}
            {profile?.preferences?.map((p: any, index: number) => (
              <Box
                key={`preferences-${index}`}
                sx={{
                  position: "relative",
                  height: 100,
                  "& table": {
                    width: "100%",
                  },
                  "& table tbody tr td:nth-child(1)": {
                    color: "text.secondary",
                    fontSize: "12px",
                    px: 2,
                    py: 1,
                    minWidth: 200,
                  },
                }}
              >
                <table>
                  <tbody>
                    <tr>
                      <td>{t("label.objective")}</td>
                      <td>
                        {p?.objectif?.startsWith("hair_objective_")
                          ? t(`customer.${p?.objectif}`)
                          : DEFAULT_NA_STRING}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("label.last_time_hairdresser")}</td>
                      <td>
                        {p?.last_time_hairdresser?.startsWith(
                          "hair_last_client_current_"
                        )
                          ? t(`customer.${p?.last_time_hairdresser}`)
                          : DEFAULT_NA_STRING}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("label.hair_conditions")}</td>
                      <td>
                        {p?.hair_condition?.length > 0
                          ? p?.hair_condition
                              ?.map((hc: string) =>
                                hc?.startsWith("hair_state_client_current_")
                                  ? t(`customer.${hc}`)
                                  : hc
                              )
                              .join(", ")
                          : DEFAULT_NA_STRING}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("label.current_hair_style")}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>{t("label.hair_type")}</td>
                      <td>
                        {p?.hair_type?.code ? (
                          <Box>
                            {/* @ts-ignore */}
                            <Label
                              startIcon={
                                <Image
                                  // @ts-ignore
                                  src={p?.hair_type?.url ?? ""}
                                  alt={p?.hair_type?.code ?? ""}
                                />
                              }
                            >
                              {p?.hair_type?.code ?? ""}
                            </Label>
                          </Box>
                        ) : (
                          DEFAULT_NA_STRING
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            ))}
          </Stack>
        </Box>
      </Stack>
    </AppModal>
  );
}

// ----------------------------------------------------------------------

function applyFilter(inputData: any, query: any) {
  if (query) {
    return (inputData ?? []).filter(
      (friend: any) =>
        `${friend?.firstname ?? ""}${friend?.lastname ?? ""}${
          friend?.email ?? ""
        }`
          .toLowerCase()
          .indexOf((query ?? "").trim().toLowerCase()) !== -1
    );
  }

  return inputData;
}
